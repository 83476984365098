import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FoldingCube } from 'better-react-spinkit';
import { BiTrash } from 'react-icons/bi'
import { immediateToast } from "izitoast-react";

import "izitoast-react/dist/iziToast.css";
import { setSelectedDocInfo, setTranslateStatus, setSelectedDocTranslatedList, setTranslateDocList } from "../../../modules/translateDoc";

const DocList = () => {

    type TranslateDocSelectedType = {
        appType: string,
        created_at: string,
        label: string,
        orgUser_id: string,
        space_id: string,
        updated_at: string
    }

    const dispatch = useDispatch();

    const [sidebarIcon, setSidebarIcon] = useState(false)

    const translateDocList = useSelector(state => state.translateDoc.translateDocList);
    const loadingDocSpaceList = useSelector(state => state.loading.loadingDocSpaceList);
    const selectedDocInfo = useSelector(state => state.translateDoc.selectedDocInfo);

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);

    // click sidebar doc
    function getSpaceState(item) {
        item.stateList = [];
        dispatch( setSelectedDocTranslatedList([]) );
        dispatch( setSelectedDocInfo(item) ); // init -> for sidebar
        dispatch( setTranslateStatus("status") );
        

        // getState Test
        /*
        var data = JSON.stringify({
            "orgCode": orgCode,
            "token": userToken,
            "uuid": item.space_id // uuid, space_id
        });

        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/translator/doc/getState',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            console.log(response);
            if( response.data.code == 100){
                const selectedDoc = item;
                const stateList = response.data.data;
                selectedDoc.stateList = stateList; //  select

                dispatch( setSelectedDocInfo(selectedDoc) ); // change data -> for useEffect stateList (add stateList)

                // dispatch( setTranslateStateList(stateList) );
                dispatch( setLoadingDocState(false) );

            } else if(response.data.code == 200) {
                // error
                console.log(response.data.message);
                dispatch( setLoadingDocState(false) );
                dispatch( setTranslateStateList([]) );
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch( setLoadingDocState(false) );
        });
        */
    }

    function delTranslateDocSpace(item: TranslateDocSelectedType) {
        console.log(item);

        immediateToast("info", {
            title: '문서 삭제',
            message: "문서가 삭제중입니다.",
            position: 'topCenter',
            timeout: 2000,
            zindex: 999999999
        });

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "type" : "translateDoc",
            "spaceId" : item.space_id
        });

        var config = {
            method: 'post',
            url:  API_URL + '/' + API_VERSION + '/space/delSpace',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
        .then( (response) => {
            console.log(response.data)

            if( response.data.code == 100){
                console.log("SUCCESS")

                immediateToast("success", {
                    title: '문서 삭제',
                    message: "문서가 삭제되었습니다.",
                    position: 'topCenter',
                    timeout: 2000,
                    zindex: 999999999
                });

                dispatch( setTranslateStatus("upload") ); // init

                // 문서 요약 사이드바 갱신
                const updateList = translateDocList.filter((list: TranslateDocSelectedType) => list.space_id != item.space_id)
                dispatch( setTranslateDocList(updateList) );

                if(selectedDocInfo.space_id == item.space_id) {
                    // 지금 선택한 문서 요약 페이지 삭제
                    dispatch( setSelectedDocInfo(false) )
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <ul className="mb-0 sidebar-list-wrapper">
            <>
                {
                    loadingDocSpaceList ? (
                        <div className='justify-content-center d-flex align-items-center h-100'>
                            <FoldingCube size={20} color="#C0C0C0" />
                        </div>
                    ) : (
                        <>
                            {
                                Object.keys(translateDocList).map(v => 
                                    <li 
                                        key={ translateDocList[v].space_id }
                                        className={ selectedDocInfo != "false" ? (
                                            selectedDocInfo.space_id == translateDocList[v].space_id ? "btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room" : "btn-select-space pl-2 pr-3 pt-3 pb-3"
                                            ) : ""
                                        }
                                        onClick={() => getSpaceState(translateDocList[v])}
                                        onMouseEnter={() => setSidebarIcon( translateDocList[v].space_id ) }
                                        onMouseLeave={() => setSidebarIcon(false) }
                                    >
                                        <div className="space-label fl text-truncate">
                                            { translateDocList[v].label }
                                        </div>
                                        {/* {
                                            sidebarIcon != false && (
                                                sidebarIcon == translateDocList[v].space_id && (
                                                    <div
                                                        className="fr pr-2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            delTranslateDocSpace( translateDocList[v] );
                                                        }}
                                                    >
                                                        <BiTrash size={ 14 } />
                                                    </div>
                                                )
                                            )
                                        } */}
                                        {/* 문서 번역 : 문서 삭제 기능 숨김 처리 */}
                                        <div className="cr" />
                                    </li>
                                )
                            }
                        </>
                    )
                    
                }
            </>
        </ul>
    )
}

export default DocList;
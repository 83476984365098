//Actions
const LOGIN = "auth/LOGIN";
const LOGOUT = "auth/LOGOUT";
const CHECKAUTH = "auth/CHECKAUTH";

// 액션 생성 함수
export const tryLogin = () => ({ type: LOGIN });
export const authLogout = () => ({ type: LOGOUT });
export const checkAuth = () => ({ type: CHECKAUTH });




// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = 0;

export default function counter(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return state + 1;
    case LOGOUT:
      return state - 1;
    case CHECKAUTH:
        return state - 1;      
    default:
      return state;
  }
}

import React from "react";
import { useSelector } from "react-redux";

const MainBottomBanner = () => {

    const uiShowAiBox = useSelector(state => state.ui.uiShowAiBox);
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const orgCode = useSelector(state => state.organization.orgCode);

    const RenderModalView = () => {
        return (
            <></>
        )
    }

    const RenderSmallView = () => {
        return (
            <></>
        )
    }

    return (
        <>
            {
                uiShowAiBox && (
                    uiSmallAiBox ? (
                        <RenderSmallView />
                    ) : (
                        <RenderModalView />
                    )
                )
            }
        </>
    )
}

export default MainBottomBanner
import React, {useState, useEffect} from 'react';

import { Row, Col, InputGroup, FormControl } from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { FoldingCube } from 'better-react-spinkit'
import { BsPencil, BsGraphUp } from 'react-icons/bs'
import { GoThumbsup, GoThumbsdown } from 'react-icons/go'
import { setLoadingSpace, setLoadingWork, setLoadingAnswering} from "../../../modules/loading";
import { setSummaryDocList, setSummaryDocStatus, setSummaryDocSelectedSpace, setSummaryDocSelectedHistory } from "../../../modules/summaryDoc";
import { useTranslation } from "react-i18next";

const SummaryDocChatLoading = () => {

    const dispatch = useDispatch();

    const [summaryDocState, setSummaryDocState] = useState(100); // default

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const summaryDocSelectedSpace = useSelector(state => state.summaryDoc.summaryDocSelectedSpace);    
    //const [promptList, setPromptList] = useState([]);
    const summaryDocList = useSelector(state => state.summaryDoc.summaryDocList);

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const fetchController = useSelector(state => state.apps.fetchController);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        console.log("summaryDocSelectedSpace : ", summaryDocSelectedSpace);
        console.log("fetchController : ", fetchController);
        if(fetchController != false){
            fetchController.abort();  
        }        
        if(summaryDocSelectedSpace.state == 100){
            setTimeout(function(){
                dispatch(setLoadingSpace(false))
            }, 2000);
        }else{
            dispatch(setLoadingSpace(true))
            getSummaryDocState(summaryDocSelectedSpace)
            var intervalNo =  setInterval(function(){
                                    getSummaryDocState(summaryDocSelectedSpace)
                                },5000);
        }
        return () => {
            clearInterval(intervalNo);
        };

    }, [summaryDocSelectedSpace]);
  


    function getSummaryDocState(item){
        

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "spaceId" : item.space_id,
            "type" : "summaryDoc"
        });
        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/space/getSpace',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            console.log(response.data)
            if( response.data.code == 100){
                console.log(response.data)
                var spaceInfo = response.data.spaceInfo;

                if(typeof spaceInfo.space_id != 'undefined') {
                    // dispatch( setSummaryDocSelectedSpace(spaceInfo) );
                    setSummaryDocState(spaceInfo.state)
                }

                if(spaceInfo.state == 100){
                    var spaceData = response.data.spaceData;
                    var tmpSummaryList = summaryDocList;
                    const entry = Object.entries(tmpSummaryList).find(([key, value]) => value.space_id === spaceInfo.space_id);
                    if (entry) {
                        const [key, value] = entry;
                        value.state = spaceInfo.state;
                        tmpSummaryList = [...tmpSummaryList]
                        
                        dispatch(setSummaryDocList(tmpSummaryList))
                    
                        
                    }

                    dispatch( setSummaryDocSelectedSpace(spaceInfo) );
                    dispatch(setSummaryDocSelectedHistory(spaceData));
                    
                    if(spaceInfo.answering != false){
                        dispatch(setLoadingWork(true))
                        dispatch(setLoadingAnswering(true))
                    }else{
                        dispatch(setLoadingWork(false))
                        dispatch(setLoadingAnswering(false))
                    }                          
                    
                }else{
              
                    dispatch(setLoadingSpace(true))
                }
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setLoadingWork(false))
            dispatch(setLoadingAnswering(false))
            //dispatch( setLoadingSummaryDocList(false) );
        });        
    }

    function RenderStateText() {
        console.log(summaryDocState, ' render state')

        switch(summaryDocState) {
            case 200:
                return <>{ t("summaryDoc.state_200") }</>
            case 201:
                return <>{ t("summaryDoc.state_201") }</>
            case 202:
                return <>{ t("summaryDoc.state_202") }</>
            case 203:
                return <>{ t("summaryDoc.state_203") }</>
            case 204:
                return <>{ t("summaryDoc.state_204") }</>
            default:
                return <>{ t("summaryDoc.state_default") }</>
        }
    }

    return (
        <>
            <div className={ uiSmallAiBox ? "small-loading" : "modal-loading" }>
                <div>
                    <div className='content-center'>
                        <FoldingCube size={30} color="#C0C0C0" />
                    </div>
                    <div>
                        <RenderStateText />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryDocChatLoading
//import axios from 'axios';

//Actions
const SET_LOADING_QUIZ_LIST = "loading/SET_LOADING_QUIZ_LIST";
const SET_LOADING_LEFTSIDEBAR = "loading/SET_LOADING_LEFTSIDEBAR";
const SET_LOADING_CENTER = "loading/SET_LOADING_CENTER";
const SET_LOADING_MODAL_REPORT = "loading/SET_LOADING_MODAL_REPORT";
const SET_LOADING_MODAL_PHOTO = "loading/SET_LOADING_MODAL_PHOTO";
const SET_LOADING_MAIN_LIST = "loading/SET_LOADING_MAIN_LIST"; // 메인화면 퀴즈목록 로딩
const SET_LOADING_AUTH = "loading/SET_LOADING_AUTH";
const SET_LOADING_SPACE = "loading/SET_LOADING_SPACE"; // getSpace Loading
const SET_LOADING_SPACE_LIST = "loading/SET_LOADING_SPACE_LIST"; // getSpaceList Loading
const SET_LOADING_WORK = "loading/SET_LOADING_WORK"; // setWork Loading
const SET_LOADING_TRANSLATE_TEXT = "loading/SET_LOADING_TRANSLATE_TEXT"; // translate text timeout 1s Loading
const SET_LOADING_TRANSLATE_DOC = "loading/SET_LOADING_TRANSLATE_DOC"; // get s3 upload url Loading
const SET_LOADING_DOC_STATE = "loading/SET_LOADING_DOC_STATE"; // 문서 번역 상태 로딩
const SET_LOADING_DOC_SPACE_LIST = "loading/SET_LOADING_DOC_SPACE_LIST"; // doc space list Loading
const SET_LOADING_DOC_DOWNLOAD = "loading/SET_LOADING_DOC_DONWLOAD"; // doc download url loading
const SET_LOADING_SUMMARY_DOC_LIST = "loading/SET_LOADING_SUMMARY_DOC_LIST"; // summary doc get list loading
const SET_LOADING_TRANSLATE_SELECTION = "loading/SET_LOADING_TRANSLATE_SELECTION"; // 단축키 번역 로딩
const SET_LOADING_TOOLTIP_CHANGE = "loading/SET_LOADING_TOOLTIP_CHANGE"; // 툴팁 번역 언어 변경
const SET_LOADING_ANSWERING = "loading/SET_LOADING_ANSWERING";

// 액션 생성 함수
export const setLoadingQuizList = loadingQuizList => ({ type: SET_LOADING_QUIZ_LIST, loadingQuizList});
export const setLoadingLeftsidebar = loadingLeftSidebar => ({ type: SET_LOADING_LEFTSIDEBAR, loadingLeftSidebar });
export const setLoadingCenter = loadingCenter => ({ type: SET_LOADING_CENTER, loadingCenter });
export const setLoadingModalReport = loadingModalReport => ({ type: SET_LOADING_MODAL_REPORT, loadingModalReport });
export const setLoadingModalPhoto = loadingModalPhoto => ({ type: SET_LOADING_MODAL_PHOTO, loadingModalPhoto });
export const setLoadingMainList = loadingMainList => ({ type: SET_LOADING_MAIN_LIST, loadingMainList });
export const setLoadingAuth = loadingAuth => ({ type: SET_LOADING_AUTH, loadingAuth });

export const setLoadingSpace = loadingSpace => ({ type: SET_LOADING_SPACE, loadingSpace });
export const setLoadingSpaceList = loadingSpaceList => ({ type: SET_LOADING_SPACE_LIST, loadingSpaceList });
export const setLoadingWork = loadingWork => ({ type: SET_LOADING_WORK, loadingWork });

export const setLoadingTranslateText = loadingTranslateText => ({ type: SET_LOADING_TRANSLATE_TEXT, loadingTranslateText });
export const setLoadingTranslateDoc = loadingTranslateDoc => ({ type: SET_LOADING_TRANSLATE_DOC, loadingTranslateDoc });
export const setLoadingDocState = loadingDocState => ({ type: SET_LOADING_DOC_STATE, loadingDocState });
export const setLoadingDocSpaceList = loadingDocSpaceList => ({ type: SET_LOADING_DOC_SPACE_LIST, loadingDocSpaceList });
export const setLoadingDocDownload = loadingDocDownload => ({ type: SET_LOADING_DOC_DOWNLOAD, loadingDocDownload });
export const setLoadingSummaryDocList = loadingSummaryDocList => ({ type: SET_LOADING_SUMMARY_DOC_LIST, loadingSummaryDocList });
export const setLoadingTranslateSelection = loadingTranslateSelection => ({ type: SET_LOADING_TRANSLATE_SELECTION, loadingTranslateSelection });
export const setLoadingTooltipChange = loadingTooltipChange => ({ type: SET_LOADING_TOOLTIP_CHANGE, loadingTooltipChange });

export const setLoadingAnswering = loadingAnswering => ({ type: SET_LOADING_ANSWERING, loadingAnswering });

// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
    loadingQuizList: false,
    loadingLeftSidebar : false,
    loadingCenter : false,
    loadingModalReport : false, // 모달창 시스템 상태
    loadingModalPhoto: false, // 모달창 대기방, 시험중, 검토 사진
    loadingMainList: false, // 메인화면 퀴즈 목록
    loadingAuth: false,

    loadingSpace: false, // 채팅 내역 로딩
    loadingSpaceList: false, // 채팅방 목록 로딩
    // loadingSetWork: false, // 메시지 전송 중 추가 전송 방지
    loadingWork: false,
    loadingTranslateText: false,
    loadingTranslateDoc: false, // 문서 번역 로딩
    loadingDocState: false, // 문서 번역 상태 로딩
    loadingDocSpaceList: false, // 번역 문서 목록 로딩
    loadingDocDownload: false, // 번역 문서 다운로드시 로딩
    loadingSummaryDocList: false, // 문서 요약 목록 로딩
    loadingTranslateSelection: false, // 단축키 번역 로딩
    loadingTooltipChange: false, // 툴팁 번역 언어 변경 로딩
    loadingAnswering: false,
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_QUIZ_LIST:
      return {
        ...state,
        loadingQuizList: action.loadingQuizList
      }
    case SET_LOADING_LEFTSIDEBAR:
      return {
        ...state,
        loadingLeftSidebar : action.loadingLeftSidebar
      }
    case SET_LOADING_CENTER:
      return {
        ...state,
        loadingCenter : action.loadingCenter
      }
    case SET_LOADING_MODAL_REPORT:
      return {
        ...state,
        loadingModalReport: action.loadingModalReport
      }
    case SET_LOADING_MODAL_PHOTO:
      return {
        ...state,
        loadingModalPhoto: action.loadingModalPhoto
      }
    case SET_LOADING_MAIN_LIST:
      return {
        ...state,
        loadingMainList: action.loadingMainList,
      }
    case SET_LOADING_AUTH:
      return {
        ...state,
        loadingAuth: action.loadingAuth,
      }
    
    case SET_LOADING_SPACE:
      return {
        ...state,
        loadingSpace: action.loadingSpace,
      }
    case SET_LOADING_SPACE_LIST:
      return {
        ...state,
        loadingSpaceList: action.loadingSpaceList,
      }
    case SET_LOADING_WORK:
      return {
        ...state,
        loadingWork: action.loadingWork,
      }
    case SET_LOADING_TRANSLATE_TEXT:
      return {
        ...state,
        loadingTranslateText: action.loadingTranslateText
      }
    case SET_LOADING_TRANSLATE_DOC:
      return {
        ...state,
        loadingTranslateDoc: action.loadingTranslateDoc
      }
    case SET_LOADING_DOC_STATE:
      return {
        ...state,
        loadingDocState: action.loadingDocState
      }
    case SET_LOADING_DOC_SPACE_LIST:
      return {
        ...state,
        loadingDocSpaceList: action.loadingDocSpaceList
      }
    case SET_LOADING_DOC_DOWNLOAD:
      return {
        ...state,
        loadingDocDownload: action.loadingDocDownload
      }
    case SET_LOADING_SUMMARY_DOC_LIST:
      return {
        ...state,
        loadingSummaryDocList: action.loadingSummaryDocList
      }
    case SET_LOADING_TRANSLATE_SELECTION:
      return {
        ...state,
        loadingTranslateSelection: action.loadingTranslateSelection
      }
    case SET_LOADING_TOOLTIP_CHANGE:
      return {
        ...state,
        loadingTooltipChange: action.loadingTooltipChange
      }
    case SET_LOADING_ANSWERING:
      return {
        ...state,
        loadingAnswering: action.loadingAnswering
      }      
    default:
        return state;
  }
} 
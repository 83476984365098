//import axios from 'axios';

//Actions
const SET_ICON = "tooltip/SET_ICON";
const SET_TEXT = "tooltip/SET_TEXT";
const SET_POSITION = "tooltip/SET_POSITION";
const SET_MODAL_POSITION = "tooltip/SET_MODAL_POSITION";
const SET_SOURCE_LANG = "tooltip/SET_SOURCE_LANG";
const SET_SOURCE_TEXT = "tooltip/SET_SOURCE_TEXT";
const SET_TARGET_TEXT = "tooltip/SET_TARGET_TEXT";
const SET_OPTIONS = "tooltip/SET_OPTIONS";
const SET_TRANSLATE_SELECTION = "tooltip/SET_TRANSLATE_SELECTION"

type tooltipPosition = {
    x: number,
    y: number
}

// 액션 생성 함수
export const setIcon = (icon: boolean) => ({ type: SET_ICON, icon });
export const setText = (text: string) => ({ type: SET_TEXT, text });
export const setPosition = (position: tooltipPosition) => ({ type: SET_POSITION, position }) // icon position
export const setModalPosition = (modalPosition: tooltipPosition) => ({ type: SET_MODAL_POSITION, modalPosition }) // tooltip modal position
export const setSourceLang = (sourceLang: string) => ({ type: SET_SOURCE_LANG, sourceLang })
export const setSourceText = (sourceText: string) => ({ type: SET_SOURCE_TEXT, sourceText })
export const setTargetText = (targetText: string) => ({ type: SET_TARGET_TEXT, targetText })
export const setOptions = (options: []) => ({ type: SET_OPTIONS, options })
export const setTranslateSelection = (translateSelection: boolean) => ({ type: SET_TRANSLATE_SELECTION, translateSelection })

// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
    icon: false,
    text : "", // toltip text
    position: {
        x: 0,
        y: 0
    },
    modalPosition: {
        x: 0,
        y: 0,
    },
    sourceLang: "",
    sourceText: "",
    targetText: "",
    options: [],
    translateSelection: false,
};

export default function tooltip(state = initialState, action) {
  switch (action.type) {
    case SET_ICON:
        return {
            ...state,
            icon: action.icon
        }
    case SET_TEXT:
        return {
            ...state,
            text : action.text
        }
    case SET_POSITION:
        return {
            ...state,
            position: action.position
        }
    case SET_MODAL_POSITION:
        return {
            ...state,
            modalPosition: action.modalPosition
        }
    case SET_SOURCE_LANG:
        return {
            ...state,
            sourceLang: action.sourceLang
        }
    case SET_SOURCE_TEXT:
        return {
            ...state,
            sourceText: action.sourceText
        }
    case SET_TARGET_TEXT:
        return {
            ...state,
            targetText: action.targetText
        }
    case SET_OPTIONS:
        return {
            ...state,
            options: action.options
        }
    case SET_TRANSLATE_SELECTION:
        return {
            ...state,
            translateSelection: action.translateSelection
        }
    default:
        return state;
  }
} 
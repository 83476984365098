import React from "react";
import { useSelector } from "react-redux";
import { ThreeBounce } from 'better-react-spinkit'
import { useTranslation } from "react-i18next";

const TranslateSelectionLoading = () => {

    const loadingTranslateSelection = useSelector(state => state.loading.loadingTranslateSelection);

    const { t, i18n } = useTranslation();

    return (
        loadingTranslateSelection && (
            <div className="translate-text-loading-container">
                <div className="translate-text-loading-box">
                    <ThreeBounce color={ "white" } />&nbsp;
                    { t('translateText.loading') }
                </div>
            </div>
        )
    )
}

export default TranslateSelectionLoading;
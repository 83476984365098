import './resources/assets/styles/sass/Common.scss';

import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import rootReducer from './app/modules';
import logger from 'redux-logger';

import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';


console.log( IS_DEV )
if(IS_DEV){
    console.log("It's DEV");
    var store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk, logger)) );
}else{
    var store = createStore(rootReducer );    
    console.log = function() {}

}

const persistor = persistStore(store);

const mainElement = document.createElement('div');
mainElement.id = "csmsai";

let headerEle = document.getElementById('page-header');



if(headerEle != null) {
    document.body.appendChild(mainElement);
    
    ReactDOM.render(
        <Provider store={store} >
            <App />
        </Provider>,
        mainElement
    );
} // ai chat not use : vod

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals 
import i18next from "i18next";

export const papagoSupportLang = () => {
    return {
        "auto": i18next.t('lang.auto'),
        "ko": i18next.t('lang.ko'),
        "en": i18next.t('lang.en'),
        "ja": i18next.t('lang.ja'),
        "zh-CN": i18next.t('lang.zh-cn'),
        "zh-TW": i18next.t('lang.zh-tw'),
        "vi": i18next.t('lang.vi'),
        "th": i18next.t('lang.th'),
        "id": i18next.t('lang.id'),
        "fr": i18next.t('lang.fr'),
        "es": i18next.t('lang.es'),
        "ru": i18next.t('lang.ru'),
        "de": i18next.t('lang.de'),
        "it": i18next.t('lang.it'),
    }

}
export const googleSupportLang = () => {
    return {
        "auto": i18next.t('lang.auto'),
        "af": i18next.t('lang.af'),
        "sq": i18next.t('lang.sq'),
        "am": i18next.t('lang.am'),
        "ar": i18next.t('lang.ar'),
        "hy": i18next.t('lang.hy'),
        "as": i18next.t('lang.as'),
        "ay": i18next.t('lang.ay'),
        "az": i18next.t('lang.az'),
        "bm": i18next.t('lang.bm'),
        "eu": i18next.t('lang.eu'),
        "be": i18next.t('lang.be'),
        "bn": i18next.t('lang.bn'),
        "bho": i18next.t('lang.bho'),
        "bs": i18next.t('lang.bs'),
        "bg": i18next.t('lang.bg'),
        "ca": i18next.t('lang.ca'),
        "ceb": i18next.t('lang.ceb'),
        "zh-CN": i18next.t('lang.zh-cn'),
        "zh-TW": i18next.t('lang.zh-tw'),
        "co": i18next.t('lang.co'),
        "hr": i18next.t('lang.hr'),
        "cs": i18next.t('lang.cs'),
        "da": i18next.t('lang.da'),
        "dv": i18next.t('lang.dv'),
        "doi": i18next.t('lang.doi'),
        "nl": i18next.t('lang.nl'),
        "en": i18next.t('lang.en'),
        "eo": i18next.t('lang.eo'),
        "et": i18next.t('lang.et'),
        "ee": i18next.t('lang.ee'),
        "fil": i18next.t('lang.fil'),
        "fi": i18next.t('lang.fi'),
        "fr": i18next.t('lang.fr'),
        "fy": i18next.t('lang.fy'),
        "gl": i18next.t('lang.gl'),
        "ka": i18next.t('lang.ka'),
        "de": i18next.t('lang.de'),
        "el": i18next.t('lang.el'),
        "gn": i18next.t('lang.gn'),
        "gu": i18next.t('lang.gu'),
        "ht": i18next.t('lang.ht'),
        "ha": i18next.t('lang.ha'),
        "haw": i18next.t('lang.haw'),
        "he": i18next.t('lang.he'),
        "hi": i18next.t('lang.hi'),
        "hmn": i18next.t('lang.hmn'),
        "hu": i18next.t('lang.hu'),
        "is": i18next.t('lang.is'),
        "ig": i18next.t('lang.ig'),
        "ilo": i18next.t('lang.ilo'),
        "id": i18next.t('lang.id'),
        "ga": i18next.t('lang.ga'),
        "it": i18next.t('lang.it'),
        "ja": i18next.t('lang.ja'),
        "jv": i18next.t('lang.jv'),
        "kn": i18next.t('lang.kn'),
        "kk": i18next.t('lang.kk'),
        "km": i18next.t('lang.km'),
        "rw": i18next.t('lang.rw'),
        "gom": i18next.t('lang.gom'),
        "ko": i18next.t('lang.ko'),
        "kri": i18next.t('lang.kri'),
        "ku": i18next.t('lang.ku'),
        "ckb": i18next.t('lang.ckb'),
        "ky": i18next.t('lang.ky'),
        "lo": i18next.t('lang.lo'),
        "la": i18next.t('lang.la'),
        "lv": i18next.t('lang.lv'),
        "ln": i18next.t('lang.ln'),
        "lt": i18next.t('lang.lt'),
        "lg": i18next.t('lang.lg'),
        "lb": i18next.t('lang.lb'),
        "mk": i18next.t('lang.mk'),
        "mai": i18next.t('lang.mai'),
        "mg": i18next.t('lang.mg'),
        "ms": i18next.t('lang.ms'),
        "ml": i18next.t('lang.ml'),
        "mt": i18next.t('lang.mt'),
        "mi": i18next.t('lang.mi'),
        "mr": i18next.t('lang.mr'),
        "mni-Mtei": i18next.t('lang.mni-Mtei'),
        "lus": i18next.t('lang.lus'),
        "mn": i18next.t('lang.mn'),
        "my": i18next.t('lang.my'),
        "ne": i18next.t('lang.ne'),
        "no": i18next.t('lang.no'),
        "ny": i18next.t('lang.ny'),
        "or": i18next.t('lang.or'),
        "om": i18next.t('lang.om'),
        "ps": i18next.t('lang.ps'),
        "fa": i18next.t('lang.fa'),
        "pl": i18next.t('lang.pl'),
        "pt": i18next.t('lang.google-pt'),
        "pa": i18next.t('lang.pa'),
        "qu": i18next.t('lang.qu'),
        "ro": i18next.t('lang.ro'),
        "ru": i18next.t('lang.ru'),
        "sm": i18next.t('lang.sm'),
        "sa": i18next.t('lang.sa'),
        "gd": i18next.t('lang.gd'),
        "nso": i18next.t('lang.nso'),
        "sr": i18next.t('lang.sr'),
        "st": i18next.t('lang.st'),
        "sn": i18next.t('lang.sn'),
        "sd": i18next.t('lang.sd'),
        "si": i18next.t('lang.si'),
        "sk": i18next.t('lang.sk'),
        "sl": i18next.t('lang.sl'),
        "so": i18next.t('lang.so'),
        "es": i18next.t('lang.es'),
        "su": i18next.t('lang.su'),
        "sw": i18next.t('lang.sw'),
        "sv": i18next.t('lang.sv'),
        "tl": i18next.t('lang.tl'),
        "tg": i18next.t('lang.tg'),
        "ta": i18next.t('lang.ta'),
        "tt": i18next.t('lang.tt'),
        "te": i18next.t('lang.te'),
        "th": i18next.t('lang.th'),
        "ti": i18next.t('lang.ti'),
        "ts": i18next.t('lang.ts'),
        "tr": i18next.t('lang.tr'),
        "tk": i18next.t('lang.tk'),
        "ak": i18next.t('lang.ak'),
        "uk": i18next.t('lang.uk'),
        "ur": i18next.t('lang.ur'),
        "ug": i18next.t('lang.ug'),
        "uz": i18next.t('lang.uz'),
        "vi": i18next.t('lang.vi'),
        "cy": i18next.t('lang.cy'),
        "xh": i18next.t('lang.xh'),
        "yi": i18next.t('lang.yi'),
        "yo": i18next.t('lang.yo'),
        "zu": i18next.t('lang.zu')
    }
    
};
export const deepLSupportLang = () => {
    return {
        "auto": i18next.t('lang.auto'),
        "bg" : i18next.t('lang.bg'),
        "cs" : i18next.t('lang.cs'),
        "da" : i18next.t('lang.da'),
        "de" : i18next.t('lang.de'),
        "el" : i18next.t('lang.el'),
        "en" : i18next.t('lang.en'),
        "es" : i18next.t('lang.es'),
        "et" : i18next.t('lang.et'),
        "fi" : i18next.t('lang.fi'),
        "fr" : i18next.t('lang.fr'),
        "hu" : i18next.t('lang.hu'),
        "id" : i18next.t('lang.id'),
        "it" : i18next.t('lang.it'),
        "ja" : i18next.t('lang.ja'),
        "ko" : i18next.t('lang.ko'),
        "lt" : i18next.t('lang.lt'),
        "lv" : i18next.t('lang.lv'),
        "nb" : i18next.t('lang.nb'),
        "nl" : i18next.t('lang.nl'),
        "pl" : i18next.t('lang.pl'),
        "pt" : i18next.t('lang.deepl-pt'),
        "ro" : i18next.t('lang.ro'),
        "ru" : i18next.t('lang.ru'),
        "sk" : i18next.t('lang.sk'),
        "sl" : i18next.t('lang.sl'),
        "sv" : i18next.t('lang.sv'),
        "tr" : i18next.t('lang.tr'),
        "uk" : i18next.t('lang.uk'),
        "zh" : i18next.t('lang.zh-cn'),
    }
    
}
export const deepLTargetLang = () => {
    return {
        "bg" : i18next.t('lang.bg'),
        "cs" : i18next.t('lang.cs'),
        "da" : i18next.t('lang.da'),
        "de" : i18next.t('lang.de'),
        "el" : i18next.t('lang.el'),
        "en-gb" : i18next.t('lang.en-gb'),
        "en-us" : i18next.t('lang.en-us'),
        "es" : i18next.t('lang.es'),
        "et" : i18next.t('lang.et'),
        "fi" : i18next.t('lang.fi'),
        "fr" : i18next.t('lang.fr'),
        "hu" : i18next.t('lang.hu'),
        "id" : i18next.t('lang.id'),
        "it" : i18next.t('lang.it'),
        "ja" : i18next.t('lang.ja'),
        "ko" : i18next.t('lang.ko'),
        "lt" : i18next.t('lang.lt'),
        "lv" : i18next.t('lang.lv'),
        "nb" : i18next.t('lang.nb'),
        "nl" : i18next.t('lang.nl'),
        "pl" : i18next.t('lang.pl'),
        "pt-br" : i18next.t('lang.pt-br'),
        "pt-pt" : i18next.t('lang.pt-pt'),
        "ro" : i18next.t('lang.ro'),
        "ru" : i18next.t('lang.ru'),
        "sk" : i18next.t('lang.sk'),
        "sl" : i18next.t('lang.sl'),
        "sv" : i18next.t('lang.sv'),
        "tr" : i18next.t('lang.tr'),
        "uk" : i18next.t('lang.uk'),
        "zh" : i18next.t('lang.zh'),
    }
    
}

export const papagoKoGroup = () => {
    return {
        "ko": i18next.t('lang.ko'),
        'en': i18next.t('lang.en'),
        'ja': i18next.t('lang.ja'),
        'zh-cn': i18next.t('lang.zh-cn'),
        'zh-tw': i18next.t('lang.zh-tw'),
        'vi': i18next.t('lang.vi'),
        'th': i18next.t('lang.th'),
        'id': i18next.t('lang.id'),
        'fr': i18next.t('lang.fr'),
        'es': i18next.t('lang.es'),
        'ru': i18next.t('lang.ru'),
        'de': i18next.t('lang.de'),
        'it': i18next.t('lang.it')
    }
}

export const papagoEnJaGroup = () => {
    return {
        'ko': i18next.t('lang.ko'),
        'en': i18next.t('lang.en'),
        'ja': i18next.t('lang.ja'),
        'zh-cn': i18next.t('lang.zh-cn'),
        'zh-tw': i18next.t('lang.zh-tw'),
        'vi': i18next.t('lang.vi'),
        'th': i18next.t('lang.th'),
        'id': i18next.t('lang.id'),
        'fr': i18next.t('lang.fr'),
    }
}

/*
export const papagoSupportLang() = {
    "auto": i18next.t('lang.auto'),
    "ko": i18next.t('lang.ko'),
    "en": i18next.t('lang.en'),
    "ja": i18next.t('lang.ja'),
    "zh-CN": i18next.t('lang.zh-cn'),
    "zh-TW": i18next.t('lang.zh-tw'),
    "vi": i18next.t('lang.vi'),
    "th": i18next.t('lang.th'),
    "id": i18next.t('lang.id'),
    "fr": i18next.t('lang.fr'),
    "es": i18next.t('lang.es'),
    "ru": i18next.t('lang.ru'),
    "de": i18next.t('lang.de'),
    "it": i18next.t('lang.it'),
}
export const googleSupportLang = {
    "auto": i18next.t('lang.auto'),
    "af": i18next.t('lang.af'),
    "sq": i18next.t('lang.sq'),
    "am": i18next.t('lang.am'),
    "ar": i18next.t('lang.ar'),
    "hy": i18next.t('lang.hy'),
    "as": i18next.t('lang.as'),
    "ay": i18next.t('lang.ay'),
    "az": i18next.t('lang.az'),
    "bm": i18next.t('lang.bm'),
    "eu": i18next.t('lang.eu'),
    "be": i18next.t('lang.be'),
    "bn": i18next.t('lang.bn'),
    "bho": i18next.t('lang.bho'),
    "bs": i18next.t('lang.bs'),
    "bg": i18next.t('lang.bg'),
    "ca": i18next.t('lang.ca'),
    "ceb": i18next.t('lang.ceb'),
    "zh-CN": i18next.t('lang.zh-cn'),
    "zh-TW": i18next.t('lang.zh-tw'),
    "co": i18next.t('lang.co'),
    "hr": i18next.t('lang.hr'),
    "cs": i18next.t('lang.cs'),
    "da": i18next.t('lang.da'),
    "dv": i18next.t('lang.dv'),
    "doi": i18next.t('lang.doi'),
    "nl": i18next.t('lang.nl'),
    "en": i18next.t('lang.en'),
    "eo": i18next.t('lang.eo'),
    "et": i18next.t('lang.et'),
    "ee": i18next.t('lang.ee'),
    "fil": i18next.t('lang.fil'),
    "fi": i18next.t('lang.fi'),
    "fr": i18next.t('lang.fr'),
    "fy": i18next.t('lang.fy'),
    "gl": i18next.t('lang.gl'),
    "ka": i18next.t('lang.ka'),
    "de": i18next.t('lang.de'),
    "el": i18next.t('lang.el'),
    "gn": i18next.t('lang.gn'),
    "gu": i18next.t('lang.gu'),
    "ht": i18next.t('lang.ht'),
    "ha": i18next.t('lang.ha'),
    "haw": i18next.t('lang.haw'),
    "he": i18next.t('lang.he'),
    "hi": i18next.t('lang.hi'),
    "hmn": i18next.t('lang.hmn'),
    "hu": i18next.t('lang.hu'),
    "is": i18next.t('lang.is'),
    "ig": i18next.t('lang.ig'),
    "ilo": i18next.t('lang.ilo'),
    "id": i18next.t('lang.id'),
    "ga": i18next.t('lang.ga'),
    "it": i18next.t('lang.it'),
    "ja": i18next.t('lang.ja'),
    "jv": i18next.t('lang.jv'),
    "kn": i18next.t('lang.kn'),
    "kk": i18next.t('lang.kk'),
    "km": i18next.t('lang.km'),
    "rw": i18next.t('lang.rw'),
    "gom": i18next.t('lang.gom'),
    "ko": i18next.t('lang.ko'),
    "kri": i18next.t('lang.kri'),
    "ku": i18next.t('lang.ku'),
    "ckb": i18next.t('lang.ckb'),
    "ky": i18next.t('lang.ky'),
    "lo": i18next.t('lang.lo'),
    "la": i18next.t('lang.la'),
    "lv": i18next.t('lang.lv'),
    "ln": i18next.t('lang.ln'),
    "lt": i18next.t('lang.lt'),
    "lg": i18next.t('lang.lg'),
    "lb": i18next.t('lang.lb'),
    "mk": i18next.t('lang.mk'),
    "mai": i18next.t('lang.mai'),
    "mg": i18next.t('lang.mg'),
    "ms": i18next.t('lang.ms'),
    "ml": i18next.t('lang.ml'),
    "mt": i18next.t('lang.mt'),
    "mi": i18next.t('lang.mi'),
    "mr": i18next.t('lang.mr'),
    "mni-Mtei": i18next.t('lang.mni-Mtei'),
    "lus": i18next.t('lang.lus'),
    "mn": i18next.t('lang.mn'),
    "my": i18next.t('lang.my'),
    "ne": i18next.t('lang.ne'),
    "no": i18next.t('lang.no'),
    "ny": i18next.t('lang.ny'),
    "or": i18next.t('lang.or'),
    "om": i18next.t('lang.om'),
    "ps": i18next.t('lang.ps'),
    "fa": i18next.t('lang.fa'),
    "pl": i18next.t('lang.pl'),
    "pt": i18next.t('lang.google-pt'),
    "pa": i18next.t('lang.pa'),
    "qu": i18next.t('lang.qu'),
    "ro": i18next.t('lang.ro'),
    "ru": i18next.t('lang.ru'),
    "sm": i18next.t('lang.sm'),
    "sa": i18next.t('lang.sa'),
    "gd": i18next.t('lang.gd'),
    "nso": i18next.t('lang.nso'),
    "sr": i18next.t('lang.sr'),
    "st": i18next.t('lang.st'),
    "sn": i18next.t('lang.sn'),
    "sd": i18next.t('lang.sd'),
    "si": i18next.t('lang.si'),
    "sk": i18next.t('lang.sk'),
    "sl": i18next.t('lang.sl'),
    "so": i18next.t('lang.so'),
    "es": i18next.t('lang.es'),
    "su": i18next.t('lang.su'),
    "sw": i18next.t('lang.sw'),
    "sv": i18next.t('lang.sv'),
    "tl": i18next.t('lang.tl'),
    "tg": i18next.t('lang.tg'),
    "ta": i18next.t('lang.ta'),
    "tt": i18next.t('lang.tt'),
    "te": i18next.t('lang.te'),
    "th": i18next.t('lang.th'),
    "ti": i18next.t('lang.ti'),
    "ts": i18next.t('lang.ts'),
    "tr": i18next.t('lang.tr'),
    "tk": i18next.t('lang.tk'),
    "ak": i18next.t('lang.ak'),
    "uk": i18next.t('lang.uk'),
    "ur": i18next.t('lang.ur'),
    "ug": i18next.t('lang.ug'),
    "uz": i18next.t('lang.uz'),
    "vi": i18next.t('lang.vi'),
    "cy": i18next.t('lang.cy'),
    "xh": i18next.t('lang.xh'),
    "yi": i18next.t('lang.yi'),
    "yo": i18next.t('lang.yo'),
    "zu": i18next.t('lang.zu')
};
export const deepLSupportLang = {
    "auto": i18next.t('lang.auto'),
    "bg" : i18next.t('lang.bg'),
    "cs" : i18next.t('lang.cs'),
    "da" : i18next.t('lang.da'),
    "de" : i18next.t('lang.de'),
    "el" : i18next.t('lang.el'),
    "en" : i18next.t('lang.en'),
    "es" : i18next.t('lang.es'),
    "et" : i18next.t('lang.et'),
    "fi" : i18next.t('lang.fi'),
    "fr" : i18next.t('lang.fr'),
    "hu" : i18next.t('lang.hu'),
    "id" : i18next.t('lang.id'),
    "it" : i18next.t('lang.it'),
    "ja" : i18next.t('lang.ja'),
    "ko" : i18next.t('lang.ko'),
    "lt" : i18next.t('lang.lt'),
    "lv" : i18next.t('lang.lv'),
    "nb" : i18next.t('lang.nb'),
    "nl" : i18next.t('lang.nl'),
    "pl" : i18next.t('lang.pl'),
    "pt" : i18next.t('lang.deepl-pt'),
    "ro" : i18next.t('lang.ro'),
    "ru" : i18next.t('lang.ru'),
    "sk" : i18next.t('lang.sk'),
    "sl" : i18next.t('lang.sl'),
    "sv" : i18next.t('lang.sv'),
    "tr" : i18next.t('lang.tr'),
    "uk" : i18next.t('lang.uk'),
    "zh" : i18next.t('lang.zh-cn'),
}
export const deepLTargetLang = {
    "bg" : i18next.t('lang.bg'),
    "cs" : i18next.t('lang.cs'),
    "da" : i18next.t('lang.da'),
    "de" : i18next.t('lang.de'),
    "el" : i18next.t('lang.el'),
    "en-gb" : i18next.t('lang.en-gb'),
    "en-us" : i18next.t('lang.en-us'),
    "es" : i18next.t('lang.es'),
    "et" : i18next.t('lang.et'),
    "fi" : i18next.t('lang.fi'),
    "fr" : i18next.t('lang.fr'),
    "hu" : i18next.t('lang.hu'),
    "id" : i18next.t('lang.id'),
    "it" : i18next.t('lang.it'),
    "ja" : i18next.t('lang.ja'),
    "ko" : i18next.t('lang.ko'),
    "lt" : i18next.t('lang.lt'),
    "lv" : i18next.t('lang.lv'),
    "nb" : i18next.t('lang.nb'),
    "nl" : i18next.t('lang.nl'),
    "pl" : i18next.t('lang.pl'),
    "pt-br" : i18next.t('lang.pt-br'),
    "pt-pt" : i18next.t('lang.pt-pt'),
    "ro" : i18next.t('lang.ro'),
    "ru" : i18next.t('lang.ru'),
    "sk" : i18next.t('lang.sk'),
    "sl" : i18next.t('lang.sl'),
    "sv" : i18next.t('lang.sv'),
    "tr" : i18next.t('lang.tr'),
    "uk" : i18next.t('lang.uk'),
    "zh" : i18next.t('lang.zh'),
}

export const papagoKoGroup = {
    "ko": i18next.t('lang.ko'),
    'en': i18next.t('lang.en'),
    'ja': i18next.t('lang.ja'),
    'zh-cn': i18next.t('lang.zh-cn'),
    'zh-tw': i18next.t('lang.zh-tw'),
    'vi': i18next.t('lang.vi'),
    'th': i18next.t('lang.th'),
    'id': i18next.t('lang.id'),
    'fr': i18next.t('lang.fr'),
    'es': i18next.t('lang.es'),
    'ru': i18next.t('lang.ru'),
    'de': i18next.t('lang.de'),
    'it': i18next.t('lang.it')
}

export const papagoEnJaGroup = {
    'ko': i18next.t('lang.ko'),
    'en': i18next.t('lang.en'),
    'ja': i18next.t('lang.ja'),
    'zh-cn': i18next.t('lang.zh-cn'),
    'zh-tw': i18next.t('lang.zh-tw'),
    'vi': i18next.t('lang.vi'),
    'th': i18next.t('lang.th'),
    'id': i18next.t('lang.id'),
    'fr': i18next.t('lang.fr'),
}
*/
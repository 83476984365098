import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ChatSpace from '../../components/apps/aiChat/ChatSpace';
import PromptManager from '../../components/apps/aiChat/PromptManager';
import ChatInputGroup from '../../components/apps/aiChat/ChatInputGroup';
import { setLoadingSpace } from '../../modules/loading';
import { RootState } from '../../modules';
import PromptPage from '../../components/apps/aiChat/PromptPage';

const ChatSpaceContainer = () => {

    const dispatch = useDispatch();

    const uiSmallAiBox = useSelector((state: RootState) => state.ui.uiSmallAiBox);
    const uiHideChatSidebar = useSelector((state: RootState) => state.ui.uiHideChatSidebar);
    const aiChatSelectedSpace = useSelector((state: RootState) => state.aiChat.aiChatSelectedSpace);
    const aiChatSelectedSpaceChatHistory = useSelector((state: RootState) => state.aiChat.aiChatSelectedSpaceChatHistory);
    const aiChatPromptPage = useSelector((state: RootState) => state.aiChat.aiChatPromptPage);

    useEffect(() => {
        return () => {
            console.log('Clean Up Chat Space Loading')
            dispatch(setLoadingSpace(false));
        }
    }, [])

    return (
        <>
            <div className="container-uiChat fr" style={ ( !uiSmallAiBox && uiHideChatSidebar ) ? { width: '100%' } : {} }>
                { 
                    (aiChatSelectedSpace == false && aiChatSelectedSpaceChatHistory.length == 0) ? (
                        <>
                            {
                                aiChatPromptPage ? (
                                    <PromptPage />
                                ) : (
                                    <PromptManager />
                                )
                            }
                        </>
                    )
                    :
                    <ChatSpace />
                }
            </div>
            <ChatInputGroup />
        </>
    )
}

export default ChatSpaceContainer
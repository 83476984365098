import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import SummaryDocSidebar from "../../components/apps/summaryDoc/SummaryDocSidebar";
import { setSummaryDocList } from "../../modules/summaryDoc";
import { setLoadingSummaryDocList } from "../../modules/loading";

const SummaryDocSidebarContainer = () => {

    const dispatch = useDispatch();
    
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const summaryDocList = useSelector(state => state.summaryDoc.summaryDocList);

    useEffect(() => {
        if(summaryDocList == false) {
            getSummaryDocList(); // init
        }
        
        return () => {
            console.log("CleanUp SummaryDocList")
        }
    }, [])

    function getSummaryDocList() {
        dispatch( setLoadingSummaryDocList(true) );

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "type" : "summaryDoc"
        });
        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/space/getSpaceList',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            console.log(response.data)
            if( response.data.code == 100){
                dispatch( setSummaryDocList(response.data.spaceList) )
                dispatch( setLoadingSummaryDocList(false) );
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch( setLoadingSummaryDocList(false) );
        });
    }

    return (
        <>
            <SummaryDocSidebar />
        </>
    )
}

export default SummaryDocSidebarContainer
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import {  setTranslateDocList } from "../../modules/translateDoc";
import { setLoadingDocSpaceList } from "../../modules/loading";
import TranslateDocSidebar from '../../components/apps/translateDoc/TranslateDocSidebar';
import { RootState } from "../../modules";

declare const API_URL: string;
declare const API_VERSION: string;

const DocSidebarContainer = () => {
    const dispatch = useDispatch();

    const translateDocList = useSelector((state: RootState) => state.translateDoc.translateDocList);
    const userToken = useSelector((state: RootState) => state.user.userToken);
    const orgCode = useSelector((state: RootState) => state.organization.orgCode);

    useEffect(() => {

        if(translateDocList == false) {
            getDocList(); // init
            console.log("DocSidebarLeft Init")
        }

        return() => {
            console.log('DocSidebarLeft Clean Up')
        }
    }, [])

    function getDocList() {
        dispatch( setLoadingDocSpaceList(true) );
        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "type" : "translateDoc"
        });
        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/space/getSpaceList',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            console.log(response.data)
            if( response.data.code == 100){

                // 날짜 순 정렬
                const docSpaceList = (response.data.spaceList).sort(function(prevItem, nextItem) {
                    var sortPrev = prevItem.created_at;
                    var sortNext = nextItem.created_at;
                    if (sortPrev > sortNext) {
                        return -1;
                    } else if (sortPrev > sortNext) {
                        return 1;
                    }
                    return 0;
                });

                dispatch( setTranslateDocList(docSpaceList) );
                dispatch( setLoadingDocSpaceList(false) );
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch( setLoadingDocSpaceList(false) );
        });
    }

    return (
        <>
            {
               <TranslateDocSidebar/>
            }     
        </>
    )
}

export default DocSidebarContainer
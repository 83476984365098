import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";

import { Row, Col } from 'react-bootstrap'
import { ThreeBounce } from 'better-react-spinkit'
import { BsDownload } from 'react-icons/bs'
import TranslateDocLoading from './TranslateDocLoading';

import { setTranslateDocList} from "../../../modules/translateDoc";
import { setLoadingDocDownload, setLoadingDocSpaceList } from "../../../modules/loading";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";

const TranslateDocMain = (props) => {

    const {
        sourceLangList, 
        targetLangList
    } = props

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const opts = useSelector(state => state.organization.opts);

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar);
    const loadingDocState = useSelector(state => state.loading.loadingDocState);
    const loadingDocDownload = useSelector(state => state.loading.loadingDocDownload);

    const translatedState = useSelector(state => state.translateDoc.translateStatus);
    const selectedDocInfo = useSelector(state => state.translateDoc.selectedDocInfo);
    
    const selectedDocTranslatedList = useSelector(state => state.translateDoc.selectedDocTranslatedList);

    function addLanguageCodeToFileName(fileName, languageCode) {
        // 파일 이름에서 확장자를 추출합니다.
        const fileExtension = fileName.split('.').pop();
        
        // 언어 코드를 추가한 새로운 파일 이름을 생성합니다.
        const newFileName = fileName.replace(`.${fileExtension}`, `-${languageCode}.${fileExtension}`);
        
        return newFileName; 
    }

    function getDownloadUrl(item) {
        // get doc download url

        console.log(item);

        if(item.state == true ) {

            immediateToast("info", {
                title: t("common.translateDoc"),
                message: t("translateDoc.download"),
                position: 'topCenter',
                timeout: 2000,
                zindex: 999999999
            });

            dispatch( setLoadingDocDownload(true) );
            console.log(item, selectedDocInfo);
            var fn = addLanguageCodeToFileName(selectedDocInfo.label, item.lang);
            var data = JSON.stringify({
                "orgCode": orgCode,
                "token": userToken,
                "uuid": selectedDocInfo.space_id,
                "lang" : item.lang,
                "fn" : fn
            });
    
            var config = {
              method: 'post',
              url:  API_URL + '/' + API_VERSION + '/translator/doc/getDownloadUrl',
              headers: { 
                'Content-Type': 'application/json'
              },
              data : data
            };
      
            axios(config)
            .then( (response) => {
                if( response.data.code == 100){
                    console.log(response.data)
                    const downloadUrl = response.data.data;
                    window.open(downloadUrl, '_parent'); // download doc
                    dispatch( setLoadingDocDownload(false) );

                } else if(response.data.code == 200 ) {
                    dispatch( setLoadingDocDownload(false) );
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch( setLoadingDocDownload(false) );
            });
        } else {

            immediateToast('error', {
                title: t("common.translateDoc"),
                message: t("translateDoc.process"),
                position: 'topCenter',
                timeout: 2000,
                zindex: 999999999,
            });
        }
    }

    return (

        <>
            <div id="doc-status-container" className="fr" style={ ( !uiSmallAiBox && uiHideSidebar ) ? { width: '100%', paddingLeft: '70px' } : {} }>
                {

                    selectedDocTranslatedList.length == 0 ? (
                        <TranslateDocLoading/>
                    ) : (
                        <>
                            <div className="status-title">
                                { selectedDocInfo.label }
                                {
                                    /*
                                    <div className="fr pl-3 pr-3">
                                        <GrFormRefresh className="cursor-pointer" onClick={() => getState()} />
                                    </div>
                                    */
                                }

                            </div> 
                            <div className="cr" />

                            {
                                uiSmallAiBox ? (
                                    <div className="doc-status-list">
                                        {
                                            selectedDocTranslatedList.length > 0 && (
                                                // translateRes
                                                (selectedDocTranslatedList).map((item, idx) => {
                                                    return (
                                                        <div className="mt-2 mb-2" key={ idx }>
                                                            <div className="status-wrapper">
                                                                <div className="status-box">
                                                                    <div>
                                                                        {
                                                                            item.state ? (
                                                                                <div className="fl success-doc">
                                                                                    { t("translateDoc.success") }
                                                                                </div> // state: true, translated: true - 변환 완료
                                                                            ) : (
                                                                                <div className="fl failure-doc">
                                                                                    { t("translateDoc.failure") }
                                                                                </div> // state: true, waiting - percentage가 존재하며 현재 변환 진행중
                                                                            )
                                                                        }
                                                                    
                                                                        {/* <Button variant="success" className="fr">다운로드</Button> */}
                                                                        <button className="download-btn">
                                                                            {
                                                                                (item.state) && (
                                                                                    <BsDownload type="button" size={ 20 } className="cursor-pointer" onClick={() => getDownloadUrl(item)} />
                                                                                )
                                                                            }
                                                                        </button>
                                                                        <div className="cr" />
                                                                    </div>

                                                                    <div className="title-header">
                                                                        { addLanguageCodeToFileName( selectedDocInfo.label, item.lang) }
                                                                    </div>

                                                                    <div className="lang-wrapper">
                                                                        <div className="lang-box">
                                                                            <div className="pt-1 pb-2">
                                                                                <span className="fl font-weight-bold">{ t("translateDoc.source") }</span>
                                                                                <span className="fr">
                                                                                    { sourceLangList[item.sourceLang] }
                                                                                </span>
                                                                                <div className="cr" />
                                                                            </div>
                                                                            

                                                                            <div className="pt-2 pb-1 border-top">
                                                                                <span className="fl font-weight-bold">{ t("translateDoc.target") }</span>
                                                                                <span className="fr">
                                                                                    { targetLangList[item.lang] }
                                                                                </span>
                                                                                <div className="cr" />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>    
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                ) : (
                                    <Row className="doc-status-list">
                                        {
                                            selectedDocTranslatedList.length > 0 && (
                                                // translateRes
                                                (selectedDocTranslatedList).map((item, idx) => {
                                                    return (
                                                        <Col className="mt-2 mb-2" sm={ 6 } md={ 4 } lg={ 3 } key={ idx }>
                                                            <div className="status-wrapper">
                                                                <div className="status-box">
                                                                    <div>
                                                                        {
                                                                            item.state ? (
                                                                                <div className="fl success-doc">
                                                                                    { t("translateDoc.success") }
                                                                                </div> // state: true, translated: true - 변환 완료
                                                                            ) : (
                                                                                <div className="fl failure-doc">
                                                                                    { t("translateDoc.failure") }
                                                                                </div> // state: true, waiting - percentage가 존재하며 현재 변환 진행중
                                                                            )
                                                                        }
                                                                    
                                                                        {/* <Button variant="success" className="fr">다운로드</Button> */}
                                                                        <button className="download-btn" type="button">
                                                                            {
                                                                                (item.state) && (
                                                                                    <BsDownload size={ 20 } className="cursor-pointer" onClick={() => getDownloadUrl(item)} />
                                                                                )
                                                                            }
                                                                        </button>
                                                                        <div className="cr" />
                                                                    </div>

                                                                    <div className="title-header">
                                                                        { addLanguageCodeToFileName( selectedDocInfo.label, item.lang) }
                                                                    </div>

                                                                    <div className="lang-wrapper">
                                                                        <div className="lang-box">
                                                                            <div className="pt-1 pb-2">
                                                                                <span className="fl font-weight-bold">{ t("translateDoc.source") }</span>
                                                                                <span className="fr">
                                                                                    { sourceLangList[item.sourceLang] }
                                                                                </span>
                                                                                <div className="cr" />
                                                                            </div>
                                                                            

                                                                            <div className="pt-2 pb-1 border-top">
                                                                                <span className="fl font-weight-bold">{ t("translateDoc.target") }</span>
                                                                                <span className="fr">
                                                                                    { targetLangList[item.lang] }
                                                                                </span>
                                                                                <div className="cr" />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>    
                                                        </Col>
                                                    )
                                                })
                                            )
                                        }
                                    </Row>
                                )
                            }
                        </>
                    )
                }
            </div>

            {
                loadingDocDownload && (
                    <div className="box-option">
                        <ThreeBounce color={ "#808080" } />
                    </div>
                )
            }
        </>
    )
}

export default TranslateDocMain
import React from 'react';
import { ThreeBounce } from 'better-react-spinkit'
import { useSelector } from 'react-redux';

const TargetBox = () => {
    
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox)
    const loadingTranslateText = useSelector(state => state.loading.loadingTranslateText);
    const translateTargetText = useSelector(state => state.translateText.translateTargetText)

    return (
        <>
        {
            uiSmallAiBox ? (
                <div className='box-input box-input-to border-top-0 border-right-0'>
                    <div className='position-relative h-100'>
                        <textarea
                            disabled
                            className="form-control translate-box target-box border-top-0 border-left-0"
                            // placeholder="번역"
                            value={ translateTargetText }
                        ></textarea>
                        {
                            loadingTranslateText && (
                                <div className='box-option'>
                                    <ThreeBounce color={ "#808080" }/>
                                </div>
                            )
                        }
                        <div className='cr' />
                    </div>
                </div>
            ) : (
            <div className='translate-text-box target-box position-relative'>
                <textarea
                    disabled
                    className="form-control translate-box target-box border-top-0 border-left-0"
                    // placeholder="번역"
                    value={ translateTargetText }
                ></textarea>
                {
                    loadingTranslateText && (
                        <div className='box-option'>
                            <ThreeBounce color={ "#808080" }/>
                        </div>
                    )
                }
                <div className='cr'></div>
            </div>
            )
        }
        
        </>
    )
}

export default TargetBox
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Draggable from 'react-draggable'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { AiOutlineCopy, AiOutlineClose } from 'react-icons/ai'
import { FoldingCube } from 'better-react-spinkit'
import Select from 'react-select';
import { immediateToast } from "izitoast-react";
import { setText, setTranslateSelection } from "../../modules/tooltip";
import { useTranslation } from "react-i18next";

const ToolTipModal = (props) => {
    
    const dispatch = useDispatch();

    const {
        setTranslateText,
    } = props

    const loadingTooltipChange = useSelector(state => state.loading.loadingTooltipChange);
    const opts = useSelector(state => state.organization.opts);
    const translateSelection = useSelector(state => state.tooltip.translateSelection)
    const tooltipOptions = useSelector(state => state.tooltip.options)
    const tooltipSourceLang = useSelector(state => state.tooltip.tooltipSourceLang)
    const tooltipSourceText = useSelector(state => state.tooltip.sourceText)
    const tooltipTargetText = useSelector(state => state.tooltip.targetText)
    const tooltipModalPosition = useSelector(state => state.tooltip.modalPosition);
    const userOpts = useSelector(state => state.setting.userOpts)

    const { t, i18n } = useTranslation();

    /*
    const defaultValue = () => {
        const defaultLang = document.querySelector('html')?.getAttribute('lang');

        if(defaultLang == 'ko') {

            if(opts['SERVICE_TRANSLATOR'] == "DL") {
                return ({
                    label: t('lang.en-gb'),
                    value: 'en-gb'
                })

            } else {
                return ({
                    label: t('lang.en'),
                    value: 'en'
                })
            }
            
        } else {
            return ({
                label: t('lang.ko'),
                value: 'ko'
            })
        }
    }
    */

    const langValue = () => {
        if(typeof userOpts['BASE_MY_LANG'] == 'undefined') {
            switch(navigator.language) {
                case 'ko':
                case 'ko-KP':
                case 'ko-KR':
                    return 'ko'
                case 'en-US':
                    return 'en-us'
                case 'en-GB':
                    return 'en-gb'
                case 'ja':
                case 'ja-JP':
                    return 'ja'
                case 'zh':
                case 'zh-Hans-CN':
                    return 'zh-cn'
                case 'es':
                    return 'es'
                case 'vi':
                case 'vi-VN':
                    return 'vi'
                default:
                    return 'en-gb'
            }
        }
    }

    const defaultValue = {
        value: typeof userOpts['BASE_MY_LANG'] == 'undefined' ? langValue() : userOpts['BASE_MY_LANG'],
        label: typeof userOpts['BASE_MY_LANG'] == 'undefined' ? t('lang.' + langValue()) : t('lang.' + userOpts['BASE_MY_LANG'])
    };

    console.log(defaultValue, ' my default value')

    const changeTargetLang = (e) => {
        const targetValue = e.value;
        console.log(e)

        setTranslateText(tooltipSourceText, tooltipSourceLang, targetValue, 'change')
    }

    const copyTranslatedText = () => {
        window.navigator.clipboard.writeText(tooltipTargetText) 

        immediateToast("success", {
            message: t('translateText.copy_text'),
            position: 'topCenter',
            timeout: 2000,
            zindex: 999999999
        });
    }

    const closeTooltipModal = () => {
        // setTranslateSelection(false)
        // setTextData("");

        dispatch(setTranslateSelection(false))
        dispatch(setText(''))
    }

    return (
        translateSelection ? (


            <Draggable
                axis="both"
                handle=".tooltip-drag-handle"
                defaultPosition={{ x: 0, y: 0 }}
                // position={null}
                grid={[1, 1]}
                scale={1}
            >
            <div className="tooltip-container" style={{ top: tooltipModalPosition.x, left: tooltipModalPosition.y }}>
                <div className="tooltip-wrapper">
        
                    <div className="tooltip-body">
                        <div className="tooltip-drag-handle">
                            <RxDragHandleDots2 size={ 16 } color="#A4A3A3" />
                        </div>
                        <div className="tooltip-header">
                            <div className="header-language">
                                <span className="lang-selector-label">
                                    { t('translateText.select_lang') }:&nbsp;
                                </span>
                                {/* <select>
                                    <option>1</option>
                                    <option>2</option>
                                </select> */}
                                <Select
                                    className="w-100"
                                    defaultMenuIsOpen={ false }
                                    options={ tooltipOptions }
                                    onChange={ (e) => changeTargetLang(e) }
                                    
                                    placeholder={ t('translateText.select_placeholder') }

                                    defaultValue={ defaultValue }
                                    
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: '100%'
                                        }),
                                    }}
                                />
                            </div>
                            <div className="header-close cursor-pointer" onClick={ closeTooltipModal }>
                                <AiOutlineClose type="button" color="#FFF" />
                            </div>
                        </div>
                        <div className="tooltip-content">
                            <div className="tooltip-text-box">
                                {
                                    loadingTooltipChange ? (
                                        <FoldingCube
                                        className='d-flex justify-content-center align-items-center'
                                        size={ 20 }
                                        color={ '#C0C0C0' }
                                    />
                                    ) : (
                                        <>
                                            <div className="tooltip-source-text-wrapper">
                                                <div className="tooltip-source-text">
                                                {
                                                    tooltipSourceText != "" && (
                                                        `[${ t('translateText.source') }] - ` + tooltipSourceText
                                                    )
                                                }
                                                </div>
                                            </div>
                                            <div className="tooltip-target-text-wrapper">
                                                <div className="tooltip-target-text">
                                                    {
                                                        tooltipTargetText != "" && (
                                                            `[${ t('translateText.target') }] - ` + tooltipTargetText
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="tooltip-options">
                                                <AiOutlineCopy 
                                                    size={ 20 }
                                                    cursor={ 'pointer' }
                                                    onClick={ copyTranslatedText }
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
            </Draggable>
        ) : (
            <></>
        )
        
    )
}

export default ToolTipModal
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap'

const MainTopBanner = () => {

    const uiShowAiBox = useSelector(state => state.ui.uiShowAiBox);
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const orgCode = useSelector(state => state.organization.orgCode);

    const { t, i18n } = useTranslation();

    const RenderSmallView = () => {
        return (
            <>
                {
                    (orgCode == "hallym_dev" || orgCode == "csms39_mdl_dev" || orgCode == "hallym") && (
                        <div className='top-banner-text'>
                            <div className='top-banner-title'>{ t("banner.title1") }</div>
                            <div>{ t("banner.title2") }</div>
                            <div>{ t("banner.title3") }</div>
                        </div>
                    )
                }
            </>
        )
    }

    const RenderModalView = () => {
        return (
            <>
                {
                    (orgCode == "hallym_dev" || orgCode == "csms39_mdl_dev" || orgCode == "hallym") && (
                        <Row className='top-banner-text'>
                            <Col className='top-banner-title' xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 }>{ t("banner.title1") }</Col>
                            <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 } style={{ padding: '0px 5px' }}>{ t("banner.title2") }</Col>
                            <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 } style={{ padding: '0px 5px' }}>{ t("banner.title3") }</Col>
                        </Row>
                    )
                }
            </>
        )
    }

    return (
        <>
            {
                uiShowAiBox && (
                    uiSmallAiBox ? (
                        <RenderSmallView />
                    ) : (
                        <RenderModalView />
                    )
                )
            }
        </>
    )
}

export default MainTopBanner
import React from 'react';
import { useSelector } from 'react-redux';

import ChatInputGroup from '../apps/aiChat/ChatInputGroup';
import ChatSidebarContainer from '../../containers/aiChat/ChatSidebarContainer';
import ChatSpaceContainer from '../../containers/aiChat/ChatSpaceContainer';

import '../../../resources/assets/styles/sass/UiChat.scss';

const UiChat = () => { 

    return (
        <>
            <ChatSidebarContainer />
            <ChatSpaceContainer />
            <div className='cr'></div>
        </>
    );

}


export default UiChat;
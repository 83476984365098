import React, { useEffect, MouseEventHandler} from 'react';
import { Col, Container } from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { TbPrompt } from "react-icons/tb";

import { setSelectedPrompt, setAiChatPromptList, setAiChatMessage, setAiChatPromptPage } from "../../../modules/aiChat";

const PromptManager = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const aiChatPromptList = useSelector(state => state.aiChat.aiChatPromptList);

    function setUserPrompt(prompt){
        console.log(prompt)
        dispatch(setSelectedPrompt(prompt));
    }

    function getPromptList(){

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : uToken,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: API_URL + '/' + API_VERSION + '/apps/aiChat/getPromptList',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };

        axios.request(config)
        .then((response) => {
            console.log(response.data)
            if(response.data.code == 100){
                dispatch(setAiChatPromptList(response.data.promptList))
                
            }
        })
        .catch((error) => {
        console.log(error);
        });

    }

    useEffect(() => {
        if(aiChatPromptList == false) {
            getPromptList();
        }
    }, [])

    const clickPromptBox: MouseEventHandler<HTMLDivElement> = (e) => {
        const promptText = e.target.textContent;
        dispatch( setAiChatMessage(promptText) );
    }

    const showPromptPage = () => {
        dispatch(setAiChatPromptPage(true))
    }

    return (
        <>
            {
                uiSmallAiBox ? (
                    <Container className="prompt-container">
                        <div className='pb-2 prompt-wrapper'>
                            <Col className='prompt-title'>
                                { t("aichat.welcome") }
                            </Col>

                            <Col className='pt-2 pb-2 prompt-box' onClick={clickPromptBox}>
                                { t("aichat.prompt_com") }
                            </Col>

                            <Col className='pt-2 pb-2 prompt-box' onClick={clickPromptBox}>
                                { t("aichat.prompt_esg") }
                            </Col>

                            <Col className='pt-2 pb-2 prompt-box' onClick={clickPromptBox}>
                                { t("aichat.prompt_ai") }
                            </Col>
                        </div>
                        {
                            IS_DEV && (
                                <button type='button' className='prompt-icon' onClick={() => showPromptPage()}>
                                    <TbPrompt size={ 20 } color='#8A8A8A' />
                                </button>
                            )
                        }
                        
                    </Container>
                ) : (
                    <Container className='prompt-container'>
                        <div className='pb-5 prompt-wrapper'>
                            <Col className='prompt-title' >
                                { t("aichat.welcome") }
                            </Col>

                            <Col className='pt-2 pb-2 prompt-box' onClick={clickPromptBox}>
                                { t("aichat.prompt_com") }
                            </Col>

                            <Col className='pt-2 pb-2 prompt-box' onClick={clickPromptBox}>
                                { t("aichat.prompt_esg") }
                            </Col>

                            <Col className='pt-2 pb-2 prompt-box' onClick={clickPromptBox}>
                                { t("aichat.prompt_ai") }
                            </Col>
                        </div>
                        {
                            IS_DEV && (
                                <button type='button' className='prompt-icon' onClick={() => showPromptPage()}>
                                    <TbPrompt size={ 20 } color='#8A8A8A' />
                                </button>
                            )
                        }
                        
                    </Container>
                )
            }
        </>
    )
}

export default PromptManager
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import Switch from 'react-switch'
import axios, { AxiosResponse } from "axios";
import { Row, Col } from 'react-bootstrap'

import { setOptTranslateTextSelection } from "../../../modules/setting";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";

const TranslateTextSetting = () => {

    const dispatch = useDispatch();

    type APIResType = {
        data: {
            code: number;
            message: string;
            setOptData: number
        }
    }

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const userOpts = useSelector(state => state.setting.userOpts)

    const [floatingChecked, setFloatingChecked] = useState(Boolean(parseInt(userOpts.TRANSLATE_TEXT_SELECTION)));

    const { t, i18n } = useTranslation();

    function setSettingOpts(menuType: string, optionData: number) {
        // add API
        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "opt" : {
                "key" : menuType,
                "value" : optionData
            }
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/common/setOpt',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios<AxiosResponse, APIResType>(config)
        .then( (response) => {
            console.log(response)
            if(response.data.code == 100) {
                console.log(response.data.message)
                dispatch(setOptTranslateTextSelection({
                    [menuType]: optionData
                }))
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    function handleOption(checked: boolean, settingType: string) {

        const optionData: number = checked ? 1 : 0

        // 체크, 체크 해제 api
        switch(settingType) {
            case "floating-option":
                setFloatingChecked(checked)
                setSettingOpts("TRANSLATE_TEXT_SELECTION", optionData) // API
                break;
            default:
                break;
        }
    }

    const RenderSmallView = () => {
        return (
            <Row className="content-center">
                <Col sm={ 7 } md={ 7 } xl={ 7 } xs={ 7 } lg={ 7 }>
                    <div className="setting-title">{ t('setting.floating_label') }</div>
                    <div className="setting-info">{ t('setting.floating_content') }</div>
                </Col>
                <Col sm={ 5 } md={ 5 } xl={ 5 } xs={ 5 } lg={ 5 } className="text-center">
                    <Switch onChange={(checked) => { handleOption(checked, "floating-option") }} checked={ floatingChecked } />
                </Col>
            </ Row>
        )
    }

    const RenderModalView = () => {
        return (
            <Row className="content-center">
                <Col sm={ 4 } md={ 4 } xl={ 4 } xs={ 4 } lg={ 4 }>
                    <div className="setting-title">{ t('setting.floating_label') }</div>
                    <div className="setting-info">{ t('setting.floating_content') }</div>
                </Col>
                <Col sm={ 4 } md={ 4 } xl={ 4 } xs={ 4 } lg={ 4 } className="text-center">
                    <Switch onChange={(checked) => { handleOption(checked, "floating-option") }} checked={ floatingChecked } />
                </Col>
                <Col sm={ 4 } md={ 4 } xl={ 4 } xs={ 4 } lg={ 4 } >
                </Col>
            </Row>
        )
    }

    return (
        <div className="setting-wrapper">
            <div className={ uiSmallAiBox? 'setting-small-content' : 'setting-modal-content'}>
                {
                    uiSmallAiBox ? (
                        <RenderSmallView />
                    ) : (
                        <RenderModalView />
                    )
                }
            </div>
        </div>
    )
}

export default TranslateTextSetting
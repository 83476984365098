//import axios from 'axios';

//Actions
const SET_ORG_CODE = "organization/SET_ORG_CODE";
const SET_OPTS = "organization/SET_OPTS";

type OptsType = {
  OPT_CSMSAI_CHAT: string
  OPT_CSMSAI_TRANSLATE_TEXT: string
  OPT_CSMSAI_TRANSLATE_DOCUMENT: string
  OPT_CSMSAI_SUMMARY_DOCUMENT: string
  SERVICE_TRANSLATOR: string
}

// 액션 생성 함수
export const setOrgCode = orgCode => ({ type: SET_ORG_CODE, orgCode });
export const setOpts = opts => ({ type: SET_OPTS, opts });

// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
  orgCode : false,
  opts: [],
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case SET_ORG_CODE:
      return {
        ...state,
        orgCode : action.orgCode
      }

    case SET_OPTS:
      return {
        ...state,
        opts: action.opts
      }

    default:
        return state;
  }
} 
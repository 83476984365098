import React from 'react';
import { useSelector } from 'react-redux';
import { RiArrowDownSLine } from 'react-icons/ri'
import { useTranslation } from "react-i18next";

const SourceSelector = (props) => {

    const {
        openSelectorBox,
        selectorType
    } = props

    const { t, i18n } = useTranslation();

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const translateSourceLang = useSelector(state => state.translateText.translateSourceLang)
    const translateTargetLang = useSelector(state => state.translateText.translateTargetLang)
    const translateAuto = useSelector(state => state.translateText.translateAuto)

    return (
        <>
            <div id={ selectorType == "source" ? "btn-aitutor-select-source-lang" : "btn-aitutor-select-target-lang" } className={ uiSmallAiBox ? 'box-select-title' : 'language-title' } onClick={ openSelectorBox }>

                {
                    selectorType == "source" ? (
                        <>
                            {
                                ( translateSourceLang.value == "auto" && translateAuto.value != "" ) ? (
                                    <>
                                        { 'lang.' + translateAuto.value } - { t('translateText.auth_lang') }
                                    </>
                                ) : (
                                    <>
                                        { t('lang.' + translateSourceLang.value) }
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <>
                            {
                                translateTargetLang.label != "" ? (
                                    translateTargetLang.label
                                ) : (
                                    <>{ t('translateDoc.select_lang') }</>
                                )
                            }
                        </>
                    )
                }
                <RiArrowDownSLine
                    id={ selectorType == "source" ? "icon-aitutor-select-source-lang" : "icon-aitutor-select-target-lang" }
                    size={ 20 }
                />
            </div>
        </>
    )
}

export default SourceSelector;

//Actions
const SET_SUMMARY_DOC_LIST = "summaryDoc/SET_SUMMARY_DOC_LIST";
const SET_SUMMARY_DOC_STATUS = "summaryDoc/SET_SUMMARY_DOC_STATUS";

const SET_SELECTED_SUMMARY_DOC = "summaryDoc/SET_SELECTED_SUMMARY_DOC";
const SET_SUMMARY_FILE_INFO = "summaryDoc/SET_SUMMARY_FILE_INFO";

const SET_SUMMARY_DOC_SELECTED_SPACE = "summaryDoc/SET_SUMMARY_DOC_SELECTED_SPACE";
const SET_SUMMARY_DOC_SELECTED_HISTORY = "summaryDoc/SET_SUMMARY_DOC_SELECTED_HISTORY";

const SET_SUMMARY_DOC_PROGRESS = "summaryDoc/SET_SUMMARY_DOC_PROGRESS";

// 액션 생성 함수
export const setSummaryDocList = summaryDocList => ({ type: SET_SUMMARY_DOC_LIST, summaryDocList });
export const setSummaryDocStatus = summaryDocStatus => ({ type: SET_SUMMARY_DOC_STATUS, summaryDocStatus });
export const setSelectedSummaryDoc = selectedSummaryDoc => ({ type: SET_SELECTED_SUMMARY_DOC, selectedSummaryDoc });
export const setSummaryFileInfo = summaryFileInfo => ({ type: SET_SUMMARY_FILE_INFO, summaryFileInfo });

export const setSummaryDocSelectedSpace = summaryDocSelectedSpace => ({ type: SET_SUMMARY_DOC_SELECTED_SPACE, summaryDocSelectedSpace });
export const setSummaryDocSelectedHistory = summaryDocSelectedSpaceChatHistory => ({ type: SET_SUMMARY_DOC_SELECTED_HISTORY, summaryDocSelectedSpaceChatHistory });

export const setSummaryDocProgress = summaryDocProgress => ({ type: SET_SUMMARY_DOC_PROGRESS, summaryDocProgress });

const initialState = {
    summaryDocList : false,
    summaryDocStatus: "upload",
    selectedSummaryDoc: {},
    summaryFileInfo: {},
    summaryDocSelectedSpace: false,
    summaryDocSelectedSpaceChatHistory:[],
    summaryDocProgress: false,
};

export default function summaryDoc(state = initialState, action) {
    switch (action.type) {   
      case SET_SUMMARY_DOC_LIST:
        return {
            ...state,
            summaryDocList : action.summaryDocList
        };

      case SET_SUMMARY_DOC_STATUS:
        return {
            ...state,
            summaryDocStatus : action.summaryDocStatus
        };

      case SET_SELECTED_SUMMARY_DOC:
        return {
            ...state,
            selectedSummaryDoc : action.selectedSummaryDoc
        };
      case SET_SUMMARY_FILE_INFO:
        return {
            ...state,
            summaryFileInfo : action.summaryFileInfo
        };
      
      case SET_SUMMARY_DOC_SELECTED_SPACE:
        return {
            ...state,
            summaryDocSelectedSpace : action.summaryDocSelectedSpace
        };
      case SET_SUMMARY_DOC_SELECTED_HISTORY:
        return {
            ...state,
            summaryDocSelectedSpaceChatHistory : action.summaryDocSelectedSpaceChatHistory
        };

      case SET_SUMMARY_DOC_PROGRESS:
        return {
            ...state,
            summaryDocProgress : action.summaryDocProgress
        };

      default:
        return state;
    }
  }
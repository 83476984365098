import React from 'react';
import { useDispatch } from 'react-redux';


import { setModalStatus, setTranslateTextOpenSourceLangList, setTranslateTextOpenTargetLangList, setTranslateTextSourceSearchText, setTranslateTextTargetSearchText } from '../../modules/translateText';
import SourceSelector from '../../components/apps/translateText/SourceSelector';


const SourceSelectorContainer = () => {

    const dispatch = useDispatch();

    function openSelectorBox() {
        // dispatch( setModalStatus("source") );

        dispatch(setTranslateTextOpenTargetLangList(false));
        dispatch(setTranslateTextOpenSourceLangList(true));
    }

    return (
        <SourceSelector
            openSelectorBox={ openSelectorBox }
            selectorType={ "source" }
        />
    )
}

export default SourceSelectorContainer
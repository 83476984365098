import React, { useEffect } from 'react';

import '../../../resources/assets/styles/sass/UiTranslateDocSmall.scss';
import '../../../resources/assets/styles/sass/UiTranslateDoc.scss';
import DocSidebarContainer from '../../containers/translateDoc/DocSidebarContainer';
import DocSpaceContainer from '../../containers/translateDoc/DocSpaceContainer';

const UiTranslateDoc = () => {

    useEffect(() => {
        return () => {
            console.log('clean up')
        }
    }, [])

    return (
        <>
            {/* <DocSidebarLeft /> */}
            <DocSidebarContainer /> {/* 측면 사이드바 */}
            {/* <DocSpaceContainer />  */}
            <DocSpaceContainer />



            

            {/* 업로듸, 선택, 번역 메인 컨테이너 */}

            {/* ModalTranslateDocStatus */}
        </>
    );

}


export default UiTranslateDoc;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { Cookies } from 'react-cookie';
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

const MenuGuideBubble = (props) => {

    const {getEventState} = props

    const [runGuide, setRunGuide] = useState(true);
    const [steps, setSteps] = useState([]);

    const uiModalAiBox = useSelector(state => state.ui.uiModalAiBox);
    const opts = useSelector(state => state.organization.opts);

    const { t, i18n } = useTranslation();

    const TranslateTextGuide = () => {

        if(uiModalAiBox) {
            return (
                <>
                    <div>
                        ･ { t("common.translate-text-content") }
                    </div>
                    <div>
                        ･ { t("common.translate-text-file") }
                    </div>
                </>
            )
        } else {
            return (
                <div>
                    ･ { t("common.translate-text-file") }
                </div>
            )
        }
        
    }

    useEffect(() => {
        if(getEventState) {
            getOrgOpts();
        } else {
            setGuideCookie()
            setRunGuide(false)
        }

        return () => {
            console.log('clean up Guide')
        }
    }, [])

    function getOrgOpts() {
        const guideList = [];

        // Start Prize Guide Steps
        if( typeof opts['OPT_CSMSAI_CHAT'] != "undefined" && opts['OPT_CSMSAI_CHAT'] )  {
            guideList.push({
                // target: '.ai-event-box',
                
                target: '.ai-event-wrapper',
                // content: '설문조사에 참여하면 특별한 헤턕을 드려요!',
                content: (
                    <>
                        {/* 
                        <div>{ t("common.guide-event") } </div>
                        <div><strong>특별한 혜택</strong>을 드려요!</div>
                        <div><strong>{ t("common.guide-prize") }</strong></div> */}
                        
                        <Trans 
                            i18nKey="common.guide-event"
                            components={{
                                event: <div></div>,
                                wrapper: <div></div>,
                                prize: <strong></strong>,
                                text: <span></span>
                            }}
                        />
                    </>
                ),
                placement: 'top-start',
                showSkipButton: false,
                disableBeacon: true,
                
            });
        }
        // End Prize Guide Steps

        /*
        // Start Menu Guide Steps
        if( typeof opts['OPT_CSMSAI_CHAT'] != "undefined" && opts['OPT_CSMSAI_CHAT'] )  {
            guideList.push({
                target: '.chat-guide',
                content: t("common.chat-guide"),
                placement: 'top',
                showSkipButton: false,
                disableBeacon: true,
            });
        }

        if(typeof opts['OPT_CSMSAI_TRANSLATE_TEXT'] != "undefined" && opts['OPT_CSMSAI_TRANSLATE_TEXT']) {
            guideList.push({
                target: '.translate-text-guide',
                title:  t("common.translate-text-guide"),
                content: <TranslateTextGuide />,
                placement: 'top',
                showSkipButton: false,
                disableBeacon: true,
            })
        }

        if(typeof opts['OPT_CSMSAI_TRANSLATE_DOCUMENT'] != "undefined" && opts['OPT_CSMSAI_TRANSLATE_DOCUMENT']) {
            guideList.push({
                target: '.translate-document-guide',
                content: t("common.translate-doc-guide"),
                placement: 'top',
                showSkipButton: false,
                disableBeacon: true,
            })
        }

        if(typeof opts['OPT_CSMSAI_SUMMARY_DOCUMENT'] != "undefined" && opts['OPT_CSMSAI_SUMMARY_DOCUMENT']) {
            guideList.push({
                target: '.summary-document-guide',
                content: t("common.summary-doc-guide"),
                placement: uiModalAiBox ? 'right' : 'top',
                showSkipButton: false,
                disableBeacon: true,
            })
        }
        // End Menu Guide Steps
        */

        setSteps(guideList);
    }

    function setGuideCookie() {
        const cookies = new Cookies();

        cookies.set('aichat-event-guide', true, {
            path: '/',
            expires: new Date(new Date().getTime()+1000*60*60*24*365)
        });
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        console.log(action, index, status, type);

        if(action == 'skip' && status == 'skipped' && type == 'step:after') {
            setGuideCookie()
            setRunGuide(false);
        } else if(action == 'close' && status == 'running' && type == 'step:after') {
            setGuideCookie()
            setRunGuide(false);
        } else if(action == 'next' && status == 'finished' && type == 'step:after') {
            setGuideCookie()
            setRunGuide(false);
        } else if(action == 'close' && status == 'finished' && type == 'tour:end') {
            setGuideCookie()
            setRunGuide(false);
        }
    }

    return (
        <>
            {/* Start Menu Guide */}
                {/* <Joyride
                    run={ runGuide }
                    steps={ steps }
                    disableOverlay={ false }
                    floaterProps={{
                        disableAnimation: true,
                    }}
                    spotlightClicks={ false }
                    disableCloseOnEsc={ false }
                    disableScrolling={ true }

                    hideBackButton={ false }
                    hideCloseButton={ true }
                    showProgress={ true }
                    
                    callback={(data => handleJoyrideCallback(data))}

                    continuous={  true }
                    spotlightPadding={ 0 }

                    disableOverlayClose={ false }

                    locale={{
                        skip: '다시보지않기',
                        next: '다음',
                        back: '이전',
                        last: '닫기',
                    }}

                    styles={{
                        tooltip: {
                            borderRadius: '12px',
                            width: '300px'
                        },
                        options: {
                            primaryColor: '#FFFFFF',
                            textColor: '#000000',
                            zIndex: 1070,
                        },
                        buttonNext: {
                            color: '#3236FF',
                            fontWeight: 500,
                            padding: '10px',
                            // textDecoration: 'underline'
                        },
                        buttonBack: {
                            color: '#000000',
                            left: '0px',
                            position: 'absolute',
                            margin: '25px'
                        },
                        buttonSkip: {
                            // textDecoration: 'underline',
                        },
                        tooltipTitle: {
                            textAlign: 'left',
                            paddingLeft: '10px',
                        },
                        tooltipContent: {
                            textAlign: 'left'
                        },
                        spotlight: {
                            position: 'absolute'
                        }
                    }}
                /> */}
            {/* End Menu Guide */}


            {/* Start Prize Guide */}
            <Joyride
                
                run={ runGuide }
                steps={ steps }
                disableOverlay={ false }
                floaterProps={{
                    disableAnimation: true,
                    id: "joyride-test-options"
                }}
                disableCloseOnEsc={ false }
                disableOverlayClose={ true }
                disableScrolling={ true }
                showSkipButton={ true }

                hideCloseButton={ false }
                hideBackButton={ true }

                continuous={ false }
                spotlightPadding={ 0 }

                callback={(data) => handleJoyrideCallback(data)}


                disableScrollParentFix={ true }
                

                styles={{
                    spotlight: {
                        // backgroundColor: 'none',
                        //display: 'none'
                        padding: '0px',
                        // height: '98px',
                        marginTop: '19px',
                        marginLeft: '5px',
                        borderRadius: '10px'
                    },
                    tooltip: {
                        borderRadius: '12px',
                        padding: '5px 10px',
                        width: '250px',
                        left: '20px',
                        color: '#3A3A3A',
                    },
                    
                    
                    options: {
                        primaryColor: '#FFFFFF',
                        textColor: '#FFFFFF',
                        // backgroundColor: '#3236FF',
                        backgroundColor: '#FFB800',
                        arrowColor: '#FFB800',
                        zIndex: 1070,
                        
                    },
                    beaconOuter: {
                        borderRadius: '30px'
                    },
                    buttonNext: {
                        color: '#3236FF',
                        fontWeight: 500,
                        padding: '10px',
                        display: 'none'
                    },
                    buttonSkip: {
                        // textDecoration: 'underline'
                    },
                    tooltipTitle: {
                        textAlign: 'left',
                        paddingLeft: '10px',
                    },
                    tooltipContent: {
                        textAlign: 'left',
                        // fontWeight: 'bold',
                        // width: '166px',
                        padding: '10px'
                    },
                    buttonClose: {
                        fontWeight: 'bold',
                        color: '#3A3A3A'
                    }
                }}
                
            />
            {/* End Prize Guide */}
            
        </>

    )
}

export default MenuGuideBubble
import React from "react";

import WritingSidebarContainer from "../../containers/writingTutor/WritingSidebarContainer";
import WritingSpaceContainer from "../../containers/writingTutor/WritingSpaceContainer";
import '../../../resources/assets/styles/sass/UiWritingTutor.scss';

const UiWritingTutor = () => {
    return (
        <>
            <WritingSidebarContainer />
            <WritingSpaceContainer />
        </>
    )
}

export default UiWritingTutor
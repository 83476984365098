
const SET_UI_SHOW_AI_BOX = "ui/SET_UI_SHOW_AI_BOX";
const SET_UI_SMALL_AI_BOX = "ui/SET_UI_SMALL_AI_BOX";
const SET_UI_MODAL_AI_BOX = "ui/SET_UI_MODAL_AI_BOX";
const SET_UI_HIDE_CHAT_SIDEBAR = "ui/SET_UI_HIDE_CHAT_SIDEBAR";
const SET_UI_HIDE_SIDEBAR = "ui/SET_UI_HIDE_SIDEBAR";

const SET_UI_STATE_BLOCK = "ui/SET_UI_STATE_BLOCK";
const SET_UI_STATE_LEFT_SIDEBAR = "ui/SET_UI_STATE_LEFT_SIDEBAR";

export const setUiShowAiBox = uiShowAiBox => ({ type: SET_UI_SHOW_AI_BOX, uiShowAiBox })
export const setUiSmallAiBox = uiSmallAiBox => ({ type: SET_UI_SMALL_AI_BOX, uiSmallAiBox });
export const setUiModalAiBox = uiModalAiBox => ({ type: SET_UI_MODAL_AI_BOX, uiModalAiBox });
export const setUiHideSidebar = uiHideSidebar => ({ type: SET_UI_HIDE_SIDEBAR, uiHideSidebar });
export const setUiHideChatSidebar = uiHideChatSidebar => ({ type: SET_UI_HIDE_CHAT_SIDEBAR, uiHideChatSidebar });

export const setUiStateBlock = uiStateBlock => ({ type: SET_UI_STATE_BLOCK, uiStateBlock });
export const setUiStateLeftSidebar = uiStateLeftSidebar => ({ type: SET_UI_STATE_LEFT_SIDEBAR, uiStateLeftSidebar });

const initialState = {
    uiShowAiBox: false,
    uiSmallAiBox : false, // ai chat init: true - small
    uiModalAiBox : true, // ai chat init: true - modal
    uiHideSidebar: false,
    uiHideChatSidebar: false,

    uiStateBlock: false,
    uiStateLeftSidebar: false,
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case SET_UI_SHOW_AI_BOX:
            return {
                ...state,
                uiShowAiBox: action.uiShowAiBox
            };

        case SET_UI_SMALL_AI_BOX:
            return {
                ...state,
                uiSmallAiBox : action.uiSmallAiBox
            };
        
        case SET_UI_MODAL_AI_BOX:
            return {
                ...state,
                uiModalAiBox : action.uiModalAiBox
            };

        case SET_UI_HIDE_SIDEBAR:
            return {
                ...state,
                uiHideSidebar: action.uiHideSidebar
            }

        case SET_UI_HIDE_CHAT_SIDEBAR:
            return {
                ...state,
                uiHideChatSidebar: action.uiHideChatSidebar
            }

        case SET_UI_STATE_BLOCK:
            return {
                ...state,
                uiStateBlock: action.uiStateBlock
            }

        case SET_UI_STATE_LEFT_SIDEBAR:
            return {
                ...state,
                uiStateLeftSidebar: action.uiStateLeftSidebar
            }
            
      default:
        return state;
    }
  }
  
import React from 'react';
import { useDispatch } from 'react-redux';
import { setModalStatus, setTranslateTextOpenSourceLangList, setTranslateTextOpenTargetLangList, setTranslateTextUpdateText } from '../../modules/translateText';
import SourceSelector from '../../components/apps/translateText/SourceSelector';

const TargetSelectorContainer = () => {

    const dispatch = useDispatch();

    function openSelectorBox() {
        // dispatch(setModalStatus("target"));
        dispatch(setTranslateTextOpenSourceLangList(false));
        dispatch(setTranslateTextOpenTargetLangList(true));
        dispatch( setTranslateTextUpdateText(true) );
    }

    return (
        <SourceSelector
            openSelectorBox={ openSelectorBox }
            selectorType={ "target" }
        />
    )
}

export default TargetSelectorContainer
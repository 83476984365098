import React from "react";

import SpeakingSidebarContainer from "../../containers/speakingTutor/SpeakingSidebarContainer";
import SpeakingSpaceContainer from "../../containers/speakingTutor/SpeakingSpaceContainer";
import '../../../resources/assets/styles/sass/UiSpeakingTutor.scss';

const UiSpeakingTutor = () => {
    return (
        <>
            <SpeakingSidebarContainer />
            <SpeakingSpaceContainer />
        </>
    )
}

export default UiSpeakingTutor
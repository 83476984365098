import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { setAiChatSpaceList } from "../../modules/aiChat";
import { setLoadingSpaceList } from "../../modules/loading";
import ChatSidebar from "../../components/apps/aiChat/ChatSidebar";
import { RootState } from "../../modules";

declare const API_URL: string;
declare const API_VERSION: string;

const ChatSidebarContainer = () => {

    const dispatch = useDispatch();

    const userToken = useSelector((state: RootState) => state.user.userToken);
    const orgCode = useSelector((state: RootState) => state.organization.orgCode);
    const aiChatSpaceList = useSelector((state: RootState) => state.aiChat.aiChatSpaceList);

    /********** Update ************/
    useEffect(() => {
        if(aiChatSpaceList == false) {
            getSpaceList();
        }
    }, [])

    function getSpaceList(){
        dispatch(setLoadingSpaceList(true)); // getSpaceList Loading Start

        setTimeout(() => {
            var data = JSON.stringify({
                "orgCode" : orgCode,
                "uToken" : userToken,
                "type" : "aiChat"
            });
            var config = {
              method: 'post',
              url:  API_URL + '/' + API_VERSION + '/space/getSpaceList',
              headers: { 
                'Content-Type': 'application/json'
              },
              data : data
            };
      
            axios(config)
            .then( (response) => {
                console.log(response.data)
                if( response.data.code == 100){
                    var tmpSpaceList = response.data.spaceList
                    tmpSpaceList = [...tmpSpaceList]
                    tmpSpaceList = tmpSpaceList.reverse()
                    dispatch(setAiChatSpaceList(tmpSpaceList))
                    dispatch(setLoadingSpaceList(false)); // getSpaceList Loading End
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setLoadingSpaceList(false)); // getSpaceList Loading End
            });
        }, 500);
    }

    return (
        <>
            <ChatSidebar />
        </>
    )
}

export default ChatSidebarContainer
import React, {useState, useEffect, ChangeEventHandler, ChangeEvent} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTranslateTextAuto, setTranslateTextOpenSourceLangList, setTranslateTextOpenTargetLangList, setTranslateTextSourceLang, setTranslateTextSourceSearchList, setTranslateTextSourceSearchText, setTranslateTextSourceText, setTranslateTextSupportTargetLang, setTranslateTextTargetLang, setTranslateTextTargetSearchList, setTranslateTextTargetSearchText, setTranslateTextTargetText, setTranslateSourceText } from '../../modules/translateText';
import SearchInput from '../../components/apps/translateText/SearchInput';
import SearchListBox from '../../components/apps/translateText/SearchListBox';
import { useTranslation } from "react-i18next";
import { papagoEnJaGroup, papagoKoGroup } from '../../components/_commons/LanguageList';

const SearchContainer =(props) => {

    const {
        deepLTargetLang
    } = props;

    type LanguageList = {
        label: string | undefined,
        value: string | undefined
    };

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const [searchText, setSearchText] = useState("");
    const [selectedSourceLang, setSelectedSourceLang] = useState<LanguageList>({
        label: "",
        value: "",
    });
    const [searchList, setSearchList] = useState({});

    const opts = useSelector(state => state.organization.opts); // SERVICE_TRANSLATOR - G: Google, P: Papago / Naver, O: OpenAI
    const translateSourceLang = useSelector(state => state.translateText.translateSourceLang)
    const translateSupportLang = useSelector(state => state.translateText.translateSupportLang)
    const openSourceLangList = useSelector(state => state.translateText.translateOpenSourceLangList)
    const translateSourceSearchText = useSelector(state => state.translateText.translateSourceSearchText)
    const translateSourceSearchList = useSelector(state => state.translateText.translateSourceSearchList)

    const translateTargetLang = useSelector(state => state.translateText.translateTargetLang)
    const openTargetLangList = useSelector(state => state.translateText.translateOpenTargetLangList)
    const translateSupportTargetLang = useSelector(state => state.translateText.translateSupportTargetLang)

    const translateTargetSearchText = useSelector(state => state.translateText.translateTargetSearchText)
    const translateTargetSearchList = useSelector(state => state.translateText.translateTargetSearchList)

    function closeLangList() {
        dispatch( setTranslateTextOpenSourceLangList(false) );
        dispatch( setTranslateTextOpenTargetLangList(false) );
        dispatch( setTranslateTextSourceSearchText("") );
        dispatch( setTranslateTextTargetSearchText("") );

        setSearchText("");
    }

    useEffect(() => {
        setSearchText(""); // Clear Search Text

    }, [openSourceLangList, openTargetLangList])

    function searchSourceLang(e: React.ChangeEvent<HTMLInputElement>) {

        // update version
        const text = e.target.value
        setSearchText(text);

        let list = translateSupportLang.filter( (item: LanguageList) => (item.label).includes(text));
        setSearchList(list);
    }

    function changeSourceLang(event: React.MouseEvent<HTMLElement, MouseEvent>){

        // update version
        dispatch( setTranslateTextOpenSourceLangList(false) );

        const clickData = {
           label: event.currentTarget.dataset.label,
           value: event.currentTarget.dataset.value,
        };

        dispatch( setTranslateTextSourceLang(clickData) );
        dispatch( setTranslateTextTargetLang({
            label: "",
            value: ""
        }) ); // init
        dispatch( setTranslateTextAuto({
            label: "",
            value: ""
        }) ); // init
        setSearchText("");
    }

    function changeTargetLangState(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {

        // update version
        const clickData = {
            label: event.currentTarget.dataset.label,
            value: event.currentTarget.dataset.value
        }

        dispatch( setTranslateTextTargetLang(clickData) );
        dispatch( setTranslateTextOpenTargetLangList(false) );
    }

    function searchTargetLang(e: React.ChangeEvent<HTMLInputElement>) {

        // update version
        const text = e.target.value;
        setSearchText(text);

        const list: object = translateSupportTargetLang.filter( (item: LanguageList) => (item.label).includes(text))
        setSearchList(list);
    }
    
    useEffect(() => {

        // update
        let list = {};

        switch(opts['SERVICE_TRANSLATOR']) {
            case "G":
                list = translateSupportLang.filter( (item: LanguageList) => ( item.value != translateSourceLang.value ) && ( item.value != "auto" ) )
                dispatch( setTranslateTextSupportTargetLang(list) );
                break;

            case "DL":
                let deepLTargetList = [];
                Object.keys(deepLTargetLang).map((item, idx) => {
                    deepLTargetList[idx] = {
                        label: deepLTargetLang[item],
                        value: item
                    }
                })
                list = deepLTargetList.filter((item: LanguageList) => ( item.value != translateSourceLang.value ) && ( item.value != "auto" ) )
                dispatch( setTranslateTextSupportTargetLang(list) );
                break;

            case "P": {
                if(translateSourceLang.value == "ko") {
                    let papagoKoList = [];
                    Object.keys(papagoKoGroup()).map((item, idx) => {
                        papagoKoList[idx] = {
                            label: deepLTargetLang[item],
                            value: item
                        }
                    })

                    const filterdLang = papagoKoList.filter((item: LanguageList) => item.value != "ko")
                    dispatch( setTranslateTextSupportTargetLang(filterdLang) )
                    
                } else if(translateSourceLang.value == "en") {
                    let papagoEnJaList = [];
                    Object.keys(papagoEnJaGroup()).map((item, idx) => {
                        papagoEnJaList[idx] = {
                            label: deepLTargetLang[item],
                            value: item
                        }
                    })

                    const filterdLang = papagoEnJaList.filter((item: LanguageList) => item.value != "en")
                    dispatch( setTranslateTextSupportTargetLang(filterdLang) )
        
                } else if(translateSourceLang.value == "ja") {
                    let papagoEnJaList = [];
                    Object.keys(papagoEnJaGroup()).map((item, idx) => {
                        papagoEnJaList[idx] = {
                            label: (deepLTargetLang)[item],
                            value: item
                        }
                    })

                    const filterdLang = papagoEnJaList.filter((item: LanguageList) => item.value != "ja")
                    dispatch( setTranslateTextSupportTargetLang(filterdLang) )
        
                } else if(translateSourceLang.value == "es" || translateSourceLang.value == "ru" || translateSourceLang.value == "de" || translateSourceLang.value == "it") {
                    dispatch( setTranslateTextSupportTargetLang([
                        {
                            "label": t('lang.ko'),
                            "value": "ko",
                        }
                    ]) )
        
                } else if(translateSourceLang.value == "auto") {
                    const filterdLang = translateSupportLang.filter((item: LanguageList) => item.value != "auto")
                    dispatch( setTranslateTextSupportTargetLang(filterdLang) )
        
                } else {
                    dispatch( setTranslateTextSupportTargetLang([
                        {
                            "label": t('lang.ko'),
                            "value" : "ko"
                        },
                        {
                            "label": t('lang.en'),
                            "value" : "en"
                        },
                        {
                            "label": t('lang.ja'),
                            "value" : "ja"
                        },
                    ]) ); // ko, en, ja
                }
                break;
            }
        }

    }, [translateSourceLang]) // 소스 언어 변경

    return (
        <>
            {
                openSourceLangList && (
                    <div className='lang-search-modal'>
                        
                        <SearchInput
                            searchLang={ searchSourceLang }
                            closeLangList={ closeLangList }
                            searchText={ searchText }
                        />

                        <SearchListBox
                            changeLang={ changeSourceLang }
                            sourceLang={ translateSourceLang }
                            supportLang={ translateSupportLang }
                            searchText={ searchText }
                            searchList={ searchList }
                        />
                    </div>
                )
            }
            {
                openTargetLangList && (
                    <div className='lang-search-modal'>
                        <SearchInput
                            searchLang={ searchTargetLang }
                            closeLangList={ closeLangList }
                            searchText={ searchText }
                        />

                        <SearchListBox
                            changeLang={ changeTargetLangState }
                            sourceLang={ translateTargetLang }
                            supportLang={ translateSupportTargetLang }
                            searchText={ searchText }
                            searchList={ searchList }
                        />
                    </div>
                )
            }
        </>
    )
}

export default SearchContainer
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FiSidebar } from 'react-icons/fi';

import { setSummaryDocStatus, setSummaryDocSelectedSpace, setSummaryDocSelectedHistory, setSummaryDocProgress, setSummaryDocList, setSummaryFileInfo } from "../../../modules/summaryDoc";
import { setLoadingSpace } from "../../../modules/loading";
import { setUiHideSidebar } from "../../../modules/ui";
import SummaryDocList from "./SummaryDocList";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";
import { Trans } from "react-i18next";

const SummaryDocSidebar = () => {

    const dispatch = useDispatch();

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar)
    const stateLeftSidebar = useSelector(state => state.ui.uiStateLeftSidebar);
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const summaryDocList = useSelector(state => state.summaryDoc.summaryDocList);

    const { t, i18n } = useTranslation();

    function getSummaryDocState(item){

        dispatch(setLoadingSpace(true));
        item.state = 200;
        dispatch( setSummaryDocSelectedSpace(item) )

        /*
        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "spaceId" : item.space_id,
            "type" : "summaryDoc"
        });
        console.log(data, ' Get Summary State');
        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/summaryDoc/getSpace',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            console.log(response.data)
            if( response.data.code == 100){
                console.log(response.data)
                var spaceInfo = response.data.spaceInfo;
                if(spaceInfo.state == 100){
                    var spaceData = response.data.spaceData;
                    var tmpSummaryList = summaryDocList;
                    const entry = Object.entries(tmpSummaryList).find(([key, value]) => value.space_id === spaceInfo.space_id);
                    if (entry) {
                        const [key, value] = entry;
                        value.state = spaceInfo.state;
                        tmpSummaryList = [...tmpSummaryList]
                        
                        dispatch(setSummaryDocList(tmpSummaryList))                                  
                        
                    }

                    dispatch( setSummaryDocSelectedSpace(spaceInfo) );

                    dispatch(setSummaryDocSelectedHistory(spaceData));
                    dispatch(setLoadingSpace(false))              
                    
                }else{
              
                    dispatch(setLoadingSpace(true))
                }
            }
        })
        .catch((error) => {
            console.log(error);
            //dispatch( setLoadingSummaryDocList(false) );
        });
        */
    }

    function setUpload() {
        dispatch( setSummaryDocProgress(0) )
        dispatch( setSummaryDocStatus("upload") ) 
        dispatch( setSummaryDocSelectedSpace(false) )
        dispatch( setSummaryFileInfo({}) )
    }

    return (
        <>
            {
                uiSmallAiBox ? ( // Small Sidebar
                    <>
                        <div className='leftSidebar-list fl' style={ stateLeftSidebar==false ? { height: '0px' } : {}  }>
                            <div className="sidebar-list-title">
                                <div className="fl">
                                    { t("translateDoc.doc_list") }
                                </div>
                                <div className="count-wrapper fr">
                                    <Trans
                                        i18nKey={ "translateDoc.doc_count" }
                                        values={{
                                            count: summaryDocList.length
                                        }}
                                        components={{
                                            text: <></>,
                                            color: <span className="count-color"></span>
                                        }}
                                    />
                                </div>
                                <div className="cr"></div>
                            </div>

                            <SummaryDocList getSummaryDocState={ getSummaryDocState } />
                            <div className='leftSidebar-optionList'>
                                <div 
                                    className="add-new-space-box"
                                    onClick={ setUpload }
                                >
                                    <AiOutlinePlusCircle className="mr-2" size={ 20 } />
                                    { t("summaryDoc.new_doc") }
                                </div>
                            </div>
                        </div>
                    </>
                ) : ( // Modal Sidebar
                    <>
                        <div className='leftSidebar-list fl' style={ uiHideSidebar ? { marginLeft: '-460px' } : {} } >
                            <div className='add-btn-wrapper d-flex'>
                                <div 
                                    className='btn-add'
                                    onClick={ setUpload }
                                >
                                    <div className="content-center">
                                        <AiOutlinePlusCircle className='mr-1' size={ 20 } />
                                        <div className="btn-add-text">{ t("summaryDoc.new_doc") }</div>
                                    </div>
                                </div>
                                <div className="btn-close" onClick={() => dispatch(setUiHideSidebar(true)) }>
                                    <FiSidebar />
                                </div>
                            </div>
                            <div className="sidebar-list-title">
                                { t("translateDoc.doc_list") }
                            </div>
                            <SummaryDocList getSummaryDocState={ getSummaryDocState } />
                        </div>

                        <div 
                            className="btn-display-sidebar"
                            style={ uiHideSidebar ? { opacity: 1, transition: 'opacity 0.5s', visibility: 'visible' } : { opacity: 0, transition: 'opacity 0.5s', visibility: 'hidden' } }
                            onClick={() => { dispatch(setUiHideSidebar(false) ); }}
                        >
                            <FiSidebar />
                        </div>
                    </>
                )
            }
        </>
    )
}

export default SummaryDocSidebar
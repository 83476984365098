import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedSetting } from "../../../modules/setting";
import { useTranslation } from "react-i18next";

const SettingSidebar = () => {

    const dispatch = useDispatch();

    const opts = useSelector(state => state.organization.opts);
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const stateLeftSidebar = useSelector(state => state.ui.uiStateLeftSidebar);
    const selectedSetting = useSelector(state => state.setting.selectedSetting);

    const { t, i18n } = useTranslation();

    function clickSettingTab(settingType: string) {
        dispatch(setSelectedSetting(settingType))
    }

    const RenderModalSidebar = () => {
        return (
            <></>
        )
    }

    const RenderSmallSidebar = () => {
        return (
            <></>
        )
    }
    
    return (
        <div className='leftSidebar-list fl' style={ uiSmallAiBox ? (
            stateLeftSidebar ? { height: '445px' } : { height: '0px' }
        ) : {} }>
            <div className="sidebar-list-title">
                { t('common.setup') }
            </div>
            {/* <ul className="mb-0 sidebar-list-wrapper"> */}
            <ul className="mb-0 sidebar-setting-list-wrapper">
                <li
                    className={ selectedSetting == 'Etc' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                    onClick={() => clickSettingTab('Etc')}
                > {/* selected-space-room */}
                    <div className="space-label fl text-truncate ml-2">
                        { t('setting.etc') }
                    </div>
                    <div className="cr" />
                </li>
                {
                    IS_DEV ? (
                        <>
                            {
                                opts['OPT_CSMSAI_CHAT'] && (
                                    <li 
                                        className={ selectedSetting == 'CHAT' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('CHAT')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.chat') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                            {
                                opts['OPT_CSMSAI_TRANSLATE_TEXT'] && (
                                    <li
                                        className={ selectedSetting == 'TranslateText' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('TranslateText')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.translateText') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                            {
                                opts['OPT_CSMSAI_TRANSLATE_DOCUMENT'] && (
                                    <li 
                                        className={ selectedSetting == 'TranslateDoc' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('TranslateDoc')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.translateDoc') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                            {
                                opts['OPT_CSMSAI_SUMMARY_DOCUMENT'] && (
                                    <li 
                                        className={ selectedSetting == 'SummaryDoc' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('SummaryDoc')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.summaryDoc') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                            {
                                opts['OPT_CSMSAI_SUMMARY_DOCUMENT'] && (
                                    <li 
                                        className={ selectedSetting == 'WritingTutor' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('WritingTutor')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.writingTutor') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                            {
                                opts['OPT_CSMSAI_SUMMARY_DOCUMENT'] && (
                                    <li 
                                        className={ selectedSetting == 'SpeakingTutor' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('SpeakingTutor')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.speakingTutor') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                            {
                                opts['OPT_CSMSAI_SUMMARY_DOCUMENT'] && (
                                    <li 
                                        className={ selectedSetting == 'InterviewPrep' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('InterviewPrep')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.interviewPrep') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                        </>
                    ) : (
                        <>
                            {
                                opts['OPT_CSMSAI_TRANSLATE_TEXT'] && (
                                    <li
                                        className={ selectedSetting == 'TranslateText' ? 'btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room' : 'btn-select-space pl-2 pr-3 pt-3 pb-3' }
                                        onClick={() => clickSettingTab('TranslateText')}
                                    > {/* selected-space-room */}
                                        <div className="space-label fl text-truncate ml-2">
                                            { t('common.translateText') }
                                        </div>
                                        <div className="cr" />
                                    </li>
                                )
                            }
                        </>
                    )
                }
            </ul>
        </div>
    )
}

export default SettingSidebar
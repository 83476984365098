import React from 'react';

import SummaryDocSidebarContainer from '../../containers/summaryDoc/SummaryDocSidebarContainer';
import SummaryDocSpaceContainer from '../../containers/summaryDoc/SummaryDocSpaceContainer';

const UiSummaryDoc = () => {

    /*
        파일을 추가한 상태가 아닌 경우 UploadSummaryDoc
        파일을 추가한 상태가 아니지만 사이드바를 클릭해서 요약본을 가져온 경우 SummaryDocChat
        파일을 추가한 상태인 경우 ModalSelectSummaryLang / SmallSelectSummaryLang
    */

    return (
        <>
            {/* <SummarySidebarLeft /> */}
            <SummaryDocSidebarContainer />
            <SummaryDocSpaceContainer />
        </>
    );

}


export default UiSummaryDoc;
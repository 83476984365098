import React from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineCheck } from 'react-icons/ai'
import { useTranslation } from "react-i18next";

const SearchListBox = (props) => {
    
    const {
        changeLang,
        sourceLang,
        supportLang,
        searchText,
        searchList
    } = props;

    const { t, i18n } = useTranslation();

    return (
        <>
            {
                searchText != "" ? (
                    searchList.length != 0 ? (
                        <div className='search-result-box'>
                            {
                                searchList.map((item, idx) => {
                                    if(sourceLang.value == item.value) {
                                        return (
                                            <div
                                                key={ idx }
                                                className='lang-option-selected'
                                                data-label={ item.label }
                                                data-value={ item.value }
                                                style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                                onClick={(e) => changeLang(e)}
                                            >
                                                <AiOutlineCheck />&nbsp;&nbsp;{item.label}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div 
                                                key={ idx } 
                                                className='lang-option' 
                                                data-label={ item.label } 
                                                data-value={ item.value } 
                                                style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                                onClick={(e) => changeLang(e)}
                                            >
                                                {item.label}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    ) : (
                        <div className='none-result'>{ t('translateText.empty') }</div>
                    )

                ) : (
                    <div className='lang-google-wrapper'> {/* remove this div / class on papago */}
                        <div className='lang-list-box'>
                            { 
                                supportLang.map((item, idx) => {
                                    if(sourceLang.value == item.value) {
                                        return (
                                            <div 
                                                key={ idx }
                                                className='lang-option-selected'
                                                data-label={ item.label }
                                                data-value={ item.value }
                                                style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                                onClick={(e) => changeLang(e)}
                                            >
                                                <AiOutlineCheck />&nbsp;&nbsp;{item.label}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div
                                                key={ idx }
                                                className='lang-option' 
                                                data-label={ item.label } 
                                                data-value={ item.value } 
                                                onClick={(e) => changeLang(e)}
                                                style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                            >
                                                {item.label}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SearchListBox;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone, { FileRejection, useDropzone } from 'react-dropzone'

import { Container, Row, Col } from 'react-bootstrap';
import { LuFolderUp } from 'react-icons/lu'
import { TbBookUpload } from 'react-icons/tb';
import { FiSidebar } from 'react-icons/fi'
import { setSelectLangDoc, setTranslateDocInfo, setTranslateDocProgress, setTranslateStatus, setUploadDoc, setUploadStatus } from '../../../modules/translateDoc';
import { setUiHideSidebar } from '../../../modules/ui';

import { setSelectedDocInfo, setTranslateDocList} from "../../../modules/translateDoc";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

const UploadDocSpace = () => {

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const [uploadType, setUploadType] = useState({})

    const [checkUploadType, setCheckUploadType] = useState(false)
    const [checkUploadSize, setCheckUploadSize] = useState(false)

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar);
    const translateDocInfo = useSelector(state => state.translateDoc.translateDocInfo);
    const opts = useSelector(state => state.organization.opts); // SERVICE_TRANSLATOR - G: Google, P: Papago / Naver, O: OpenAI

    function getUploadFileInfo(acceptedFiles) {
        console.log(acceptedFiles[0]);
        
        if( typeof acceptedFiles[0] !== "undefined" ) {
            dispatch( setTranslateDocInfo(acceptedFiles[0]) ); // multiple false
            dispatch( setTranslateStatus("select") );
        }
        
        // dispatch( setTranslateDocInfo(acceptedFiles) ); // multiple true
    }

    useEffect(() => {
        dispatch( setTranslateDocProgress(false) );
        setUploadAcceptTypes();

        return () => {
            console.log('Clean Up Upload Space')
            // dispatch( setTranslateDocInfo({}) );
        }
    }, [])

    function setUploadAcceptTypes() {
        let type = {};

        switch(opts['SERVICE_TRANSLATOR']){
            case "P":
                type = {
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/vnd.hancom.hwp': ['.hwp']
                }
                break;

            case "G":
                
                type = {
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/pdf': ['.pdf'],
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }
                
                break;
                
            case "DL":
                type = {
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                    'application/pdf': ['.pdf'],
                    'text/plain': ['.txt'],
                    'application/vnd.hancom.hwp': ['.hwp'],
                    // 'text/*': ['.txt']
                    // 'text/plain': ['.txt']
                }
                break;

            default:
                type = {
                    'application/pdf': ['.pdf'],
                }
        }

        setUploadType(type)
        // return type;
    }


    function DocTypeText() {
        switch(opts['SERVICE_TRANSLATOR']) {
            case "P":
                return (
                    <div className='sub-title'>docx, pptx, xlsx, hwp { t("translateDoc.doc_type") }</div>
                )

            case "G":
                return (
                    <div className='sub-title'>docx, pdf, xlsx, pptx { t("translateDoc.doc_type") }</div>
                )
            
            case "DL":
                return (
                    <div className='sub-title'>docx, pptx, pdf, txt, hwp { t("translateDoc.doc_type") }</div>
                )

            default:
                return (
                    <div className='sub-title'>pdf { t("translateDoc.doc_type") }</div>
                )
        }
    }

    function DocSizeText() {
        switch(opts['SERVICE_TRANSLATOR']) {
            case "DL":
                return (
                    <div className='sub-title'>({ t("translateDoc.doc_size", { size: 20 }) })</div>
                )

            case "G":
                return (
                    <div className='sub-title'>{ t("translateDoc.doc_size_text", { size: 20, length: 30 })}</div>
                )

            case "P":
                return (
                    <div className='sub-title'>{ t("translateDoc.doc_size_text", { size: 20, length: 30 })}</div>
                )

            default:
                return (
                    <div className='sub-title'>{ t("translateDoc.doc_size_text", { size: 20, length: 30 })}</div>
                )
        }
    }
    // opts에 따른 maxSize 변경

    function fileValidator(file) {
        
        setCheckUploadSize(false)
        setCheckUploadType(false)

        if(typeof file.size != 'undefined') {
            if(file.size > 20000000) {
                dispatch( setTranslateDocInfo(false) );
                setCheckUploadSize(true)
            }
        }

        if(typeof file.type != 'undefined') {
            console.log(file.type)
            const fileType = Object.keys(uploadType).includes(file.type);

            if(fileType == false) {
                dispatch( setTranslateDocInfo(false) );
                setCheckUploadType(true);
            }
        }
    }

    function handleRejectedFile(file: Array<FileRejection>) {
        setCheckUploadSize(false)
        setCheckUploadType(false)

        console.log(file)

        const errorName = file[0].errors[0].code

        console.log(errorName, " : errorName")

        switch(errorName) { 
            case "file-too-large" : 
                dispatch(setTranslateDocInfo(false))
                setCheckUploadSize(true)
                break;

            case "file-invalid-type" : 
                dispatch(setTranslateDocInfo(false))
                setCheckUploadType(true)
                break;
        }
    }

    return (
        <Dropzone onDrop={acceptedFiles => getUploadFileInfo(acceptedFiles)} 
                                           maxFiles={ 1 } 
                                           multiple={ false } 
                                           accept={ uploadType } 
                                           maxSize={ 20000000 } 
                                           // validator={ fileValidator }
                                           noKeyboard={ true }
                                           onDropRejected={ handleRejectedFile }
        >
            {({getRootProps, getInputProps}) => (
                <div id="doc-upload-container" className='fr' style={ ( !uiSmallAiBox && uiHideSidebar ) ? { width: '100%' } : {} }>
                    <div className='doc-box-wrapper' {...getRootProps()}>
                        <div className='file-container'>
                            <section>
                                <input {...getInputProps()} />
                                <div className='position-relative'>
                                    <div><LuFolderUp className='doc-upload-icon' /></div>

                                    <div className='pt-2 pb-2 text-title'>
                                        <Trans
                                            i18nKey={ 'translateDoc.add_pc_doc' }
                                            components={{
                                                row: <div />,
                                                color: <span className='ai-font-color'></span>,
                                                text: <span></span>
                                            }}
                                        />
                                    </div>
                                    <DocTypeText />
                                    <DocSizeText />
                                    <div className='over-size-input w-100 font-weight-bold pt-2'>
                                        {
                                            !translateDocInfo && (
                                                <>
                                                    {
                                                        checkUploadSize && (
                                                            <div>{ t("translateDoc.error_size") }</div>
                                                        )
                                                    }

                                                    {
                                                        checkUploadType && (
                                                            <div>{ t("translateDoc.error_type") }</div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                                
                            </section>
                        </div>
                    </div>
                    <div className="cr"></div>
                </div>
            )}
        </Dropzone>
    )
}

export default UploadDocSpace
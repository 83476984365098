import React from "react";
import InterviewSidebarContainer from "../../containers/interviewPrep/InterviewSidebarContainer";
import InterviewSpaceContainer from "../../containers/interviewPrep/InterviewSpaceContainer";

import '../../../resources/assets/styles/sass/UiInterview.scss';

const UiInterviewPrep = () => {
    return (
        <>
            <InterviewSidebarContainer />
            <InterviewSpaceContainer />
        </>
    )
}

export default UiInterviewPrep
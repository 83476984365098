import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TranslateDocMain from '../../components/apps/translateDoc/TranslateDocMain';
import SelectDocLang from '../../components/apps/translateDoc/SelectDocLang';
import UploadDocSpace from '../../components/apps/translateDoc/UploadDocSpace';
import { deepLSupportLang, deepLTargetLang, googleSupportLang, papagoSupportLang } from '../../components/_commons/LanguageList';



const DocSpaceContainer = () => {
    const [sourceLangList, setSourceLangList] = useState({});
    const [targetLangList, setTargetLangList] = useState({});

    const opts = useSelector(state => state.organization.opts);
    const translateStatus = useSelector(state => state.translateDoc.translateStatus);

    useEffect(() => {

        switch(opts['SERVICE_TRANSLATOR']) {
            case "P":
                setSourceLangList(papagoSupportLang())
                setTargetLangList(papagoSupportLang())
                break;
            
            case "G":
                setSourceLangList(googleSupportLang())
                setTargetLangList(googleSupportLang())
                break;

            case "DL":
                setSourceLangList(deepLSupportLang())
                setTargetLangList(deepLTargetLang())
                break;
        }

    }, [])

    return (
        <>
            {
                translateStatus == "upload" ? (
                    <UploadDocSpace />
                ) : (
                    translateStatus == "select" ? (
                        <SelectDocLang
                        />
                        /*
                        uiSmallAiBox ? (
                            <SmallSelectDoclang />
                        ) : (
                            <ModalSelectDocLang />
                        )
                        */
                    ) : (
                       (
                            <TranslateDocMain
                                sourceLangList={ sourceLangList }
                                targetLangList={ targetLangList }
                            />
                        )
                    )
                )
            }
        </>
    )
}

export default DocSpaceContainer
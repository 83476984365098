import React from "react"
import { useSelector, useDispatch } from "react-redux";

import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FiSidebar } from 'react-icons/fi';
import { setUiHideSidebar } from "../../../modules/ui";
import { setTranslateDocInfo, setTranslateStatus } from "../../../modules/translateDoc";
import DocList from "../../../components/apps/translateDoc/DocList";
import { setSelectedDocInfo, setTranslateDocList} from "../../../modules/translateDoc";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";
import { Trans } from "react-i18next";

const TranslateDocSidebar = () => {
    const dispatch = useDispatch();

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar)
    const translateDocList = useSelector(state => state.translateDoc.translateDocList);
    const stateLeftSidebar = useSelector(state => state.ui.uiStateLeftSidebar);

    const { t, i18n } = useTranslation();

    function setUpload(){
        dispatch( setTranslateStatus("upload") );
        dispatch(setSelectedDocInfo([]))
        dispatch(setTranslateDocInfo({}));
    }

    return (
        <>
            {
                !uiSmallAiBox ? 
                    <> {/* render modal view */}
                        <div className='leftSidebar-list fl' style={ uiHideSidebar ? { marginLeft: '-460px' } : {} } >
                            <div className='add-btn-wrapper d-flex'>
                                <div className='btn-add' onClick={() => { setUpload() }}>
                                    <div className="content-center">
                                        <AiOutlinePlusCircle className='mr-1' size={20} />
                                        <div className="btn-add-text">{ t("translateDoc.new_doc") }</div>
                                    </div>
                                </div>
                                <div className="btn-close" onClick={() => dispatch(setUiHideSidebar(true)) }>
                                    <FiSidebar />
                                </div>
                            </div>
                            <div className="sidebar-list-title">
                                { t("translateDoc.doc_list") }
                            </div>
                            <DocList />
                        </div>

                        <div 
                            className="btn-display-sidebar"
                            style={ uiHideSidebar ? { opacity: 1, transition: 'opacity 0.5s', visibility: 'visible' } : { opacity: 0, transition: 'opacity 0.5s', visibility: 'hidden' } }
                            onClick={() => { dispatch(setUiHideSidebar(false) ); }}
                        >
                            <FiSidebar />
                        </div>
                    </>

                :
                    <>
                        {/* render small view */}
                        <div className='leftSidebar-list fl' style={ stateLeftSidebar==false ? { height: '0px' } : {}  }>
                            <div className="sidebar-list-title">
                                <div className="fl">
                                    { t("translateDoc.doc_list") }
                                </div>
                                <div className="count-wrapper fr">
                                    <Trans
                                        i18nKey={ "translateDoc.doc_count" }
                                        values={{
                                            count: translateDocList.length
                                        }}
                                        components={{
                                            text: <></>,
                                            color: <span className="count-color"></span>
                                        }}
                                    />
                                </div>
                                <div className="cr"></div>
                            </div>

                            <DocList />
                            <div className='leftSidebar-optionList'>
                                <div className="add-new-space-box" onClick={() => { dispatch( setTranslateStatus("upload") ) }}>
                                    <AiOutlinePlusCircle className="mr-2" size={ 20 } />
                                    { t("translateDoc.new_doc") }
                                </div>
                            </div>
                        </div>
                        <div>
                            <FiSidebar className="anime-btn" onClick={() =>  dispatch(setUiHideSidebar(false) )} style={ ( !uiSmallAiBox && uiHideSidebar ) ? { opacity: 1, transition: 'opacity 0.8s', visibility: 'visible' } : { opacity: 0, transition: 'opacity 0.8s', visibility: 'hidden' } } />
                        </div>
                    </>
            }
        </>
    )
}

export default TranslateDocSidebar;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import { Row, Col, Modal } from 'react-bootstrap';
import { AiOutlineArrowRight } from 'react-icons/ai'

import { MenuGuideBubble } from '../_commons/';
import { setSelectedApp } from '../../modules/status';

import { useTranslation } from "react-i18next";

import { Trans } from 'react-i18next'
import { setSelectedSetting } from '../../modules/setting';
import MainBottomBanner from '../_commons/MainBottomBanner';
import MainTopBanner from '../_commons/MainTopBanner';

const UiMain = (props) => {

    const {getEventState} = props

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();

    const uiShowAiBox = useSelector(state => state.ui.uiShowAiBox);
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const opts = useSelector(state => state.organization.opts);
    const orgCode = useSelector(state => state.organization.orgCode);
    const userOpts = useSelector(state => state.setting.userOpts)

    const randomNumber = Math.floor( Math.random() * 5 + 1 )

    // get event organization code
    /*
    function getEventState() {
        if(orgCode == "kangwon" || orgCode == "gwlrs" || orgCode == "inu_test" )  {
            return true
        } else {
            return false
        }
    }
    */

    useEffect(() => {
        dispatch(setSelectedSetting("Etc"));
        console.log('Start Ui Main')
    }, [])

    const cookies = new Cookies();

    function linkToBoard() {
        switch(orgCode){
            case "inu_test":
                window.open('/', '_blank')    
            break;            
            case "kangwon":
                window.open('https://eruri.kangwon.ac.kr/mod/ubboard/view.php?id=1485459', '_blank')    
            break;
            case "gwlrs":
                window.open(' https://lms.gwlrs.ac.kr/mod/ubboard/view.php?id=5262', '_blank')    
                
            break;
        }
    }

    function linkToSupportPage() {
        window.open('https://support.coursemos.kr/ko/manual/ai/common/1.0/17d0642fb467fa8dedfbcfbb8d186648', '_blank')
    }

    function linkToFormPage() {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSeSK8Ihvpr3YLlnWZQQRYMgL6V-GE0tAbdX12BLL_tLOH9ieg/viewform', '_blank')
    }

    const RenderSmallView = () => {

        let orgStyle = {
            titlePosition: {},
            titleText: {},
            subtitleText: {},
            guideBtn: {}
        }

        let titlePositionStyle = {
            'ko': { top: 'unset' },
            'en': { top: 'unset' },
            'ja': { top: 'unset' },
            'zh': { top: 'unset' },
            'es': { top: 'unset' },
            'vi': { top: '35px' }
        };
        let titleTextStyle = {
            'es': { fontSize: '18px' },
            'vi': { fontSize: '18px' }
        }
        let subtitleTextStyle = {
            'vi': { marginTop: '5px' }
        }
        let guideBtnStyle = {
            'vi': { width: '170px', marginTop: '8px', marginBottom: '3px' }
        }

        if(typeof userOpts != 'undefined') {
            console.log(userOpts, " check user opts")
            let userLang;
            if(typeof userOpts['BASE_MY_LANG'] != 'undefined') {
                userLang = userOpts['BASE_MY_LANG'].substring(0, 2);
            }

            switch(orgCode) {
                case "hallym_dev":
                    orgStyle.titlePosition = titlePositionStyle[userLang];
                    orgStyle.titleText = titleTextStyle[userLang];
                    orgStyle.subtitleText = subtitleTextStyle[userLang]
                    orgStyle.guideBtn = guideBtnStyle[userLang]
                    // titlePositionStyle = banner + userLang + 'Style';

                    break;
                case "csms39_mdl_dev":
                    orgStyle.titlePosition = titlePositionStyle[userLang];
                    orgStyle.titleText = titleTextStyle[userLang];
                    orgStyle.subtitleText = subtitleTextStyle[userLang]
                    orgStyle.guideBtn = guideBtnStyle[userLang]
                    // titlePositionStyle = 'banner' + userLang + 'Style';
                    break;
                default:
                    // titlePositionStyle = {}
                    break;
            }
        }

        return (
            <div className='main-box-btns'>
                <div className='title-position' style={ orgStyle.titlePosition }>
                    <div className='ai-title-text'>
                        <div style={ orgStyle.titleText }>{ t("common.welcome") }</div>
                        <div style={ orgStyle.titleText }>{ t("common.ai_name") }</div>
                        <div style={ orgStyle.subtitleText}>{ t("common.welcome_title") }</div>   
                    </div>
                    <div className="box-aiTutor-mainBtns">
                        <div 
                            id='btn-aiTutor-toGuide'
                            onClick={ linkToSupportPage }
                            // style={ getEventState ? {} : { marginTop: '15px' } } 이벤트
                            style={ orgStyle.guideBtn }
                        >
                            { t("common.guide") } &nbsp;
                            <AiOutlineArrowRight size={ 12 } fontWeight={ '500'} color='#FFF'/>
                        </div>
                        <div 
                            id="btn-aiTutor-toBoard"
                            onClick={ linkToBoard }
                            style={ getEventState ? {} : { display: 'none' } }
                        >
                            { t("common.user_board") } &nbsp;
                            <AiOutlineArrowRight size={ 12 } fontWeight={ '500'} color='#FFF'/>
                        </div>
                        <div className='cr'></div>
                    </div>

                    <MainTopBanner /> {/* 상단 맞춤 배너 */}

                </div>
                <div className={ "small-bg-" + randomNumber }></div>
                {/* <div className='main-box-btns-wrapper' style={ getEventState ? {} : { top: '280px' } }> */}
                <div className='main-box-btns-wrapper'>
                    <SmallMenuBox optType="OPT_CSMSAI_CHAT" guideName="chat-guide" menuName="CHAT" langName="chat" iconName="ai-chat-icon" />
                    <SmallMenuBox optType="OPT_CSMSAI_TRANSLATE_TEXT" guideName="translate-text-guide" menuName="TranslateText" langName="translateText" iconName="translate-text-icon" />
                    <SmallMenuBox optType="OPT_CSMSAI_TRANSLATE_DOCUMENT" guideName="translate-document-guide" menuName="TranslateDoc" langName="translateDoc" iconName="translate-doc-icon" />
                    <SmallMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="SummaryDoc" langName="summaryDoc" iconName="summary-doc-icon" />
                    {
                        IS_DEV && (
                            <>
                                <SmallMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="WritingTutor" langName="writingTutor" iconName="summary-doc-icon" />
                                <SmallMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="SpeakingTutor" langName="speakingTutor" iconName="summary-doc-icon" />
                                <SmallMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="InterviewPrep" langName="interviewPrep" iconName="summary-doc-icon" />
                            </>
                        )
                    }
                </div>

                <MainBottomBanner />
                {
                    getEventState && (
                        <div className="event-box-container">
                            <div className='event-box' onClick={ linkToFormPage } />
                        </div>
                    )
                }
                
            </div>
        )
    }

    const SmallMenuBox = (props) => {
        const {
            optType,
            guideName,
            menuName,
            langName,
            iconName
        } = props

        return (
            <>
                {
                    (
                        typeof opts[optType] != "undefined" && opts[optType]) ? (
                            <div className={ 'block-box ' + guideName } onClick={() => dispatch(setSelectedApp(menuName))} style={ langName == "interviewPrep" ? { left: '15px', display: 'block', position: 'absolute' } : {}}>
                                <div className='box-title'>
                                    { t("common." + langName) }
                                </div>
                                <div className={ 'box-icon ' + iconName }/>
                            </div>
                    ) : <></>
                }
            </>
        )
    }

    const ModalMenuBox = (props) => {
        const {
            optType,
            guideName,
            menuName,
            langName,
            iconName
        } = props

        return (
            <>
                {
                    (typeof opts[optType] != "undefined" && opts[optType]) ? (
                        <Col xs={ 6 } sm={ 6 } md={ 6 } lg={ 4 } xl={ 4 } className={`ai-menu-box ${ guideName }`} onClick={() => dispatch(setSelectedApp(menuName))}>
                            <div className='block-box'>
                                <div className='box-text'>
                                    { t("common." + langName) }
                                </div>

                                {
                                    optType == "OPT_CSMSAI_TRANSLATE_TEXT" && (
                                        <Trans 
                                                i18nKey="common.translateText_subtitle"
                                                components={{
                                                    drag: <div className='small-box-text' style={{ top: '40px' }} />,
                                                    wrapper: <div className='small-box-text' style={{ top: '60px' }} />,
                                                    shortcut: <span className='ai-font-color' />,
                                                    text: <span />,
                                                }}
                                            />
                                    )
                                }

                                <div className={`box-icon ${ iconName }`}></div>
                            </div>
                        </Col>
                    ) : (
                        <></>
                    )
                }
            </>
        )
    }

    const RenderModalView = () => {
        return (
            <div className='main-box-btns'>
                <div className='main-box-btns-wrapper'>
                    <Row className='main-box-row-text'>
                        <Col className='ai-menu-box'>
                            <div className='title-position'>
                                <div className='ai-title-text'>
                                    <div>{ t("common.welcome") }</div>
                                    <div>{ t("common.ai_name") }</div>
                                    <div>{ t("common.welcome_title") }</div>
                                </div>
                                {/* https://stackoverflow.com/questions/62472735/i18next-react-html-tags html tag to i18next component */}
                            </div>
                        </Col>
                    </Row>
                    
                    <Row className='main-box-guide'>
                        <div className='btn-guide' onClick={ linkToSupportPage }>
                            { t("common.guide") } &nbsp; <AiOutlineArrowRight fontWeight={ 'bold'} color='#FFF'/>
                        </div>
                        <div 
                                
                                id="btn-aiTutor-toBoard"
                                className="btn-guide"
                                onClick={ linkToBoard }
                                style={ getEventState ? {} : { display: 'none' } }
                            >
                                { t("common.user_board")} &nbsp;
                                <AiOutlineArrowRight size={ 12 } fontWeight={ '500'} color='#FFF'/>
                        </div>                                    
                    </Row>

                    <MainTopBanner /> {/* 상단 맞춤 배너 */}

                    <Row className='main-box-row-icon'>
                        <ModalMenuBox optType="OPT_CSMSAI_CHAT" guideName="chat-guide" menuName="CHAT" langName="chat" iconName="ai-chat-icon" />
                        <ModalMenuBox optType="OPT_CSMSAI_TRANSLATE_TEXT" guideName="translate-text-guide" menuName="TranslateText" langName="translateText" iconName="translate-text-icon" />
                        <ModalMenuBox optType="OPT_CSMSAI_TRANSLATE_DOCUMENT" guideName="translate-document-guide" menuName="TranslateDoc" langName="translateDoc" iconName="translate-doc-icon" />
                        <ModalMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="SummaryDoc" langName="summaryDoc" iconName="summary-doc-icon" />


                        {
                            IS_DEV && (
                                <>
                                    <ModalMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="WritingTutor" langName="writingTutor" iconName="summary-doc-icon" />
                                    <ModalMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="SpeakingTutor" langName="speakingTutor" iconName="summary-doc-icon" />
                                    <ModalMenuBox optType="OPT_CSMSAI_SUMMARY_DOCUMENT" guideName="summary-document-guide" menuName="InterviewPrep" langName="interviewPrep" iconName="summary-doc-icon" />
                                </>
                            )
                        }

                    </Row>

                    <MainBottomBanner />
                    {
                        getEventState && (
                            <div className='ai-event-box-row w-100'>
                                <div className='ai-event-wrapper' style={{ width: '628px', height: '98px', margin: '0 auto' }}>
                                    <div className='ai-event-box' onClick={ linkToFormPage }></div>

                                </div>
                            </div>
                        )
                    }
                    
                </div>
            </div>
        )
    }

    return (
        <>
            {
                uiShowAiBox && (
                    uiSmallAiBox ? (
                        <RenderSmallView />
                    ) : (
                        <RenderModalView />
                    )
                )
            }
            
            {
                !cookies.get('aichat-event-guide') && (
                    <MenuGuideBubble getEventState={ getEventState } />
                )
            }
        </>
    );

}


export default UiMain;
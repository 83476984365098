import React, { useState } from "react"
import { TbPrompt } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { setAiChatPromptPage } from "../../../modules/aiChat";
import { Button, Col, Modal, Form, Pagination, Row } from "react-bootstrap";

const PromptPage = () => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const hidePromptPage = () => {
        dispatch(setAiChatPromptPage(false))
    }

    return (
        <div className="prompt-page-container">
            {/* <div className="prompt-page-wrapper w-100"> */}
                <div className="prompt-page-header w-100">
                    <Row className="header-row">
                        <Col className="header-menu" xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 } xl={ 4 }>즐겨찾기</Col>
                        <Col className="header-menu" xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 } xl={ 4 }>공개 프롬프트</Col>
                        <Col className="header-menu" xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 } xl={ 4 }>개인 프롬프트</Col>
                    </Row>
                    {/* <Nav  variant="tabs" defaultActiveKey="/home">
                    <Nav.Item>
                        <Nav.Link href="/home">Active</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-1">Option 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="disabled" disabled>
                        Disabled
                        </Nav.Link>
                    </Nav.Item>
                    </Nav> */}
                </div>

                <div className="prompt-page-nav">
                    <div className="nav-row">
                        <div>
                            <Form.Group controlId="formGridState">
                                <Form.Label>주제</Form.Label>
                                <Form.Control as="select" defaultValue="수학">
                                    <option>수학</option>
                                    <option>컴퓨터</option>
                                    <option>역사</option>
                                    <option>과학</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group controlId="formGridState">
                                <Form.Label>활동</Form.Label>
                                <Form.Control as="select" defaultValue="활동1">
                                    <option>활동1</option>
                                    <option>활동2</option>
                                    <option>야외 활동</option>
                                    <option>실내 활동</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group controlId="formGridState">
                                <Form.Label>정렬 기준</Form.Label>
                                <Form.Control as="select" defaultValue="가나다순">
                                    <option>가나다순</option>
                                    <option>최신순</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Label>검색</Form.Label>
                            <Form.Control placeholder="검색" />
                        </div>
                    </div>
                </div>

                <div className="prompt-page-body">
                    <div className="body-row">
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                        <button className="prompt-content-box-wrapper" type="button">
                            <div className="prompt-content-box"></div>
                        </button>
                    </div>
                </div>

                <div className="prompt-page-pagination">
                    <div className="pagination-row">
                    <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Ellipsis />

                        <Pagination.Item>{10}</Pagination.Item>
                        <Pagination.Item>{11}</Pagination.Item>
                        <Pagination.Item active>{12}</Pagination.Item>

                        <Pagination.Ellipsis />
                        <Pagination.Item>{20}</Pagination.Item>
                        <Pagination.Next />
                    </Pagination>
                    </div>
                </div>

                <div className="prompt-page-style">
                    <div className="prompt-page-style-row">
                        <div>
                        <Form.Group controlId="formGridState">
                            <Form.Label>Output In</Form.Label>
                                <Form.Control as="select" defaultValue="한국어">
                                    <option>한국어</option>
                                    <option>영어</option>
                                    <option>중국어</option>
                                    <option>일본어</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group controlId="formGridState">
                            <Form.Label>Tone</Form.Label>
                                <Form.Control as="select" defaultValue="Default">
                                    <option>Default</option>
                                    <option>Ai Chat</option>
                                    <option>Live Translation</option>
                                    <option>Document Translation</option>
                                    <option>Document Summary</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div>
                        <Form.Group controlId="formGridState">
                            <Form.Label>Writing Style</Form.Label>
                                <Form.Control as="select" defaultValue="Default">
                                    <option>Default</option>
                                    <option>컴퓨터</option>
                                    <option>역사</option>
                                    <option>수학</option>
                                    <option>과학</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            {/* </div> */}

            <button type='button' className='prompt-icon selected-prompt-icon' onClick={() => hidePromptPage()}>
                <TbPrompt size={ 20 } color='#8A8A8A' />
            </button>
        </div>
    )
}

export default PromptPage
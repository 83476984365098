import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TbMessageChatbot } from "react-icons/tb";
import { Cookies } from 'react-cookie';

import { setUiModalAiBox, setUiShowAiBox, setUiSmallAiBox } from '../../modules/ui';

import ToolTipContainer from '../../containers/ToolTipContainer';
import { MainGuideBubble } from './';

const BtnCsmsAi = () => {
    const dispatch = useDispatch();

    const userOpts = useSelector(state => state.setting.userOpts)
    const orgCode = useSelector(state => state.organization.orgCode);
    const cookies = new Cookies();
    
    return(
        <>
            {
                orgCode != "" ? (
                    <>
                        <div 
                            id="btn-aitutor-open-aiTutor"
                            className='header-color'
                            style={{ 
                                position:'fixed', 
                                bottom:'20px',
                                left:'20px', 
                                width:'50px',
                                height:'50px',
                                zIndex: 100,     
                                color: 'white',
                                fontSize: '30px',
                                cursor:'pointer',
                                borderRadius: '100px',
                                display: "flex", 
                                justifyContent: 'center',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                            }}
                            onClick={(e)=> {
                                const chatState = cookies.get('aichat-state')

                                if(typeof chatState == 'undefined') {
                                dispatch( setUiModalAiBox(true) );
                                dispatch( setUiShowAiBox(true) );

                                } else {

                                    /*
                                    if(chatState == 'modal') {
                                        dispatch( setUiSmallAiBox(false) )
                                        dispatch( setUiModalAiBox(true) );
                                        dispatch( setUiShowAiBox(true) );

                                    } else if(chatState == 'small') {
                                        dispatch( setUiSmallAiBox(true) )
                                        dispatch( setUiModalAiBox(false) );
                                        dispatch( setUiShowAiBox(true) );
                                    }
                                    */

                                    // mobile
                                    // if(window.innerWidth <= 800 && window.innerHeight <= 600) {
                                    if(window.innerWidth <= 768) {
                                        dispatch( setUiSmallAiBox(true) )
                                        dispatch( setUiModalAiBox(false) );
                                        dispatch( setUiShowAiBox(true) );
                                    } else { // PC / Tablet
                                        if(chatState == 'modal') {
                                            dispatch( setUiSmallAiBox(false) )
                                            dispatch( setUiModalAiBox(true) );
                                            dispatch( setUiShowAiBox(true) );
                    
                                        } else if(chatState == 'small') {
                                            dispatch( setUiSmallAiBox(true) )
                                            dispatch( setUiModalAiBox(false) );
                                            dispatch( setUiShowAiBox(true) );
                                        }
                                    }
                                }
                            }}
                        >
                            <TbMessageChatbot id='icon-aitutor-open-aiTutor'/>
                        </div>
                        {
                            !cookies.get('aichat-event-guide') && (
                                <MainGuideBubble />
                            )
                        }
                        {
                            (userOpts['TRANSLATE_TEXT_SELECTION'] == true) && (
                                <ToolTipContainer />
                            )
                        }
                    </>
                ) : (
                    <></>
                )
            }
        </>
    )
}


export default BtnCsmsAi;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import i18next from 'i18next';

import '../../../resources/assets/styles/sass/UiTranslateText.scss';
import { setTranslateTextOpenSourceLangList, setTranslateTextOpenTargetLangList, setTranslateTextSupportLang, setTranslateTextSupportTargetLang } from '../../modules/translateText';
import SourceSelectorContainer from '../../containers/translateText/SourceSelectorContainer';
import ChangeBtnContainer from '../../containers/translateText/ChangeBtnContainer';
import SourceBoxContainer from '../../containers/translateText/SourceBoxContainer';
import TargetSelectorContainer from '../../containers/translateText/TargetSelectorContainer';
import TargetBoxContainer from '../../containers/translateText/TargetBoxContainer';
import SearchContainer from '../../containers/translateText/SearchContainer';
import { deepLSupportLang, deepLTargetLang, googleSupportLang, papagoSupportLang } from '../_commons/LanguageList';
import { setTranslateSourceLang, setTranslateTargetLang } from '../../modules/translateDoc';

const UiTranslateText = () => {

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);

    const opts = useSelector(state => state.organization.opts); // SERVICE_TRANSLATOR - G: Google, P: Papago / Naver, O: OpenAI
    const userOpts = useSelector(state => state.setting.userOpts);

    const dispatch = useDispatch();
    
    useEffect(() => {
        // if(translateSupportLang.length == 0) {
            
        if(typeof opts['SERVICE_TRANSLATOR'] != 'undefined') {
            let list = [];
            switch(opts['SERVICE_TRANSLATOR']) {
                case "G":
                    Object.keys(googleSupportLang()).map((item, idx) => {
                        list[idx] = {
                            label: googleSupportLang()[item],
                            value: item
                        }
                    })

                    dispatch( setTranslateTextSupportLang( list ) );
                    dispatch( setTranslateTextSupportTargetLang( list.filter(item => item.value != "auto" ) ) );
                    break;
                
                case "P":

                    Object.keys(papagoSupportLang()).map((item, idx) => {
                        list[idx] = {
                            label: papagoSupportLang()[item],
                            value: item
                        }
                    })

                    dispatch( setTranslateTextSupportLang( list ) );
                    dispatch( setTranslateTextSupportTargetLang( list.filter(item => item.value != "auto") ) );
                    break;
                
                case "DL":
                    let deepLSupportList = [];

                    Object.keys(deepLSupportLang()).map((item, idx) => {
                        deepLSupportList[idx] = {
                            label: deepLSupportLang()[item],
                            value: item
                        }
                    })

                    dispatch( setTranslateTextSupportLang( deepLSupportList ) );

                    let deepLtargetList = [];
                    Object.keys(deepLTargetLang()).map((item, idx) => {
                        deepLtargetList[idx] = {
                            label: deepLTargetLang()[item],
                            value: item
                        }
                    })
                    dispatch( setTranslateTextSupportTargetLang(deepLtargetList) )
                    break;
            }
        }

        dispatch(setTranslateSourceLang({
            label: i18next.t('lang.auto'),
            value: 'auto'
        }))

        dispatch(setTranslateTargetLang({
            label: i18next.t('lang.ko'),
            value: 'ko'
        }))
    }, []);

    useEffect(() => {
        dispatch(setTranslateSourceLang({
            label: i18next.t('lang.auto'),
            value: 'auto'
        }))

        dispatch(setTranslateTargetLang({
            label: i18next.t('lang.ko'),
            value: 'ko'
        }))
    }, [userOpts])

    useEffect(() => {
        window.addEventListener('keydown', closeSearchBox);

        return () => {
            window.removeEventListener('keydown', closeSearchBox)
        }
    });

    const closeSearchBox = (e) => {
        if(e.keyCode == 27) {
            dispatch(setTranslateTextOpenTargetLangList(false));
            dispatch(setTranslateTextOpenSourceLangList(false));
        }
    }

    return (
        <>
            {
                uiSmallAiBox ? (
                    <>
                        <div className='box-select-lang'>
                            <SourceSelectorContainer />
                            <ChangeBtnContainer />
                            <TargetSelectorContainer />
                        </div>



                        <div className='box-inputZone'>
                            
                            <SourceBoxContainer />
                            <TargetBoxContainer />
                            <div className='cr'></div>
                        </div>

                        <SearchContainer
                            deepLTargetLang={ deepLTargetLang() }
                        />
                    </>
                ) : (
                    <div className='translate-text-container'>
                        <div className='content-center'>
                                {/* <Col className='col-6 pr-2 w-45'>
                                    <div className='translate-col-wrapper'>
                                        <div className='translate-title'>
                                            <SourceSelectorContainer />
                                            <ChangeBtnContainer />
                                        </div>
                                        <SourceBoxContainer/>
                                    </div>
                                </Col>

                                <Col className='col-6 pr-2 w-45'>
                                    <div className='translate-col-wrapper'>
                                        <div className='translate-title'>
                                            <TargetSelectorContainer />
                                        </div>
                                        <TargetBoxContainer />
                                    </div>
                                </Col> */}
                                <div className='w-45 pr-2'>
                                    <div className='translate-col-wrapper'>
                                        <div className='translate-title'>
                                            <SourceSelectorContainer />
                                            <ChangeBtnContainer />
                                        </div>
                                        <SourceBoxContainer/>
                                    </div>
                                </div>

                                <div className='w-45 pr-2'>
                                    <div className='translate-col-wrapper'>
                                        <div className='translate-title'>
                                            <TargetSelectorContainer />
                                        </div>
                                        <TargetBoxContainer />
                                    </div>
                                </div>
                            <SearchContainer 
                                deepLTargetLang={ deepLTargetLang() }
                            />
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default UiTranslateText;
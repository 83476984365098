import i18n from "i18next"
import {initReactI18next} from "react-i18next";
import { useSelector } from "react-redux";

import langEnGB from './en-gb.json'
import langEnUS from './en-us.json'
import langKo from './ko.json'
import langEs from './es.json'
import langZhCN from './zh-cn.json'
import langZhTW from './zh-tw.json'
import langJa from './ja.json'
import langVi from './vi.json'

const resource =  {
    enGB: {
        translation: langEnGB
    }, // 영어(영국)
    enUS: {
        translation: langEnUS
    }, // 영어(미국)
    ko: {
        translation: langKo
    },
    es: {
        translation: langEs
    },
    ja: {
        translation: langJa
    },
    zhCN: {
        translation: langZhCN
    }, // 중국어(간체)
    zhTW: {
        translation: langZhTW
    }, // 중국어(번체)
    vi: {
        translation: langVi
    }
};

i18n
    .use(initReactI18next)  // passes i18n down to react-i18next
    .init({
        resources: resource,
        lng: "ko",
        fallbackLng: 'ko',

        // ns: ['translation'],
        // defaultNS: "translation",
        debug: true,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
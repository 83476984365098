
import React, { useEffect, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { LuFolderUp } from 'react-icons/lu';
import axios from "axios";

import { setSummaryDocList, setSummaryDocStatus, setSummaryDocSelectedSpace, setSummaryDocProgress, setSummaryFileInfo } from "../../../modules/summaryDoc";
import { setLoadingSpace } from "../../../modules/loading";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

const UploadSummaryDoc = () => {
    
    const dispatch = useDispatch();

    const [userMessage, setUserMessage] = useState("");

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar);
    const summaryDocProgress = useSelector(state => state.summaryDoc.summaryDocProgress)
    const summaryFileInfo = useSelector(state => state.summaryDoc.summaryFileInfo);
    const summaryDocSelectedSpace = useSelector(state => state.summaryDoc.summaryDocSelectedSpace);

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const summaryDocList = useSelector(state => state.summaryDoc.summaryDocList);

    const opts = useSelector(state => state.organization.opts); // SERVICE_TRANSLATOR - G: Google, P: Papago / Naver, O: OpenAI

    const [summaryFileBinary, setSummaryFileBinary] = useState(false);
    const [newChatFlag, setNewChatFlag] = useState(false);
    const [uploadType, setUploadType] = useState({})
    const [checkUploadType, setCheckUploadType] = useState(false)
    const [checkUploadSize, setCheckUploadSize] = useState(false)

    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch( setSummaryDocProgress(false) );
        setUploadAcceptTypes()

        return () => {
            console.log('Clean Up Upload Space')
            // dispatch( setSummaryFileInfo({}) )
        }
    }, [])

    useEffect(() => {
        //dispatch( setAiChatSelectedSpace(newSpaceData) );
        console.log("newChatFlag : ", newChatFlag);
        console.log("summaryDocList : ", summaryDocList);
        
        if(newChatFlag){
            var newSpaceData = summaryDocList[0];
            console.log("newSpaceData : ", newSpaceData)
            dispatch(setSummaryDocSelectedSpace(newSpaceData));
            dispatch( setSummaryDocStatus("selected") );            
            dispatch( setLoadingSpace(true) );
            setNewChatFlag(false)
        }

        
    }, [summaryDocList])

    function getUploadFileInfo(acceptedFiles) {
        console.log(acceptedFiles[0])

        if( typeof acceptedFiles[0] !== "undefined" ) {
            
            var file = acceptedFiles[0];
            const reader = new FileReader(); // FileReader 객체 생성
            reader.readAsBinaryString(file);
            // 파일 로드가 완료되었을 때 이벤트 리스너를 등록합니다.
            reader.onload = function (loadEvent) { 
              const binaryData = loadEvent.target.result; // 파일의 이진 데이터 가져오기
              console.log("binaryData : ", binaryData); // 이진 데이터 출력 또는 원하는 처리 수행
              //setSummaryFileBinary(binaryData);

              getUploadUrl(file, binaryData);
            };
            
            //getUploadUrl( acceptedFiles[0] );
            
            //dispatch( setSummaryDocStatus("loading") );
        }
    }

    function getUploadUrl(summaryFileInfo, summaryFileBinary) {


        const fileExtension = (summaryFileInfo.name).split('.').pop();

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "token" : userToken,
            "ext" : fileExtension,
            // "realFileName": encodeURIComponent(summaryFileInfo.name)
            "realFileName": summaryFileInfo.name
        });

        var config = {
        method: 'post',
        url:  API_URL + '/' + API_VERSION + '/summaryDoc/getUploadUrl',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios(config)
        .then( (response) => {

            if( response.data.code == 100){
                const uploadUrl = response.data.upload_url;
                const uuid = response.data.uuid;
                const ext = fileExtension;

                const fileInfo = {
                    uploadUrl : uploadUrl,
                    uuid : uuid,
                    ext : ext,
                    label : summaryFileInfo.path,
                    type : summaryFileInfo.type
                }


                putUploadSummaryDoc(fileInfo, summaryFileInfo);
                // dispatch( setTranslateUploadInfo(fileInfo) ); // update file info
            }
        })
        .catch((error) => {
            console.log(error);
        }); // End Get FileUpload URL
    }

    function putUploadSummaryDoc(fileInfo, summaryFileBinary) {
        // fileInfo : uploadUrl, uuid, ext
        console.log("업로드 정보")
        //console.log(fileInfo, typeof fileInfo);
        
        
        if(fileInfo['uuid'] != "") {
            console.log(summaryFileBinary)
            
            const config = {
                method: 'PUT',
                maxBodyLength: Infinity,
                url: fileInfo['uploadUrl'],
                headers: {
                    'Content-Type': fileInfo.type
                },
                data: summaryFileBinary,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`업로드 진행 상황: ${percentCompleted}%`);
                    dispatch( setSummaryDocProgress(percentCompleted) );
                }
            };

            console.log("config : ", config);
            
            axios(config)
            .then((response) => {
                console.log(response);

                // success
                if(response.status == 200) {
                    
                    /*
                        appType: "summaryDoc"
                        created_at: 1688491820
                        ext: "pdf"
                        label: "BofA.pdf"
                        orgUser_id: "196#2"
                        space_id: "c92b4105-7488-4724-b64f-bc7535eeec5f"
                        state: 100
                        updated_at: 1688491820
                    */
                    setNewChatFlag(true)  
                    var tmpSpaceList = summaryDocList;
                    var newSpace = {
                        appType: "summaryDoc",
                        ext: fileInfo.ext,
                        label: fileInfo.label,
                        space_id : fileInfo.uuid,
                        state: 200
                    }
                    
                    
                    tmpSpaceList.unshift(newSpace);
                    tmpSpaceList = [...tmpSpaceList];
                    console.log("tmpSpaceList : ", tmpSpaceList);
                    console.log("newSpace : ", newSpace);
                    dispatch( setSummaryDocList(tmpSpaceList) )

                    //getSummaryDocList();
                    //dispatch(setSummaryDocStatus("summary")); // 문서 요약 요청
                }
            })
            .catch((error) => {
                console.log(error);
                //dispatch( setLoadingTranslateDoc(false) );
            })
            
            // End PUT FileUpload
        }
    }

    function setUploadAcceptTypes() {
        let type = {};

        if(IS_DEV) {
            type = {
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.csv'],
                
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],

                'text/plain': ['.txt'],
                
                'application/vnd.hancom.hwp': ['.hwp'],
                'application/haansofthwp': ['.hwp'],
                'application/x-hwp': ['.hwp'],
                
                'application/pdf': ['.pdf'],
                'application/epub+zip': ['.epub'],
            }
        } else {
            type = {
                /*
                'text/csv': ['.csv'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'text/plain': ['.txt'],
                'application/pdf': ['.pdf'],
                'application/epub+zip': ['.epub']
                */
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.csv'],
                
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],

                'text/plain': ['.txt'],
                
                'application/vnd.hancom.hwp': ['.hwp'],
                'application/haansofthwp': ['.hwp'],
                'application/x-hwp': ['.hwp'],
                
                'application/pdf': ['.pdf'],
                'application/epub+zip': ['.epub'],                       
            }
        }

        setUploadType(type)
        console.log('current support type', type)
        // return type;
    }

    function DocTypeText() {

        if(IS_DEV) {
            return (
                <div className='sub-title'>
                    pdf, docx, txt, epub, csv, hwp { t("summaryDoc.upload_text") }
                </div>
            )
        } else {
            switch(opts['SERVICE_TRANSLATOR']) {
                case "P":
                    return (
                        <div className='sub-title'>docx, pptx, xlsx, hwp { t("summaryDoc.upload_text") }</div>
                    )
    
                case "G":
                    return (
                        <div className='sub-title'>docx, pdf, pptx, xlsx { t("summaryDoc.upload_text") }</div>
                    )
                
                case "DL":
                    return (
                        <div className='sub-title'>
                            pdf, docx, txt, epub, csv, hwp { t("summaryDoc.upload_text") }
                        </div>
                    )
    
                default:
                    return (
                        <div className='sub-title'>pdf { t("summaryDoc.upload_text") }</div>
                    )
            }
        }
    }

    function DocSizeText() {
        switch(opts['SERVICE_TRANSLATOR']) {
            case "DL":
                return (
                    <div className='sub-title'>{ t("translateDoc.doc_size", { size: 30 }) }</div>
                )

            case "P":
                return (
                    <div className='sub-title'>{ t("translateDoc.doc_size_text", { size: 50, length: 30 }) }</div>
                )

            case "G":
                return (
                    <div className='sub-title'>{ t("translateDoc.doc_size_text", { size: 50, length: 30 }) }</div>
                )

            default:
                return (
                    <div className='sub-title'>{ t("translateDoc.doc_size_text", { size: 50, length: 30 }) }</div>
                )
        }
    }
    // opts에 따른 maxSize 변경

    function fileValidator(file: DataTransferItem) {

        console.log(file.kind);
        console.log(file.type)

        setCheckUploadSize(false)
        setCheckUploadType(false)

        if(typeof file.size != 'undefined') {
            if(file.size > 30000000) {
                dispatch( setSummaryFileInfo(false) );
                setCheckUploadSize(true)
            }
        }

        if(typeof file.type != 'undefined') {

            const fileType = Object.keys(uploadType).includes(file.type);

            if(fileType == false) {
                dispatch( setSummaryFileInfo(false) );
                setCheckUploadType(true);
            }
            // !fileType && setCheckUploadType(true)
        }
    }

    function handleRejectedFile(file: Array<FileRejection>) {
        setCheckUploadSize(false)
        setCheckUploadType(false)

        console.log(file)
        console.log(file[0].file.name)

        const errorName = file[0].errors[0].code

        console.log(errorName, " : errorName")

        switch(errorName) { 
            case "file-too-large" : 
                dispatch(setSummaryFileInfo(false))
                setCheckUploadSize(true)
                break;

            case "file-invalid-type" : 
                dispatch(setSummaryFileInfo(false))
                setCheckUploadType(true)
                break;
        }
    }

    return (
        <Dropzone onDrop={acceptedFiles => getUploadFileInfo(acceptedFiles)} 
                                           multiple={ false } 
                                           accept={ uploadType }  
                                           maxFiles={ 1 } 
                                           maxSize={ 50000000 } 
                                           // validator={ fileValidator } 
                                           noKeyboard={ true }
                                           onDropRejected={ handleRejectedFile }
        >
            {({getRootProps, getInputProps}) => (
                <div id="doc-upload-container" className='fr' style={ ( !uiSmallAiBox && uiHideSidebar ) ? { width: '100%' } : {} }>
                    <div className='doc-box-wrapper' {...getRootProps()}>
                        <div className='file-container'>
                            <section>
                                <input {...getInputProps()} />
                                <div className='position-relative'>
                                    <div>
                                        <div><LuFolderUp className='doc-upload-icon' /></div>
                                    </div>
                                    <div>
                                        <div className='pt-2 pb-2 text-title'>
                                            <Trans
                                                i18nKey={ 'translateDoc.add_pc_doc' }
                                                components={{
                                                    row: <div />,
                                                    color: <span className='ai-font-color'></span>,
                                                    text: <span></span>
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <DocTypeText />
                                    <div>
                                        <DocSizeText />
                                    </div>
                                    <div className='over-size-input w-100 font-weight-bold pt-2'>
                                        {
                                            !summaryFileInfo && (
                                                <>
                                                    {
                                                        checkUploadSize && (
                                                            <div>{ t("translateDoc.error_size") }</div>
                                                        )
                                                    }

                                                    {
                                                        checkUploadType && (
                                                            <div>{ t("translateDoc.error_type") }</div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                    {
                                        summaryDocProgress != false && (
                                            // <div>
                                            //     <div className='sub-title position-absolute left-0 right-0'>
                                            //         { summaryDocProgress + '%' }
                                            //     </div>
                                            // </div>
                                            <div className='position-absolute w-100 pt-3'>
                                                <ProgressBar className="ml-3 mr-3" label={`${ summaryDocProgress }%`} now={ summaryDocProgress } />
                                            </div>
                                        )
                                    }


                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="cr"></div>
                </div>
            )}
        </Dropzone>
    )
}

export default UploadSummaryDoc;
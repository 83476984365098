import React, { useEffect, useState} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { FoldingCube } from 'better-react-spinkit'
import { setSelectedDocInfo, setSelectedDocTranslatedList} from "../../../modules/translateDoc";

import { setLoadingDocState } from "../../../modules/loading";
import { useTranslation } from "react-i18next";

const TranslateDocLoading = () => {

    const dispatch = useDispatch();

    const [docState, setDocState] = useState(100); // default

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    //const [promptList, setPromptList] = useState([]);
    const summaryDocList = useSelector(state => state.summaryDoc.summaryDocList);

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);

    const selectedDocInfo = useSelector(state => state.translateDoc.selectedDocInfo);
    //const stateList = useSelector(state => state.translateDoc.selectedDocInfo.stateList);
    const selectedDocTranslatedList = useSelector(state => state.translateDoc.selectedDocTranslatedList);

    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        console.log("TranslateDoc getState : ", selectedDocTranslatedList);
        if(selectedDocTranslatedList.length > 0){
            setTimeout(function(){
                dispatch( setLoadingDocState(false) );
            }, 2000);
            
        }else{
            dispatch( setLoadingDocState(true) );
            getTranslateDocState()
            var intervalNo =  setInterval(function(){
                                    getTranslateDocState()
                                },5000);
        }
        return () => {
            clearInterval(intervalNo);
        };

    }, [selectedDocTranslatedList]);
  


    function getTranslateDocState() {
        dispatch( setLoadingDocState(true) );
    
        // getState Test
        var data = JSON.stringify({
            "orgCode": orgCode,
            "token": userToken,
            "uuid": selectedDocInfo.space_id, // uuid, space_id
        });

        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/translator/doc/getState',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data, 
        };
  
        axios(config)
        .then( (response) => {
            console.log(response);

            if( response.data.code == 100){
                //const item = selectedDocInfo
                dispatch(setSelectedDocTranslatedList(response.data.data));
                //item.stateList = response.data.data
                //console.log(item, 'Upload Get State');
                //dispatch( setSelectedDocInfo(item) );
                //dispatch( setLoadingDocState(false) );

            } else if(response.data.code == 200) {

                if(typeof response.data.spaceInfo != 'undefined') {
                    dispatch(setSelectedDocInfo(response.data.spaceInfo));
                    setDocState(response.data.spaceInfo.state)
                } // Render State Text

                // error
                console.log(response.data.message);
                //dispatch( setLoadingDocState(false) );
            }
        })
        .catch((error) => {
            console.log(error);
            //dispatch( setLoadingDocState(false) );
        });
    }

    function RenderStateText() {
        switch(docState) {
            case 200:
                return <>{ t("translateDoc.state_200") }</>
            
            case 201:
                return <>{ t("translateDoc.state_201") }</>

            case 202:
                return <>{ t("translateDoc.state_202") }</>

            case 203:
                return <>{ t("translateDoc.state_203") }</>

            default:
                return <>{ t("translateDoc.state_default") }</>
        }
    }

    return (
        <>
            <div className={ uiSmallAiBox ? "small-doc-loading" : "modal-loading" }>
                <div>
                    <div className='content-center'>
                        <FoldingCube size={30} color="#C0C0C0" />
                    </div>
                    <div>
                        <RenderStateText />
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default TranslateDocLoading
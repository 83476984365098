import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { PiArrowsCounterClockwiseFill } from 'react-icons/pi'
import { setIcon } from "../../modules/tooltip";

const ToolTipIcon = ( props ) => {

    const dispatch = useDispatch();

    const {
        setTranslateText
    } = props

    /*
    const elementPosition = window.getSelection()?.getRangeAt(0).getBoundingClientRect(); // selected position
    console.log(elementPosition);
    */

    const tooltipIcon = useSelector(state => state.tooltip.icon);
    const tooltipPosition = useSelector(state => state.tooltip.position);
    const translateSelection = useSelector(state => state.tooltip.translateSelection)
    const opts = useSelector(state => state.organization.opts);

    const clickTooltipIcon = () => {

        dispatch(setIcon(false))
        const selectedText = window.getSelection()?.toString();

        let sourceLang = document.querySelector('html')?.getAttribute('lang');
        let targetLang = "";
        if(sourceLang == 'ko') {

            if(opts['SERVICE_TRANSLATOR'] == "DL") {
                targetLang = 'en-gb'
            } else {
                targetLang = 'en'
            }
        } else {
            targetLang = 'ko'
        }

        if(translateSelection) {
            setTranslateText(selectedText, sourceLang, targetLang, 'change');
        } else {
            setTranslateText(selectedText, sourceLang, targetLang, 'init');
        }
    }


    /*
    useEffect(() => {
        if(showIcon) {
            if(window.getSelection()?.toString() != '') {
                // const elementPosition = window.getSelection()?.getRangeAt(0)?.getBoundingClientRect(); // selected position

                setIconPosition({
                    top: mousePosition.y + 'px',
                    left: mousePosition.x + 'px'
                })
            }
        }
        
    }, [showIcon])
    */

    /*
    useEffect(() => {

        console.log('tooltip mouse up event')

        function handleMouseUp(e: MouseEvent) {
            const text = window.getSelection()?.toString().trim()
            
            
            setMousePosition({
                x: e.pageX,
                y: e.pageY
            })
            

            setTimeout(() => {
                if ( !window.getSelection()?.isCollapsed && window.getSelection()?.type == 'Range' ) {

                    dispatch(setPosition({
                        x: e.pageX,
                        y: e.pageY
                    }));

                    dispatch(setText(text)) // tooltip text
                    // setShowIcon(true) // tooltip icon location change
                    dispatch(setIcon(true))
                } else {
                    // setShowIcon(false)
                }
            }, 10)
        }

        window.addEventListener('mouseup', handleMouseUp)

        return () => {
            console.log('Clean Up Tooltip MouseUp Event')
            window.removeEventListener('mouseup', handleMouseUp)
        }

    }, [])

    useEffect(() => {

        console.log('tooltip mouse down event')

        function handleMouseDown(e) {
            console.log(e.target.id)
            // mouse down remove text
            const text = window.getSelection()?.toString().trim()
            console.log(text)
            dispatch(setText(""))
            dispatch(setIcon(false))
            dispatch(setPosition({
                x: 0,
                y: 0
            }))
        }

        window.addEventListener('mousedown', handleMouseDown)

        return () => {
            console.log('Clean Up Tooltip MouseDown Event')
            window.removeEventListener('mousedown', handleMouseDown)
        }

    }, [])
    */

    return (
        <>
            {/* {
                showIcon && (

                    <div
                        id="btn-tooltip-icon"
                        className="tooltip-icon-wrapper"
                        style={{
                            top: iconPosition.top,
                            left: iconPosition.left,
                        }}
                        onMouseDown={clickTooltipIcon}
                    >
                        <PiArrowsCounterClockwiseFill
                            size={ 20 }
                            color="#0075F8"
                            className="tooltip-start-icon"
                        />
                    </div>
                )
            } */}

            {
                tooltipIcon && (

                    <div
                        id="btn-aitutor-tooltip"
                        className="tooltip-icon-wrapper"
                        style={{
                            top: tooltipPosition.y,
                            left: tooltipPosition.x,
                        }}
                        onMouseDown={clickTooltipIcon}
                    >
                        <PiArrowsCounterClockwiseFill
                            id="icon-aitutor-tooltip"
                            size={ 20 }
                            color="#0075F8"
                            className="tooltip-start-icon"
                        />
                    </div>
                )
            }
            
                
           
            {/* {
                tooltipIcon && (

                    <div
                        id="btn-tooltip-icon"
                        className="tooltip-icon-wrapper"
                        style={{
                            
                            // top: iconPosition.top,
                            // left: iconPosition.left,
                            
                            top: tooltipPosition.y + 'px',
                            left: tooltipPosition.x + 'px',
                        }}
                        onMouseDown={clickTooltipIcon}
                    >
                        <PiArrowsCounterClockwiseFill
                            size={ 20 }
                            color="#0075F8"
                            className="tooltip-start-icon"
                        />
                    </div>
                )
            } */}
        </>
    )
}

export default ToolTipIcon
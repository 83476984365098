import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "izitoast-react/dist/iziToast.css";

import TranslateSelectionLoading from "../components/_commons/TranslateSelectionLoading";
import { setLoadingTooltipChange, setLoadingTranslateSelection } from "../modules/loading";
import { ToolTipModal, ToolTipIcon } from "../components/_commons";
import { setIcon, setModalPosition, setOptions, setPosition, setSourceLang, setSourceText, setTargetText, setText, setTranslateSelection } from "../modules/tooltip";
import { deepLTargetLang, googleSupportLang, papagoSupportLang } from "../components/_commons/LanguageList";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ToolTipContainer = () => {

    const dispatch = useDispatch();

    const opts = useSelector(state => state.organization.opts);
    const userOpts = useSelector(state => state.setting.userOpts)
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);

    const textData = useSelector(state => state.tooltip.text);
    const translateSelection = useSelector(state => state.tooltip.translateSelection)

    const { t, i18n } = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(setTranslateSelection(false))
            dispatch(setText(''))
        }
    }, [])

    useEffect(() => {
        if( typeof opts['SERVICE_TRANSLATOR'] != 'undefined' ) {
            type ListType = {
                label: string,
                value: string
            }

            let list: Array<ListType> = [];

            if(opts['SERVICE_TRANSLATOR'] == "P") {
                Object.keys(papagoSupportLang()).map((item, idx) => {
                    list[idx] = {
                        label: papagoSupportLang()[item],
                        value: item
                    }
                })

            } else if(opts['SERVICE_TRANSLATOR'] == "G") {
                Object.keys(googleSupportLang()).map((item, idx) => {
                    list[idx] = {
                        label: googleSupportLang()[item],
                        value: item
                    }
                })

            } else if(opts['SERVICE_TRANSLATOR'] == "DL") {
                Object.keys(deepLTargetLang()).map((item, idx) => {
                    list[idx] = {
                        label: deepLTargetLang()[item],
                        value: item
                    }
                })
            }
            dispatch(setOptions(list));
        }
    }, [opts, userOpts, i18next.language])

    useEffect(() => {
        function selectText(e) {

            console.log(window.getSelection())

            dispatch(setPosition({
                x: e.pageX,
                y: e.pageY
            }));

            setTimeout(() => {
                const dragData = window.getSelection()?.toString();

                // if (dragData?.trim() != '' && !window.getSelection()?.isCollapsed && window.getSelection()?.type == 'Range' ) {
                if ( !window.getSelection()?.isCollapsed && window.getSelection()?.type == 'Range' && dragData?.trim() != '' ) {
                    // setTextData(dragData); // dragged text

                    dispatch(setText(dragData))
                    dispatch(setIcon(true))

                } else {
                    
                    dispatch(setIcon(false))
                }
            }, 10)
        }

        window.addEventListener('mouseup', selectText);

        return () => {
            console.log('clean up Window MouseUp Event function')
            window.removeEventListener('mouseup', selectText);
        }
    }, [])

    useEffect(() => {
        function checkText(e: MouseEvent) {
            const dragData = window.getSelection()?.toString().trim();
            const mouseButton = e.button;

            if(dragData != '') {
                if(mouseButton == 0) {
                    window.getSelection()?.empty()
                    // setTextData('');

                    dispatch(setText(''))
                    dispatch(setIcon(false))
                }
            }
        }

        window.addEventListener('mousedown', checkText);

        return () => {
            console.log('clean up MouseDown Event function');
            window.removeEventListener('mousedown', checkText)
        }
    }, []) // remove selection text data

    useEffect(() => {
        function clickTranslateBtn(e) {
            if(textData != '') {

                if(e.keyCode == 84 && e.shiftKey) {
                    console.log("START TRANSLATE!!!")
                    
                    console.log(window.getSelection()?.toString(), ' tooltip text')

                    // setTranslateSelection(false);
                    // const selectedText = window.getSelection()?.toString();

                    let sourceLang = document.querySelector('html')?.getAttribute('lang');
                    let targetLang = "";
                    
                    if(sourceLang == 'ko') {

                        console.log(opts, 'opts')
                        if(opts['SERVICE_TRANSLATOR'] == "DL") {
                            targetLang = "en-gb"
                        } else {
                            targetLang = 'en'
                        }
                        
                    } else {
                        sourceLang = 'en-gb';
                        targetLang = 'ko'
                    }

                    if(translateSelection) {
                        setTranslateText(textData, sourceLang, targetLang, 'change');
                    } else {
                        setTranslateText(textData, sourceLang, targetLang, 'init');
                    }
                }
            }
        }

        window.addEventListener('keydown', clickTranslateBtn)

        return () => {
            window.removeEventListener('keydown', clickTranslateBtn)
        }
    }, [textData]);

    function setTranslateText(item, sourceLang, targetLang, type) {
        console.log("item: ", item)
        console.log("sourceLang: ", sourceLang);
        console.log("targetLang: " ,targetLang)
        console.log("type: " ,type)

        if(window.getSelection()?.toString() != '') {
            const elementPosition = window.getSelection()?.getRangeAt(0)?.getBoundingClientRect(); // selected position

            const tooltipWidth = 550;
            let top = elementPosition?.top + window.scrollY + 24;
            // let top = elementPosition?.top;
            let left = elementPosition?.left

            if( !(left < tooltipWidth) ) {
                left = left - tooltipWidth + 24
            }

            /*
                if(top + 100 < document.body.clientHeight) {
                    top = top - 300
                }
            */

            dispatch(setModalPosition({
                x: top,
                y: left
            }))
        }

        if(type == 'init') {
            dispatch( setLoadingTranslateSelection(true) );
        } else {
            dispatch( setLoadingTooltipChange(true) )
        }
        
        if(sourceLang == targetLang) {
            if(sourceLang == 'ko') {

                if(opts['SERVICE_TRANSLATOR'] == "DL") {
                    targetLang = "en-gb"
                } else {
                    targetLang = 'en'
                }
                
            } else {
                targetLang == 'ko'
            }
        }

        if(opts['SERVICE_TRANSLATOR'] == "DL") {
            sourceLang = 'auto'
        }

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "token" : userToken,
            "sourceLang": sourceLang,
            "targetLang": targetLang != "en" ? targetLang : "en-gb",
            "html" : false,
            "text": item,
        });

        console.log(JSON.parse(data),' 번역할 데이터')

        if(data.text != "") {
            var config = {
                method: 'post',
                url:  API_URL + '/' + API_VERSION + '/translator/text',
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : data
              };
      
              axios(config)
              .then( (response) => {
                  if( response.data.code == 100) {
      
                      let translatedText = "";
      
                      if(opts['SERVICE_TRANSLATOR'] == "DL") {
                          translatedText = response.data.data;
                      } else {
                          translatedText = response.data.data.translatedText;
                      }
      
                      console.log(response.data)
      
                      if(type == 'init') {
                          dispatch( setLoadingTranslateSelection(false) );
                      } else {
                          dispatch( setLoadingTooltipChange(false) )
                      }
                      
                      
      
                      /*
                      setTranslateSelection(true);
                      setTooltipSourceLang(sourceLang)
                      setTooltipSourceText(item);
                      setTooltipTargetText(translatedText);
                      */

                      dispatch(setTranslateSelection(true))
                      dispatch(setSourceLang(sourceLang))
                      dispatch(setSourceText(item))
                      dispatch(setTargetText(translatedText))
                  }
              })
              .catch((error) => {
                  console.log(error);
      
                  if(type == 'init') {
                      dispatch( setLoadingTranslateSelection(false) );
                  } else {
                      dispatch( setLoadingTooltipChange(false) )
                  }
                  
              });
        }
    }

    return (
        <>
            <TranslateSelectionLoading />
            <ToolTipModal 
                setTranslateText={ setTranslateText }

            />
            <ToolTipIcon
                setTranslateText={ setTranslateText }
            />
        </>
    )
}

export default ToolTipContainer
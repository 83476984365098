import React, { useCallback, useEffect, useState } from "react"
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Button, ProgressBar, InputGroup } from 'react-bootstrap'

import { ThreeBounce } from 'better-react-spinkit';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'
import { BsBook } from 'react-icons/bs';
import { RiArrowDownSLine } from 'react-icons/ri'
import { AiOutlineSearch, AiOutlineCheck } from 'react-icons/ai'
import { setSelectedDocInfo, setTranslateDocInfo, setTranslateStatus, setTranslateDocList, setSelectedDocTranslatedList, setTranslateDocProgress} from "../../../modules/translateDoc";
import { setLoadingDocState, setLoadingTranslateDoc } from "../../../modules/loading";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";
import { deepLSupportLang, deepLTargetLang, googleSupportLang, papagoSupportLang } from "../../_commons/LanguageList";

const SelectDocLang = () => {

    const dispatch = useDispatch();

    const [openSourceLangList, setOpenSourceLangList] = useState(false);
    const [openTargetLangList, setOpenTargetLangList] = useState(false);
    const [searchLangList, setSearchLangList] = useState([{
        label: "",
        value: "",
    }]);

    const [searchText, setSearchText] = useState("")

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar);
    const translateDocInfo = useSelector(state => state.translateDoc.translateDocInfo);
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const opts = useSelector(state => state.organization.opts);
    const selectedDocInfo = useSelector(state => state.translateDoc.selectedDocInfo);
    const translateDocList = useSelector(state => state.translateDoc.translateDocList);
    const translateDocProgress = useSelector(state => state.translateDoc.translateDocProgress);

    const loadingTranslateDoc = useSelector(state => state.loading.loadingTranslateDoc);


    const [sourceLang, setSourceLang] = useState("");
    const [targetLang, setTargetLang] = useState([{
        label: "",
        value: "",
    }]);
    const [checkSourceLang, setCheckSourceLang] = useState({
        label: "",
        value: "",
    });
    const [checkTargetLang, setCheckTargetLang] = useState([]);
    const [checkTargetLabel, setCheckTargetLabel] = useState([]);

    const [supportLang, setSupportLang] = useState([{ value: "", label: "" }]);

    const { t, i18n } = useTranslation();

    useEffect(() => {

        let list = [];

        switch(opts['SERVICE_TRANSLATOR']) {
            case "P":
                Object.keys(papagoSupportLang()()).map((item, idx) => {
                    list[idx] = {
                        label: papagoSupportLang()()[item],
                        value: item
                    }
                })
                break;
            case "G":
                Object.keys(googleSupportLang()).map((item, idx) => {
                    list[idx] = {
                        label: googleSupportLang()[item],
                        value: item
                    }
                })
                break;
            case "DL":
                Object.keys(deepLSupportLang()).map((item, idx) => {
                    list[idx] = {
                        label: deepLSupportLang()[item],
                        value: item
                    }
                })
                break;
            default:
                break;
        }

        setSupportLang(list)

        /*
        if(opts['SERVICE_TRANSLATOR'] == "P") {
            setSupportLang(papagoSupportLang())

        } else if(opts['SERVICE_TRANSLATOR'] == "G") {
            setSupportLang(googleSupportLang);

        } else if(opts['SERVICE_TRANSLATOR'] == "DL") {
            setSupportLang(deepLSupportLang);
        }
        */
 
        return () => {
            console.log('CleanUp Select Translate Doc Lang')
            // dispatch( setTranslateDocInfo({}) )
        }
    }, [])
    
    function getUploadUrl() {

        if(checkSourceLang.value == "") {
            alert( t("translateDoc.select_source") );
        } else if(checkTargetLang.length == 0) {
            alert( t("translateDoc.select_target") );
        } else {
            dispatch( setLoadingTranslateDoc(true) );
            const fileExtension = (translateDocInfo.name).split('.').pop();

            var data = JSON.stringify({
                "orgCode" : orgCode,
                "token" : userToken,
                "ext" : fileExtension,
                // "realFileName": translateDocInfo.name
                "realFileName": encodeURIComponent(translateDocInfo.name)
            });

            var config = {
            method: 'post',
            url:  API_URL + '/' + API_VERSION + '/translator/doc/getUploadUrl',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };
    
            axios(config)
            .then( (response) => {
                console.log(response.data)

                if( response.data.code == 100){
                    const uploadUrl = response.data.upload_url;
                    const uuid = response.data.uuid;
                    const ext = fileExtension;

                    const fileInfo: object = {
                        uploadUrl,
                        uuid,
                        ext
                    }

                    putUploadDoc(fileInfo);
                    // dispatch( setTranslateUploadInfo(fileInfo) ); // update file info
                }
            })
            .catch((error) => {
                console.log(error);
            }); // End Get FileUpload URL
        }
    } // End getUploadUrl Function

    const changeSearchLang = (event) => {

        // 원본, 번역 언어 선택했을때 검색 가능
        if(openSourceLangList) {
            setSearchText(event.target.value)

            const data = supportLang.filter(item => (item.label).includes(event.target.value));
            setSearchLangList(data)

        } else {
            setSearchText(event.target.value)

            const data = targetLang.filter(item => (item.label).includes(event.target.value))
            setSearchLangList(data);
        }
    };

    // 원본 언어 변경
    function handleCheckSourceLang(event) {

        if(!loadingTranslateDoc) {
            const label = event.target.dataset['label'];
            const value = event.target.dataset['value'];

            if(typeof label != 'undefined' && typeof value != 'undefined') {
                if(opts['SERVICE_TRANSLATOR'] == "P") {
                    if(value == "ko") {
                        setTargetLang([
                            {
                                label: t('lang.en'),
                                value: "en",
                            },
                            {
                                label: t('lang.ja'),
                                value: "ja",
                            },
                            {
                                label: t('lang.zh-cn'),
                                value : "zh-CN",
                            }
                        ]);
                    } else {
                        setTargetLang([
                            {
                                label: t('lang.ko'),
                                value: "ko",
                            }
                        ]);
                    }
                } else if(opts['SERVICE_TRANSLATOR'] == "G") {
                    const filterdLang = supportLang.filter(item => item.value != value)
                    setTargetLang(filterdLang);
                } else if(opts['SERVICE_TRANSLATOR'] == "DL") {

                    let list = [];

                    Object.keys(deepLTargetLang()).map((item, idx) => {
                        list[idx] = {
                            label: deepLTargetLang()[item],
                            value: item
                        }
                    })

                    const filterdLang = list.filter(item => item.value != value)
                    setTargetLang(filterdLang);
                }
    
                setCheckSourceLang({
                    label: label,
                    value: value
                });
            }
        }
    }

    // 번역 언어 변경
    function handleCheckTargetLang(event) {

       if(!loadingTranslateDoc) {
            const checkLabel = event.target.dataset['label'];
            const checkValue = event.target.dataset['value'];

            if(typeof checkLabel != 'undefined' && typeof checkValue != 'undefined') {
                console.log(checkLabel, checkValue);

                const data = targetLang;


                
                const checked = checkTargetLang.filter((item) => item == checkValue);
                console.log(checked[0]);

                console.log(checkTargetLang, checked);

                if(checked.length == 0) {
                    setCheckTargetLang([...checkTargetLang, checkValue]);
                    setCheckTargetLabel([...checkTargetLabel, checkLabel]);
                } else {
                    setCheckTargetLang(
                        checkTargetLang.filter((item) => item != checkValue)
                    )
                    setCheckTargetLabel(
                        checkTargetLabel.filter((item) => item != checkLabel)
                    )
                }
            }
       }
    }

    // translate doc - Start Translate Doc
    function setTranslateDoc(fileInfo) {

        let data = "";

        if( opts['SERVICE_TRANSLATOR'] == "DL" ) {

            const targetLangs = checkTargetLang.map( (value) => {
                return value;
            })

            data = JSON.stringify({
                "orgCode": orgCode,
                "token": userToken,
                "realFileName": translateDocInfo.name,
                "uuid": fileInfo['uuid'],
                "ext": fileInfo['ext'],
                "sourceLang": checkSourceLang.value,
                "targetLangs": targetLangs
                // "targetLangs": checkTargetLang,
            });

        } else {
            data = JSON.stringify({
                "orgCode": orgCode,
                "token": userToken,
                "realFileName": translateDocInfo.name,
                "uuid": fileInfo['uuid'],
                "ext": fileInfo['ext'],
                "sourceLang": checkSourceLang.value,
                "targetLangs": checkTargetLang,
            });
        }

        

        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/translator/doc/setTranslate',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            console.log(response);
            if( response.data.code == 100){
                // setTranslateRes(response.data.data); // Translate Doc Result
                dispatch( setSelectedDocInfo({
                    label: translateDocInfo.name,
                    uuid: fileInfo['uuid'],
                    ext: fileInfo['ext'],
                    space_id: fileInfo['uuid'],
                    stateList: response.data.data, // translateStateList
                    uploadType: "uploadDoc"
                }) );

                /*
                    appType : "translateDoc"
                    created_at : 1694155347
                    ext : "pdf"
                    label : "20230710.pdf"
                    orgUser_id : "196#2"
                    space_id : "d764a938-d038-4309-8401-3a0c9bc56092"
                    state : 200
                    updated_at : 1694155347
                */
                var newTranslateObject = {
                    appType: "translateDoc",
                    ext: fileInfo['ext'],
                    label: translateDocInfo.name,
                    uuid: fileInfo['uuid'],
                    space_id: fileInfo['uuid'],
                }
                var tmpTranslateDocList = translateDocList;
                tmpTranslateDocList.unshift(newTranslateObject)
                tmpTranslateDocList = [...tmpTranslateDocList]


                console.log("tmpTranslateDocList : ", tmpTranslateDocList);
                dispatch( setTranslateDocList(tmpTranslateDocList) );
                dispatch( setSelectedDocTranslatedList([]) );
                dispatch( setTranslateStatus("status") );
                dispatch( setLoadingTranslateDoc(false) );
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch( setLoadingTranslateDoc(false) );
        });
    }

    function putUploadDoc(fileInfo) {
        dispatch(setTranslateDocProgress(0))
        // fileInfo : uploadUrl, uuid, ext
        console.log("업로드 정보")
        console.log(fileInfo, typeof fileInfo);
        
        if(fileInfo['uuid'] != "") {
            const config = {
                method: 'PUT',
                url: fileInfo['uploadUrl'],
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: translateDocInfo,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`업로드 진행 상황: ${percentCompleted}%`);
                    dispatch(setTranslateDocProgress(percentCompleted));
                }
            };
    
            console.log(config);
    
            axios(config)
            .then((response) => {
                console.log(response);
    
                // success
                if(response.status == 200) {

                    setTranslateDoc(fileInfo); // 문서 번역 요청
                    // dispatch( setTranslateStatus("status") );
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch( setLoadingTranslateDoc(false) );
            })
            // End PUT FileUpload
        }
    }

    return (
        <>
            {
                uiSmallAiBox ? ( // RenderSmallView
                    <div id='doc-select-language'>

                        <div className="doc-info-top">
                            <div className='pb-2'>
                                <HiOutlineClipboardDocumentList className="book-icon" />
                            </div>
                            <div className="info-text text-dark">{ t("translateDoc.file_name") } : { translateDocInfo.name }</div>
                            <div className="info-text">{ t("translateDoc.file_size") } : { ((translateDocInfo.size) / (1024 * 1024)).toFixed(2) + "MB" }</div>
                            {/* <ProgressBar className="progress-bar-container" label={`${ translateDocProgress }%`} now={ translateDocProgress } /> */}
                            {
                                translateDocProgress != false && (
                                    <>
                                        <ProgressBar className="progress-bar-container" label={`${ translateDocProgress }%`} now={ translateDocProgress } />
                                        {/* <div className="info-progress info-text">
                                            { translateDocProgress + '%' }
                                        </div> */}
                                    </>
                                )
                            }
                        </div>

                        <Row className="ml-0 mr-0 lang-list-title">
                            <Col xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 } xl={ 4 } className="cursor-pointer content-center mt-2 mb-2"  onClick={() => { setOpenSourceLangList(true); setOpenTargetLangList(false); setSearchLangList([]); setSearchText(""); }}>
                                <div className="selected-lang-text">
                                    {
                                        checkSourceLang.value == "" ? (
                                            <>
                                                { t("translateDoc.select_lang") }
                                            </>
                                        ) : (
                                            <>
                                                { checkSourceLang.label }
                                            </>
                                        )
                                    }
                                    <RiArrowDownSLine />
                                </div>
                            </ Col>

                            <Col xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 } xl={ 4 } className="cursor-pointer content-center mt-2 mb-2" onClick={() => { setOpenSourceLangList(false); setOpenTargetLangList(true); setSearchLangList([]); setSearchText("") }}>
                                <div className="selected-lang-text">
                                    {
                                        checkTargetLang.length == 0 ? (
                                            <>
                                                { t("translateDoc.select_lang") }
                                            </>
                                        ) : (
                                            checkTargetLabel.map((item, idx) => {
                                                return (
                                                    <span key={ idx }>
                                                        { item }
                                                        {
                                                            idx != (checkTargetLabel.length - 1) && ", "
                                                        }
                                                    </span>
                                                )
                                            })
                                        )
                                    }
                                    <RiArrowDownSLine />
                                </div>
                            </ Col>

                            <Col xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 } xl={ 4 } className="mt-2 mb-2">
                                {
                                    loadingTranslateDoc ? (
                                        <Button className="btn-set-translate" variant='default' disabled>
                                            <ThreeBounce color={ "#808080" } />
                                        </Button>
                                    ) : (
                                        <Button className="btn-set-translate" variant='default' onClick={() => { getUploadUrl() }}>{ t("translateDoc.set_translate") }</Button>
                                    )
                                }
                            </ Col>
                        </ Row>

                        <div className="input-group search-lang-input-group">
                            {/* <span className="input-group-btn pr-3" >
                                <AiOutlineSearch size={ 20 } />
                            </span> */}
                            <span className="content-center" >
                                <AiOutlineSearch size={ 20 } />
                            </span>
                            <Form.Control
                                className="search-input-form"
                                placeholder={ t("translateDoc.search_lang") }
                                aria-describedby="basic-addon1"
                                onChange={(e) => changeSearchLang(e) }
                                value={ searchText }
                            />
                        </div>

                        <div className="translate-doc-lang-wrapper" style={ openSourceLangList ? { } : { display: 'none' } }>
                            <div className="translate-doc-lang-lists">
                                {
                                    supportLang.length != 0 && (
                                        searchText == "" ? (
                                            supportLang.map((item, idx) => {
                                                return (
                                                    <div 
                                                        key={ idx }
                                                        className={ checkSourceLang.value == item.value ? "lang-option-selected" : "lang-option" }
                                                        // className="lang-option"
                                                        data-label={ item.label }
                                                        data-value={ item.value }
                                                        onClick={(e) => handleCheckSourceLang(e)}
                                                        style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                                    >
                                                        {
                                                            checkSourceLang.value == item.value ? (
                                                                <>
                                                                    { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    { item.label }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            searchLangList.map((item, idx) => {
                                                return (
                                                    <div 
                                                        key={ idx }
                                                        className={ checkSourceLang.value == item.value ? "lang-option-selected" : "lang-option" }
                                                        // className="lang-option"
                                                        data-label={ item.label }
                                                        data-value={ item.value }
                                                        onClick={(e) => handleCheckSourceLang(e)}
                                                        style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                                    >
                                                        {
                                                            checkSourceLang.value == item.value ? (
                                                                <>
                                                                    { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    { item.label }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        )

                                        
                                    )
                                }
                            </div>
                        </div>

                        {/* Checkbox TargetLanguages */}
                        {/* <div className="lang-list-wrapper" style={{ height: 'calc(100% - 390px)' }}> */}
                        <div className="translate-doc-lang-wrapper" style={ openTargetLangList ? { } : { display: 'none' } }>
                            <div className="translate-doc-lang-lists">
                                {
                                    ( checkSourceLang.value != "" && searchText == "" ) ? (
                                        targetLang.map((item, idx) => {
                                            return (
                                                <div
                                                    key={ idx }
                                                    data-label={ item.label }
                                                    data-value={ item.value }
                                                    // className="lang-option"
                                                    className={ checkTargetLang.includes(item.value) ? "lang-option-selected" : "lang-option" }
                                                    onClick={(e) => handleCheckTargetLang(e) }
                                                >
                                                    {
                                                        checkTargetLang.includes(item.value) ? (
                                                            <>
                                                                { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                            </>
                                                        ) : (
                                                            <>
                                                                { item.label }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    ) : (
                                        searchLangList.map((item, idx) => {
                                            return (
                                                <div
                                                    key={ idx }
                                                    data-label={ item.label }
                                                    data-value={ item.value }
                                                    // className="lang-option"
                                                    className={ checkTargetLang.includes(item.value) ? "lang-option-selected" : "lang-option" }
                                                    onClick={(e) => handleCheckTargetLang(e) }
                                                >
                                                    {
                                                        checkTargetLang.includes(item.value) ? (
                                                            <>
                                                                { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                            </>
                                                        ) : (
                                                            <>
                                                                { item.label }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ) : ( // RenderModalView
                        <div id='doc-select-language' className='fr' style={ ( !uiSmallAiBox && uiHideSidebar ) ? { width: '100%' } : {} }>
                            <div className="doc-info-left">
                                <div className="w-100 h-100 justify-content-center d-flex">
                                    <div className="upload-file-box">
                                        <div>
                                            <div className='pb-2'>
                                                <HiOutlineClipboardDocumentList className="book-icon" />
                                            </div>
                                            <div className="info-text-title pb-1">{ t("translateDoc.file_name") } : { translateDocInfo.name }</div>
                                            <div className="info-text">{ t("translateDoc.file_size") } : { ((translateDocInfo.size) / (1024 * 1024)).toFixed(2) + "MB" }</div>
                                            {
                                                translateDocProgress != false && (
                                                    <>
                                                        <ProgressBar className="progress-bar-container" label={`${ translateDocProgress }%`} now={ translateDocProgress } />
                                                        {/* <div className="info-progress">
                                                            { translateDocProgress + '%' }
                                                        </div> */}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="doc-info-right">

                                {/* Radio SourceLanguages */}
                                <Row className="ml-0 mr-0 lang-list-title">
                                    <Col sm={ 12 } md={ 12 } lg={ 4 } className="mt-2 mb-2 d-flex justify-content-center align-items-center">
                                        <div className="selected-lang-text" onClick={() => { setOpenSourceLangList(true); setOpenTargetLangList(false); setSearchLangList([]); setSearchText(""); }}>
                                            {
                                                checkSourceLang.value == "" ? (
                                                    <>
                                                        <span>{ t("translateDoc.select_lang") }</span>
                                                        <RiArrowDownSLine />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>{ checkSourceLang.label }</span>
                                                        <span><RiArrowDownSLine /></span>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Col>

                                    <Col sm={ 12 } md={ 12 } lg={ 4 } className="mt-2 mb-2 d-flex justify-content-center align-items-center">
                                        <div className="selected-lang-text" onClick={() => { setOpenSourceLangList(false); setOpenTargetLangList(true); setSearchLangList([]); setSearchText("") }}>
                                            {
                                                checkTargetLang.length == 0 ? (
                                                    <>
                                                        <span>{ t("translateDoc.select_lang") }</span>
                                                        <span><RiArrowDownSLine /></span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>
                                                            {
                                                                checkTargetLabel.map((item, idx) => {
                                                                    return (
                                                                        <span key={ idx }>
                                                                            { item }
                                                                            {
                                                                                idx != (checkTargetLabel.length - 1) && ", "
                                                                            }
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </span>
                                                        <span><RiArrowDownSLine /></span>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Col>

                                    <Col sm={ 12 } md={ 12 } lg={ 4 } className="mt-2 mb-2 d-flex justify-content-center align-items-center">
                                        <div className="selected-lang-text">
                                            {
                                                loadingTranslateDoc ? (
                                                    <Button variant='default' disabled style={{ fontSize: '12px', padding: '10px' }}>
                                                        <ThreeBounce color={ "#808080" } />
                                                    </Button>
                                                ) : (
                                                    <Button variant='default' onClick={() => { getUploadUrl() } } style={{ fontSize: '12px', padding: '10px' }}>{ t("translateDoc.set_translate") }</Button>
                                                )
                                            }
                                        </div>

                                        
                                    </Col>
                                </Row>

                                <div className="search-lang-input-group">
                                    <span className="content-center" >
                                        <AiOutlineSearch size={ 20 } />
                                    </span>
                                    <Form.Control
                                        className="search-input-form"
                                        placeholder={ t("translateDoc.search_lang") }
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => changeSearchLang(e) }
                                        // onChange={(e) =>  setSearchText(e.target.value) }
                                        value={ searchText }
                                    />
                                </div>

                                <div className="translate-doc-lang-wrapper" style={ openSourceLangList ? { } : { display: 'none' } }>
                                    <div className="translate-doc-lang-lists">
                                        {
                                            supportLang.length != 0 && (
                                                searchText == "" ? (
                                                    supportLang.map((item, idx) => {
                                                        return (
                                                            <div 
                                                                key={ idx }
                                                                className={ checkSourceLang.value == item.value ? "lang-option-selected" : "lang-option" }
                                                                // className="lang-option"
                                                                data-label={ item.label }
                                                                data-value={ item.value }
                                                                onClick={(e) => handleCheckSourceLang(e)}
                                                                style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                                            >
                                                                {
                                                                    checkSourceLang.value == item.value ? (
                                                                        <>
                                                                            { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            { item.label }
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    searchLangList.map((item, idx) => {
                                                        return (
                                                            <div 
                                                                key={ idx }
                                                                className={ checkSourceLang.value == item.value ? "lang-option-selected" : "lang-option" }
                                                                // className="lang-option"
                                                                data-label={ item.label }
                                                                data-value={ item.value }
                                                                onClick={(e) => handleCheckSourceLang(e)}
                                                                style={ item.value == "auto" ? { fontWeight: 'bold' } : {} }
                                                            >
                                                                {
                                                                    checkSourceLang.value == item.value ? (
                                                                        <>
                                                                            { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            { item.label }
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                )

                                                
                                            )
                                        }
                                    </div>
                                </div>

                                {/* Checkbox TargetLanguages */}
                                {/* <div className="lang-list-wrapper" style={{ height: 'calc(100% - 390px)' }}> */}
                                <div className="translate-doc-lang-wrapper" style={ openTargetLangList ? { } : { display: 'none' } }>
                                    <div className="translate-doc-lang-lists">
                                        {
                                            ( checkSourceLang.value != "" && searchText == "" ) ? (
                                                targetLang.map((item, idx) => {
                                                    return (
                                                        <div
                                                            key={ idx }
                                                            data-label={ item.label }
                                                            data-value={ item.value }
                                                            // className="lang-option"
                                                            className={ checkTargetLang.includes(item.value) ? "lang-option-selected" : "lang-option" }
                                                            onClick={(e) => handleCheckTargetLang(e) }
                                                        >
                                                            {
                                                                checkTargetLang.includes(item.value) ? (
                                                                    <>
                                                                        { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        { item.label }
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                searchLangList.map((item, idx) => {
                                                    return (
                                                        <div
                                                            key={ idx }
                                                            data-label={ item.label }
                                                            data-value={ item.value }
                                                            // className="lang-option"
                                                            className={ checkTargetLang.includes(item.value) ? "lang-option-selected" : "lang-option" }
                                                            onClick={(e) => handleCheckTargetLang(e) }
                                                        >
                                                            {
                                                                checkTargetLang.includes(item.value) ? (
                                                                    <>
                                                                        { item.label }&nbsp;&nbsp;<AiOutlineCheck />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        { item.label }
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="cr" />

                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default SelectDocLang;
import React from "react"

import SettingSidebar from "../../components/apps/setting/SettingSidebar";

const SettingSidebarContainer = () => {

    return (
        <SettingSidebar />
    )
}

export default SettingSidebarContainer
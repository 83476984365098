
//Actions
/*
const SET_USER_QUIZ = "quiz/SET_USER_QUIZ";
const SET_USER_QUIZ_STARTTIME = "quiz/SET_USER_QUIZ_STARTTIME";
const SET_USER_QUIZ_ENDTIME = "quiz/SET_USER_QUIZ_ENDTIME";
*/
const SET_SELECTED_APP = "status/SET_SELECTED_APP";
const SET_AI_NAME = "status/SET_AI_NAME";


// 액션 생성 함수
/*
export const setUserQuiz = userQuiz => ({ type: SET_USER_QUIZ, userQuiz });
export const setUserQuizStartTime = startTime => ({ type: SET_USER_QUIZ_STARTTIME, startTime });
export const setUserQuizEndTime = endTime => ({ type: SET_USER_QUIZ_ENDTIME, endTime });
*/
export const setSelectedApp = selectedApp => ({ type: SET_SELECTED_APP, selectedApp });
export const setAIName = aiName => ({ type: SET_AI_NAME, aiName });


const initialState = {
  selectedApp : false,
  aiName : false
};

export default function status(state = initialState, action) {
  switch (action.type) {
    /*
    case SET_USER_QUIZ:
      return {
          ...state,
          userQuiz : action.userQuiz
      };
    case SET_USER_QUIZ_STARTTIME:
      return {
          ...state,
          startTime : action.startTime
    };
    */    
    case SET_AI_NAME:
      return {
          ...state,
          apiName : action.apiName
    };
    case SET_SELECTED_APP:
      return {
          ...state,
          selectedApp : action.selectedApp
    };
    default:      
    
      return state;
  }
}

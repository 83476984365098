import React, {useState, useEffect} from 'react';


import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { Main } from './app/containers';
import Common from './Common';
import { useSelector, useDispatch } from 'react-redux';
import { Cookies } from 'react-cookie';

//import jwt from 'expo-jwt';
//import ProgressStateBar from './app/components/_commons/ProgressStateBar';
import { setUserData, setUserToken } from './app/modules/user';

import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './resources/assets/styles/sass/Toast.scss';


function App() {

    // console.log = function() {}

    const dispatch = useDispatch();

    const loadingAuth = useSelector(state => state.loading.loadingAuth);

    const cookies = new Cookies();
    /*
    var userAuthToken;

    if(cookies.get('token') != undefined) {
      var userData = [];
      for (const [key, value] of Object.entries(cookies.get('user_data'))) {
        userData[value[0]] = value[1];
      }
      userAuthToken = true;
      dispatch(setUserToken( cookies.get('token') ))
      dispatch( setUserData(userData) );
      // 사용자 정보 및 토큰 스토어에 저장
    } else {
      userAuthToken = false;
    }
    */
  return (
    <BrowserRouter>
      
        {/* 
          <Route exact path="/" component={Main} />
          MainRoom은 퀴즈를 검색하는 화면이 있는 컴포넌트,
          TestRoom은 해당 퀴즈에 대한 정보가 나타나는 컴포넌트
        */}
        {
          /*
          <Routes>
            <Route  path="/" element={ <Main/> } />
            <Route  path="/course/view.php" element={ <Main/> } />
            <Route  path="/mod/assign/view.php" element={ <Main/> } />
            <Route  path="/report/ubcompletion/progress.php" element={ <Main/> } />
            <Route  path="/grade/report/grader/index.php" element={ <Main/> } />
            <Route  path="*" element={ <></> } />
          </Routes>
          */

        }

      <Common/>
      <ToastContainer />
    </BrowserRouter>
  )

}

export default App; 
import i18n from "../langs/i18n";

//Actions
const SET_TRANSLATE_TEXT_SOURCE_TEXT = "translateText/SET_TRANSLATE_TEXT_SOURCE_TEXT";
const SET_TRANSLATE_TEXT_SOURCE_LANG = "translateText/SET_TRANSLATE_TEXT_SOURCE_LANG";
const SET_TRANSLATE_TEXT_SOURCE_SEARCH_TEXT = "translateText/SET_TRANSLATE_TEXT_SOURCE_SEARCH_TEXT";
const SET_TRANSLATE_TEXT_SOURCE_SEARCH_LIST = "translateText/SET_TRANSLATE_TEXT_SOURCE_SEARCH_LIST";
const SET_TRANSLATE_TEXT_OPEN_SOURCE_LANG_LIST = "translateText/SET_TRANSLATE_TEXT_OPEN_SOURCE_LANG_LIST";

const SET_TRANSLATE_TEXT_TARGET_TEXT = "translateText/SET_TRANSLATE_TEXT_TARGET_TEXT";
const SET_TRANSLATE_TEXT_TARGET_LANG = "translateText/SET_TRANSLATE_TEXT_TARGET_LANG";
const SET_TRANSLATE_TEXT_TARGET_SEARCH_TEXT = "translateText/SET_TRANSLATE_TEXT_TARGET_SEARCH_TEXT";
const SET_TRANSLATE_TEXT_TARGET_SEARCH_LIST = "translateText/SET_TRANSLATE_TEXT_TARGET_SEARCH_LIST";
const SET_TRANSLATE_TEXT_OPEN_TARGET_LANG_LIST = "translateText/SET_TRANSLATE_TEXT_OPEN_TARGET_LANG_LIST";

const SET_TRANSLATE_TEXT_AUTO = "translateText/SET_TRANSLATE_TEXT_AUTO";

const SET_TRANSLATE_TEXT_SUPPORT_LANG = "translateText/SET_TRANSLATE_TEXT_SUPPORT_LANG";
const SET_TRANSLATE_TEXT_SUPPORT_TARGET_LANG = "translateText/SET_TRANSLATE_TEXT_SUPPORT_TARGET_LANG";

const SET_TRANSLATE_TEXT_UPDATE_TEXT = "translateText/SET_TRANSLATE_TEXT_UPDATE_TEXT";

const SET_MODAL_STATUS = "translateText/SET_MODAL_STATUS";

// 액션 생성 함수

export const setTranslateTextSourceText = translateSourceText => ({ type: SET_TRANSLATE_TEXT_SOURCE_TEXT, translateSourceText });
export const setTranslateTextSourceLang = translateSourceLang => ({ type: SET_TRANSLATE_TEXT_SOURCE_LANG, translateSourceLang });
export const setTranslateTextSourceSearchText = translateSourceSearchText => ({ type: SET_TRANSLATE_TEXT_SOURCE_SEARCH_TEXT, translateSourceSearchText });
export const setTranslateTextSourceSearchList = translateSourceSearchList => ({ type: SET_TRANSLATE_TEXT_SOURCE_SEARCH_LIST, translateSourceSearchList });
export const setTranslateTextOpenSourceLangList = translateOpenSourceLangList => ({ type: SET_TRANSLATE_TEXT_OPEN_SOURCE_LANG_LIST, translateOpenSourceLangList });

export const setTranslateTextTargetText = translateTargetText => ({ type: SET_TRANSLATE_TEXT_TARGET_TEXT, translateTargetText });
export const setTranslateTextTargetLang = translateTargetLang => ({ type: SET_TRANSLATE_TEXT_TARGET_LANG, translateTargetLang });
export const setTranslateTextTargetSearchText = translateTargetSearchText => ({ type: SET_TRANSLATE_TEXT_TARGET_SEARCH_TEXT, translateTargetSearchText });
export const setTranslateTextTargetSearchList = translateTargetSearchList => ({ type: SET_TRANSLATE_TEXT_TARGET_SEARCH_LIST, translateTargetSearchList });
export const setTranslateTextOpenTargetLangList = translateOpenTargetLangList => ({ type: SET_TRANSLATE_TEXT_OPEN_TARGET_LANG_LIST, translateOpenTargetLangList });

export const setTranslateTextAuto = translateAuto => ({ type: SET_TRANSLATE_TEXT_AUTO, translateAuto });

export const setTranslateTextSupportLang = translateSupportLang => ({ type: SET_TRANSLATE_TEXT_SUPPORT_LANG, translateSupportLang });
export const setTranslateTextSupportTargetLang = translateSupportTargetLang => ({ type: SET_TRANSLATE_TEXT_SUPPORT_TARGET_LANG, translateSupportTargetLang });


export const setTranslateTextUpdateText = updateText => ({ type: SET_TRANSLATE_TEXT_UPDATE_TEXT, updateText });

export const setModalStatus = modalStatus => ({ type: SET_MODAL_STATUS, modalStatus });

const initialState = {
    translateSourceText : "", // 번역할 언어 입력
    translateSourceLang: {
        label: i18n.t('lang.auto'),
        value: 'auto',
    }, // 선택한 소스 언어
    translateSourceSearchText: "", // 소스 언어 검색어
    translateSourceSearchList: [], // 소스 언어 검색 결과 목록
    translateOpenSourceLangList: false, // 소스 언어 검색창

    translateTargetText: "",
    translateTargetLang: {
        label: '',
        value: "",
    },
    translateTargetSearchText: "",
    translateTargetSearchList: [],
    translateOpenTargetLangList: false,

    translateAuto: {
        label: "",
        value: "",
    },

    // translateSupportLang: [{
    //     label: "",
    //     value: "",
    // }],
    translateSupportLang: [],
    translateSupportTargetLang: [{
        label: "",
        value: "",
    }],

    updateText: false,
    modalStatus: false,
};

export default function translateText(state = initialState, action) {
    switch (action.type) {   
        case SET_TRANSLATE_TEXT_SOURCE_TEXT:
        return {
            ...state,
            translateSourceText : action.translateSourceText
        };

        case SET_TRANSLATE_TEXT_SOURCE_LANG:
            return {
                ...state,
                translateSourceLang : action.translateSourceLang
            };

        case SET_TRANSLATE_TEXT_SOURCE_SEARCH_TEXT:
            return {
                ...state,
                translateSourceSearchText : action.translateSourceSearchText
            };

        case SET_TRANSLATE_TEXT_SOURCE_SEARCH_LIST:
            return {
                ...state,
                translateSourceSearchList : action.translateSourceSearchList
            };

        case SET_TRANSLATE_TEXT_OPEN_SOURCE_LANG_LIST:
            return {
                ...state,
                translateOpenSourceLangList : action.translateOpenSourceLangList
            };



        case SET_TRANSLATE_TEXT_TARGET_TEXT:
            return {
                ...state,
                translateTargetText : action.translateTargetText
            };

        case SET_TRANSLATE_TEXT_TARGET_LANG:
            return {
                ...state,
                translateTargetLang : action.translateTargetLang
            };

        case SET_TRANSLATE_TEXT_TARGET_SEARCH_TEXT:
            return {
                ...state,
                translateTargetSearchText : action.translateTargetSearchText
            };

        case SET_TRANSLATE_TEXT_TARGET_SEARCH_LIST:
            return {
                ...state,
                translateTargetSearchList : action.translateTargetSearchList
            };

        case SET_TRANSLATE_TEXT_OPEN_TARGET_LANG_LIST:
            return {
                ...state,
                translateOpenTargetLangList : action.translateOpenTargetLangList
            };

        case SET_TRANSLATE_TEXT_AUTO:
            return {
                ...state,
                translateAuto : action.translateAuto
            };

        case SET_TRANSLATE_TEXT_SUPPORT_LANG:
            return {
                ...state,
                translateSupportLang : action.translateSupportLang
            };
        case SET_TRANSLATE_TEXT_SUPPORT_TARGET_LANG:
            return {
                ...state,
                translateSupportTargetLang : action.translateSupportTargetLang
            };

        case SET_TRANSLATE_TEXT_UPDATE_TEXT:
            return {
                ...state,
                updateText : action.updateText
            };

        case SET_MODAL_STATUS:
            return {
                ...state,
                modalStatus: action.modalStatus
            }

      default:
        return state;
    }
  }
  
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import UploadSummaryDoc from '../../components/apps/summaryDoc/UploadSummaryDoc';
import SummaryDocChat from '../../components/apps/summaryDoc/SummaryDocChat';
import ChatInputGroup from '../../components/apps/summaryDoc/ChatInputGroup';
import { setLoadingSpace } from '../../modules/loading';

const SummaryDocSpaceContainer = () => {

    const dispatch = useDispatch();

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar);
    const summaryDocSelectedSpace = useSelector(state => state.summaryDoc.summaryDocSelectedSpace);

    useEffect(() => {
        return () => {
            console.log('Clean Up Summary Doc Loading')
            dispatch(setLoadingSpace(false));
        }
    }, [])

    return (
        <>
            {
                summaryDocSelectedSpace == false && (
                    <UploadSummaryDoc />
                )
            }
            {
                typeof summaryDocSelectedSpace.space_id != 'undefined' &&
                    <>
                        <div className="container-uiChat fr" style={ ( !uiSmallAiBox && uiHideSidebar ) ? { width: '100%' } : {} }>
                            <SummaryDocChat/>
                        </div>
                        <ChatInputGroup />
                    </>
            }
        </>
    )
}

export default SummaryDocSpaceContainer
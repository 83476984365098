import React from 'react';
import SourceBox from '../../components/apps/translateText/SourceBox';

const SourceBoxContainer = () => {

    return (
        <SourceBox
        />
    )
}

export default SourceBoxContainer
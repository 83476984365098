
//Actions
const SET_TRANSLATE_DOC_LIST = "translateDoc/SET_TRANSLATE_DOC_LIST";
const SET_TRANSLATE_STATUS = "translateDoc/SET_TRANSLATE_STATUS";
const SET_UPLOAD_DOC = "translateDoc/SET_UPLOAD_DOC";
const SET_SELECT_LANG_DOC = "translateDoc/SET_SELECT_LANG_DOC";
const SET_UPLOAD_STATUS = "translateDoc/SET_UPLOAD_STATUS";
const SET_TRANSLATE_UPLOAD_INFO = "translateDoc/SET_TRANSLATE_UPLOAD_INFO";

const SET_TRANSLATE_DOC_INFO = "translateDoc/SET_TRANSLATE_DOC_INFO"; // 첨부한 파일 정보

const SET_TRANSLATE_SOURCE_LANG = "translateDoc/SET_TRANSLATE_SOURCE_LANG"; // check source lang
const SET_TRANSLATE_TARGET_LANG = "translateDoc/SET_TRANSLATE_TARGET_LANG"; // check target lang

const SET_TRANSLATE_STATE_LIST = "translateDoc/SET_TRANSLATE_STATE_LIST"; // get translated doc state

const SET_SELECTED_DOC_INFO = "translateDoc/SET_SELECTED_DOC_INFO"; // get onClick sidebar doc info
const SET_SELECTED_DOC_TRANSLATED_LIST = "translateDoc/SET_SELECTED_DOC_TRANSLATED_LIST"; // get onClick sidebar doc info

const SET_TRANSLATE_DOC_PROGRESS = "translateDoc/SET_TRANSLATE_DOC_PROGRESS";

// 액션 생성 함수
export const setTranslateDocList = translateDocList => ({ type: SET_TRANSLATE_DOC_LIST, translateDocList });
export const setTranslateStatus = translateStatus => ({ type: SET_TRANSLATE_STATUS, translateStatus });
export const setUploadDoc = uploadDoc => ({ type: SET_UPLOAD_DOC, uploadDoc });
export const setSelectLangDoc = selectLangDoc => ({ type: SET_SELECT_LANG_DOC, selectLangDoc });
export const setUploadStatus = uploadStatus => ({ type: SET_UPLOAD_STATUS, uploadStatus });
export const setTranslateUploadInfo = translateUploadInfo => ({ type: SET_TRANSLATE_UPLOAD_INFO, translateUploadInfo });

export const setTranslateDocInfo = translateDocInfo => ({ type: SET_TRANSLATE_DOC_INFO, translateDocInfo });

export const setTranslateSourceLang = sourceLang => ({ type: SET_TRANSLATE_SOURCE_LANG, sourceLang });
export const setTranslateTargetLang = targetLangs => ({ type: SET_TRANSLATE_TARGET_LANG, targetLangs });

export const setTranslateStateList = translateStateList => ({ type: SET_TRANSLATE_STATE_LIST, translateStateList });

export const setSelectedDocInfo = selectedDocInfo => ({ type: SET_SELECTED_DOC_INFO, selectedDocInfo });
export const setSelectedDocTranslatedList = selectedDocTranslatedList => ({ type: SET_SELECTED_DOC_TRANSLATED_LIST, selectedDocTranslatedList });

export const setTranslateDocProgress = translateDocProgress => ({ type: SET_TRANSLATE_DOC_PROGRESS, translateDocProgress });

const initialState = {
    translateDocList : false, // []
    translateStatus: "upload",
    translateDocInfo: {},
    translateUploadInfo: {
      uploadUrl: "",
      uuid: "",
      ext: "",
    },
    sourceLang: "",
    targetLangs: [],
    translateStateList: [],
    selectedDocInfo: {},
    selectedDocTranslatedList: [],

    translateDocProgress: false,
};

export default function translateDoc(state = initialState, action) {
    switch (action.type) {   
      case SET_TRANSLATE_DOC_LIST:
        return {
            ...state,
            translateDocList : action.translateDocList
        };

      case SET_TRANSLATE_STATUS:
        return {
          ...state,
          translateStatus: action.translateStatus
        }

      case SET_UPLOAD_DOC:
        return {
            ...state,
            uploadDoc : action.uploadDoc
        };
      
      case SET_SELECT_LANG_DOC:
        return {
            ...state,
            selectLangDoc : action.selectLangDoc
        };

        case SET_UPLOAD_STATUS:
          return {
              ...state,
              uploadStatus : action.uploadStatus
          };

        case SET_TRANSLATE_UPLOAD_INFO:
          return {
            ...state,
            translateUploadInfo: action.translateUploadInfo
          }

        case SET_TRANSLATE_SOURCE_LANG:
          return {
            ...state,
            sourceLang: action.sourceLang
          }

          case SET_TRANSLATE_TARGET_LANG:
            return {
              ...state,
              targetLangs: action.targetLangs
            }

          case SET_TRANSLATE_DOC_INFO:
            return {
              ...state,
              translateDocInfo: action.translateDocInfo
            }

          case SET_TRANSLATE_STATE_LIST:
            return {
              ...state,
              translateStateList: action.translateStateList
            }

          case SET_SELECTED_DOC_INFO:
            return {
              ...state,
              selectedDocInfo: action.selectedDocInfo
            }
          case SET_SELECTED_DOC_TRANSLATED_LIST:
            return {
              ...state,
              selectedDocTranslatedList: action.selectedDocTranslatedList
            }
            
          case SET_TRANSLATE_DOC_PROGRESS:
            return {
              ...state,
              translateDocProgress: action.translateDocProgress
            }

      default:
        return state;
    }
  }
  
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import Switch from 'react-switch'
import axios, { AxiosResponse } from "axios";
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select';

import { setOptTranslateTextSelection } from "../../../modules/setting";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const EtcSetting = () => {

    const dispatch = useDispatch();

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const userOpts = useSelector(state => state.setting.userOpts)

    const { t, i18n } = useTranslation();

    type APIResType = {
        data: {
            code: number;
            message: string;
            setOptData: number
        }
    }

    const langValue = () => {
        if(typeof userOpts['BASE_MY_LANG'] == 'undefined') {
            switch(navigator.language) {
                case 'ko':
                case 'ko-KP':
                case 'ko-KR':
                    return 'ko'
                case 'en-US':
                    return 'en-us'
                case 'en-GB':
                    return 'en-gb'
                case 'ja':
                case 'ja-JP':
                    return 'ja'
                case 'zh':
                case 'zh-Hans-CN':
                    return 'zh-cn'
                case 'es':
                    return 'es'
                case 'vi':
                case 'vi-VN':
                    return 'vi'
                default:
                    return 'en-gb'
            }
        }
    }

    const defaultLangOption = {
        value: typeof userOpts['BASE_MY_LANG'] == 'undefined' ? langValue() : userOpts['BASE_MY_LANG'],
        label: typeof userOpts['BASE_MY_LANG'] == 'undefined' ? t('lang.' + langValue()) : t('lang.' + userOpts['BASE_MY_LANG'])
    };

    console.log(defaultLangOption, 'default lang option')

    const langOptions = [
        { value: 'ko', label: t('lang.ko') },
        { value: 'en-gb', label: t('lang.en-gb') },
        { value: 'en-us', label: t('lang.en-us') },
        { value: 'ja', label: t('lang.ja') },
        { value: 'zh-cn', label: t('lang.zh-cn') },
        { value: 'es', label: t('lang.es') },
        { value: 'vi', label: t('lang.vi') },
    ];

    function changeBaseLang(menuType: string, e) {
        console.log(e);
        console.log("Change Base Lang")

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "opt" : {
                "key" : menuType,
                "value":e.value
            }
        });

        var config = {
            method: 'post',
            url: API_URL + '/' + API_VERSION + '/common/setOpt',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios<AxiosResponse, APIResType>(config)
        .then( (response) => {
            console.log(response)
            if(response.data.code == 100) {
                dispatch(setOptTranslateTextSelection({
                    [menuType]: e.value
                }))
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const RenderSmallView = () => {
        return (
            <Row className="content-center">
                <Col sm={ 7 } md={ 7 } xl={ 7 } xs={ 7 } lg={ 7 }>
                    <div className="setting-title">{ t('setting.base_lang_label') }</div>
                    <div className="setting-info">{ t('setting.base_lang_content') }</div>
                </Col>
                <Col sm={ 5 } md={ 5 } xl={ 5 } xs={ 5 } lg={ 5 } className="text-center">    
                    <Select
                        className="w-100"
                        defaultMenuIsOpen={ false }
                        options={ langOptions }
                        onChange={ (e) => changeBaseLang('BASE_MY_LANG', e) }
                        
                        // placeholder={ t('translateText.select_placeholder') }

                        defaultValue={ defaultLangOption }
                        
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '100%'
                            }),
                        }}
                    />
                </Col>
            </ Row>
        )
    }

    const RenderModalView = () => {
        return (
            <Row className="content-center">
                <Col sm={ 4 } md={ 4 } xl={ 4 } xs={ 4 } lg={ 4 }>
                    <div className="setting-title">{ t('setting.base_lang_label') }</div>
                    <div className="setting-info">{ t('setting.base_lang_content') }</div>
                </Col>
                <Col sm={ 4 } md={ 4 } xl={ 4 } xs={ 4 } lg={ 4 } className="text-center">
                    
                    <Select
                        className="w-100"
                        defaultMenuIsOpen={ false }
                        options={ langOptions }
                        onChange={ (e) => changeBaseLang('BASE_MY_LANG', e) }
                        
                        // placeholder={ t('translateText.select_placeholder') }

                        defaultValue={ defaultLangOption }
                        
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '100%'
                            }),
                        }}
                    />
                </Col>
                <Col sm={ 4 } md={ 4 } xl={ 4 } xs={ 4 } lg={ 4 }>
                </Col>
            </Row>
        )
    }

    return (
        <div className="setting-wrapper">
            <div className={ uiSmallAiBox? 'setting-small-content' : 'setting-modal-content'}>
                {
                    uiSmallAiBox ? (
                        <RenderSmallView />
                    ) : (
                        <RenderModalView />
                    )
                }
            </div>
        </div>
    )
}

export default EtcSetting
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai'

import { setTranslateTextAuto, setTranslateTextOpenTargetLangList, setTranslateTextSourceText, setTranslateTextTargetLang, setTranslateTextTargetText, setTranslateTextUpdateText } from '../../../modules/translateText';
import { setLoadingTranslateText } from '../../../modules/loading';
import { useTranslation } from "react-i18next";

const SourceBox = () => {

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const focusTextInput = useRef(null)
    
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const translateSourceText = useSelector(state => state.translateText.translateSourceText)

    const translateTargetLang = useSelector(state => state.translateText.translateTargetLang)

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const opts = useSelector(state => state.organization.opts); // SERVICE_TRANSLATOR - G: Google, P: Papago / Naver, O: OpenAI

    const translateSourceLang = useSelector(state => state.translateText.translateSourceLang)
    const translateSupportLang = useSelector(state => state.translateText.translateSupportLang)
    const updateText = useSelector(state => state.translateText.updateText);

    useEffect(() => {
        if(focusTextInput.current != null) {
            focusTextInput.current.focus()
            console.log('focus cursor')
        }
    }, [])

    useEffect(() => {
        // 원본 언어, 번역 언어, 텍스트 세개가 입력된 상태에서 작은 창으로 전환할 때, 페이지가 처음 렌더되면서 이 useEffect가 실행되지 않도록 수정

        if(updateText) {
            dispatch( setTranslateTextOpenTargetLangList(false) );
            if(translateSourceText != "" && translateTargetLang.value != "") {
                dispatch( setLoadingTranslateText(true) );
            } else {
                dispatch( setLoadingTranslateText(false) );
            }
    
            // sourceText 입력 후 1초가 지나면 번역 실행
            // targetLang이 설정되어 있어야 번역 실행 가능
            if(translateSourceText != "" && typeof translateTargetLang.value != 'undefined' && translateTargetLang.value != "") {
    
                var timeoutId = setTimeout(() => {
                    console.log("START TRANSLATER")
                    setTranslateTextWork();
                }, 1000)
            }
    
            return () => {
                clearTimeout(timeoutId);
            }
        }

    }, [translateSourceText, translateTargetLang.value])
    // }, [translateSourceText])

    useEffect(() => {
        console.log('value change')
    }, [translateTargetLang]);

    function changeSourceText(text) {
        dispatch( setTranslateTextSourceText(text) );
        if(!updateText) {
            dispatch( setTranslateTextUpdateText(true) );
        }
    }

    function setTranslateTextWork() {
        dispatch( setTranslateTextTargetText("") ) // init
        dispatch( setTranslateTextOpenTargetLangList(false) );

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "token" : userToken,
            "sourceLang": translateSourceLang.value,
            "targetLang": translateTargetLang.value != "en" ? translateTargetLang.value : "en-gb",
            "text": translateSourceText,
        });

        var config = {
          method: 'post',
          url:  API_URL + '/' + API_VERSION + '/translator/text',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios(config)
        .then( (response) => {
            console.log(response.data)
            if( response.data.code == 100){
                const selectedSourceLang = response.data.data.selectedSourceLang;
                const sourceLang = response.data.data.sourceLang;

                let translatedText = "";

                if(opts['SERVICE_TRANSLATOR'] == "DL") {
                    translatedText = response.data.data; // text
                } else {
                    translatedText = response.data.data.translatedText; // text
                }

                if(selectedSourceLang == "auto") {
                    if(opts['SERVICE_TRANSLATOR'] == "P") {
                        const data = translateSupportLang.filter(item => item.value == sourceLang )
                        dispatch( setTranslateTextAuto(data[0]) );
                        
                    } else {
                        const data = translateSupportLang.filter(item => item.value == sourceLang )
                        dispatch( setTranslateTextAuto(data[0]) );
                    }
                }

                // check same source, target language
                if(response.data.data.selectedSourceLang == "auto" && ( response.data.data.sourceLang == response.data.data.targetLang )) {

                    let newTargetLang = "";

                    if(response.data.data.sourceLang == "ko") {
                        newTargetLang = "en";
                        dispatch( setTranslateTextTargetLang({
                            label: t('lang.en'),
                            value: "en"
                        }) );
                    } else {
                        newTargetLang = "ko";
                        dispatch( setTranslateTextTargetLang({
                            label: t('lang.ko'),
                            value: "ko",
                        }) );
                    }

                } else {
                    dispatch( setTranslateTextTargetText(translatedText) )
                }

                dispatch( setLoadingTranslateText(false) );
                dispatch( setTranslateTextUpdateText(false) );
            }
        })
        .catch((error) => {
            console.log(error);

            dispatch( setLoadingTranslateText(false) );
        });
    }

    function clearSourceText() {
        dispatch( setTranslateTextSourceText('') )
    }

    return (
        <>
            {
                uiSmallAiBox ? (
                    <div className='box-input box-input-from border-top-0 border-right-0'>
                        <div className='position-relative source-box-bottom'>
                            <textarea
                                className="form-control translate-box border-top-0 border-right-0"
                                placeholder={ t('translateText.source_input') }
                                onChange={(e) => { changeSourceText(e.target.value) } }
                                value={ translateSourceText }
                                maxLength= { 5000 }
                                ref={ focusTextInput }
                            ></textarea>
                            <div className='box-option'>
                                { translateSourceText.length } / 5000
                            </div>
                            <div className='btn-del-source-text'>
                                <AiOutlineClose size={ 20 } onClick={ clearSourceText } />
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className='translate-text-box position-relative'>
                        <textarea
                            className="form-control translate-box"
                            placeholder={ t('translateText.source_input') }
                            onChange={(e) => { changeSourceText(e.target.value) } }
                            // value={ sourceText }
                            value={ translateSourceText }
                            maxLength= { 5000 }
                            ref={ focusTextInput }
                        ></textarea>
                        <div className='box-option'>
                            {/* { sourceText.length } / 5000 */}
                            { translateSourceText.length } / 5000
                        </div>
                        
                        <div className='btn-del-source-text'>
                            <AiOutlineClose size={ 20 } onClick={ clearSourceText } />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SourceBox;
//Actions
const SET_GPTCHAT_CURRENT_CHATHISTORY = "apps/SET_GPTCHAT_CURRENT_CHATHISTORY";
const SET_FETCH_CONTROLLER = "apps/SET_FETCH_CONTROLLER";

// 액션 생성 함수
export const setGptChatCurrentChatHistory = gptChatCurrentChatHistory => ({ type: SET_GPTCHAT_CURRENT_CHATHISTORY, gptChatCurrentChatHistory });
export const setFetchController = fetchController => ({ type: SET_FETCH_CONTROLLER, fetchController });

const initialState = {
    gptChatCurrentChatHistory : [],
    fetchController : false
};

export default function apps(state = initialState, action) {
    switch (action.type) {   
      case SET_GPTCHAT_CURRENT_CHATHISTORY:
        return {
            ...state,
            gptChatCurrentChatHistory : action.gptChatCurrentChatHistory
      };
      case SET_FETCH_CONTROLLER:
        return {
            ...state,
            fetchController : action.fetchController
      };      
      default:      
      
        return state;
    }
  }
  
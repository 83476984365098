
//Actions
const SET_AI_CHAT_MESSAGE = "aiChat/SET_AICHAT_MESSAGE";
const SET_AI_CHAT_SPACE_LIST = "aiChat/SET_AICHAT_SPACE_LIST";
const SET_AI_CHAT_SELECTED_SPACE = "aiChat/SET_AI_CHAT_SELECTED_SPACE";
const SET_AI_CHAT_SELECTED_SPACE_CHAT_HISTORY = "aiChat/SET_AI_CHAT_SELECTED_SPACE_CHAT_HISTORY";
const SET_NEW_CHAT_SPACE = "aiChat/SET_NEW_CHAT_SPACE";
const SET_AI_CHAT_PROMPT_LIST = "aiChat/SET_AI_CHAT_PROMPT_LIST";
const SET_SELECTED_PROMPT = "aiChat/SET_SELECTED_PROMPT";
const SET_AI_CHAT_PROMPT_PAGE = "aiChat/SET_PROMPT_PAGE";

// 액션 생성 함수
export const setAiChatMessage = aiChatMessage => ({ type: SET_AI_CHAT_MESSAGE, aiChatMessage });
export const setAiChatSpaceList = aiChatSpaceList => ({ type: SET_AI_CHAT_SPACE_LIST, aiChatSpaceList });
export const setAiChatSelectedSpace = aiChatSelectedSpace => ({ type: SET_AI_CHAT_SELECTED_SPACE, aiChatSelectedSpace });
export const setAiChatSelectedSpaceChatHistory = aiChatSelectedSpaceChatHistory => ({ type: SET_AI_CHAT_SELECTED_SPACE_CHAT_HISTORY, aiChatSelectedSpaceChatHistory });
export const setNewChatsSpace = aiNewChatSpace => ({ type: SET_NEW_CHAT_SPACE, aiNewChatSpace });
export const setAiChatPromptList = aiChatPromptList => ({ type: SET_AI_CHAT_PROMPT_LIST, aiChatPromptList });
export const setSelectedPrompt = selectedPrompt => ({ type: SET_SELECTED_PROMPT, selectedPrompt });
export const setAiChatPromptPage = aiChatPromptPage => ({ type: SET_AI_CHAT_PROMPT_PAGE, aiChatPromptPage });

const initialState = {
    aiChatMessage: "",
    aiChatSpaceList : [],
    aiChatSelectedSpace : false,
    aiChatSelectedSpaceChatHistory : [],
    aiNewChatSpace: false,
    aiChatPromptList:false,
    selectedPrompt : false,
    aiChatPromptPage: false,
};

export default function aiChat(state = initialState, action) {
    switch (action.type) {  
      case SET_AI_CHAT_MESSAGE:
        return {
          ...state,
          aiChatMessage: action.aiChatMessage,
        };

      case SET_AI_CHAT_PROMPT_LIST:
        return {
            ...state,
            aiChatPromptList : action.aiChatPromptList
        };       
      case SET_SELECTED_PROMPT:
        return {
            ...state,
            selectedPrompt : action.selectedPrompt
        };

      case SET_AI_CHAT_SPACE_LIST:
        return {
            ...state,
            aiChatSpaceList : action.aiChatSpaceList
        };

      case SET_AI_CHAT_SELECTED_SPACE:
        return {
            ...state,
            aiChatSelectedSpace : action.aiChatSelectedSpace
        };

      case SET_AI_CHAT_SELECTED_SPACE_CHAT_HISTORY:
        return {
            ...state,
            aiChatSelectedSpaceChatHistory : action.aiChatSelectedSpaceChatHistory
        };
      
      case SET_NEW_CHAT_SPACE:
        return {
          ...state,
          aiNewChatSpace: action.aiNewChatSpace
        };

      case SET_AI_CHAT_PROMPT_PAGE:
        return {
          ...state,
          aiChatPromptPage: action.aiChatPromptPage
        }
        
      default:
        return state;
    }
  }
  
import React from 'react';
import TargetBox from '../../components/apps/translateText/TargetBox';

const TargetBoxContainer = () => {

    return (
        <TargetBox />
    )
}

export default TargetBoxContainer
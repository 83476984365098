import React from "react";
import { useTranslation } from "react-i18next";

const InterviewSidebarContainer = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className='leftSidebar-list fl'>
                {/* 
                <div className='add-btn-wrapper d-flex'>
                    <div 
                        className='btn-add'
                    >
                        <div className="content-center">
                        </div>
                    </div>
                    <div className="btn-close" >
                    </div>
                </div>
                */}
                <div className="sidebar-list-title">
                    { t("common.interviewPrep") }
                </div>
                <ul className="mb-0 sidebar-list-wrapper">
                    <li className="btn-select-space pl-2 pr-3 pt-3 pb-3 cursor-pointer d-flex">
                        <div className="space-label fl text-truncate">
                            { t("common.interviewPrep") }
                        </div>
                        <div className="cr" />
                    </li>
                    <li className="btn-select-space pl-2 pr-3 pt-3 pb-3 cursor-pointer d-flex">
                        <div className="space-label fl text-truncate">
                            { t("common.interviewPrep") }
                        </div>
                        <div className="cr" />
                    </li>
                    <li className="btn-select-space pl-2 pr-3 pt-3 pb-3 cursor-pointer d-flex">
                        <div className="space-label fl text-truncate">
                            { t("common.interviewPrep") }
                        </div>
                        <div className="cr" />
                    </li>
                    <li className="btn-select-space pl-2 pr-3 pt-3 pb-3 cursor-pointer d-flex">
                        <div className="space-label fl text-truncate">
                            { t("common.interviewPrep") }
                        </div>
                        <div className="cr" />
                    </li>
                    <li className="btn-select-space pl-2 pr-3 pt-3 pb-3 cursor-pointer d-flex">
                        <div className="space-label fl text-truncate">
                            { t("common.interviewPrep") }
                        </div>
                        <div className="cr" />
                    </li>
                </ul>
            </div>
        </>
    )
}

export default InterviewSidebarContainer
import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import organization from './organization';
import status from './status';
import loading from './loading';
import apps from './apps';
import ui from './ui';

import aiChat from "./aiChat"
import translateText from './translateText';
import translateDoc from './translateDoc';
import summaryDoc from './summaryDoc';
import tooltip from './tooltip';
import setting from './setting';

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

const persistConfig = {
    key: "user",
    storage,
    whitelist: ['organization', 'auth', 'ui', 'status', 'loading', 'apps', 'aiChat', 'translateText', 'translateDoc', 'summaryDoc', 'tooltip', 'setting']
};

const rootReducer = combineReducers({ organization, auth, ui, user, status, loading, apps, aiChat, translateText, translateDoc, summaryDoc, tooltip, setting });

//export default persistReducer(persistConfig, rootReducer);
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
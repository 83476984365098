import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TbArrowsExchange } from 'react-icons/tb';

import { setTranslateTextSourceLang, setTranslateTextSourceText, setTranslateTextTargetLang, setTranslateTextTargetText, setTranslateTextUpdateText } from '../../../modules/translateText';
import { useTranslation } from "react-i18next";

const ChangeLangBtn = () => {

    const dispatch = useDispatch();

    const opts = useSelector(state => state.organization.opts);
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const loadingTranslateText = useSelector(state => state.loading.loadingTranslateText);
    const translateSourceText = useSelector(state => state.translateText.translateSourceText)
    const translateSourceLang = useSelector(state => state.translateText.translateSourceLang)
    const translateTargetLang = useSelector(state => state.translateText.translateTargetLang)
    const translateTargetText = useSelector(state => state.translateText.translateTargetText)
    const translateAuto = useSelector(state => state.translateText.translateAuto)

    const { t, i18n } = useTranslation();

    function handleSwitchingSide() {
        // source, target 위치 변경
        dispatch( setTranslateTextUpdateText(true) );
        dispatch( setTranslateTextTargetText("") )

        let tmpTargetText = translateTargetText;
        let tmpSourceLang = translateSourceLang;
        let tmpTargetLang = translateTargetLang;

        if(translateSourceLang.value == "auto") {
            tmpSourceLang = translateAuto
        }

        if(translateSourceLang.value == "en") {

            switch(opts['SERVICE_TRANSLATOR']) {
                case "DL":
                    tmpSourceLang = {
                        label: t('lang.en-gb'),
                        value: "en-gb"
                    }
                    break;
                default:
                    break;
            }
        } // source - en

        if(translateTargetLang.value == "en-gb" || translateTargetLang.value == "en-us") {
            switch(opts['SERVICE_TRANSLATOR']) {
                case "DL":
                    tmpTargetLang = {
                        label: t('lang.en'),
                        value: 'en'
                    }
                    break;
                default:
                    break;
            }
        } // target - en

        if(translateSourceLang.value == 'pt') {
            switch(opts['SERVICE_TRANSLATOR']) {
                case "DL":
                    tmpSourceLang = {
                        label: t('lang.pt-pt'),
                        value: 'pt-pt'
                    }
                    break;
                default:
                    break;
            }
        } // source - pt

        if(translateTargetLang.value == 'pt-br' || translateTargetLang.value == 'pt-pt') {
            switch(opts['SERVICE_TRANSLATOR']) {
                case "DL":
                    tmpTargetLang = {
                        label: t('lang.deepl-pt'),
                        value: 'pt'
                    }
                    break;
                default:
                    break;
            }
        } // target - pt

        dispatch( setTranslateTextTargetLang(tmpSourceLang) );
        dispatch( setTranslateTextSourceLang(tmpTargetLang) );
        dispatch( setTranslateTextSourceText(tmpTargetText) );
        // source - target 변경 - 번역 언어 교체
    }

    return (
        <div className='change-btn'>
            {
                uiSmallAiBox ? (
                    typeof translateTargetLang.value != 'undefined' && typeof translateSourceLang.value != 'undefined' && translateSourceText != "" && translateSourceLang.value != '' && translateTargetLang.value != '' ) ? (
                        loadingTranslateText ? (
                            <div className='fl pt-3 pb-2'>
                                <TbArrowsExchange size={20} color='#E0E0E0' />
                            </div>
                        ) : (
                            <div className='fl pt-3 pb-2'>
                                <TbArrowsExchange
                                    id='icon-aitutor-exchange'
                                    className='cursor-pointer'
                                    size={20}
                                    onClick={ handleSwitchingSide }
                                />
                            </div>
                        )
                    ) : (
                        <div className='fl pt-3 pb-2'>
                            <TbArrowsExchange size={20} color='#E0E0E0' />
                        </div>
                ) : (
                    typeof translateTargetLang.value != 'undefined' && typeof translateSourceLang.value != 'undefined' && translateSourceText != "" && translateSourceLang.value != '' && translateTargetLang.value != '' ) ? (
                        loadingTranslateText ? (
                                <TbArrowsExchange size={20} color='#E0E0E0' />
                        ) : (
                                <TbArrowsExchange 
                                    id='icon-aitutor-exchange'
                                    className='cursor-pointer'
                                    size={20} 
                                    onClick={ handleSwitchingSide }
                                />
                        )
                    ) : (
                            <TbArrowsExchange size={20} color='#E0E0E0' />
                )
            }
        </div>
    )
}

export default ChangeLangBtn
import React from 'react';

import ChangeLangBtn from "../../components/apps/translateText/ChangeLangBtn"

const ChangeBtnContainer = () => {
    
    return (
        <ChangeLangBtn />
    )
}

export default ChangeBtnContainer
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { Cookies } from 'react-cookie';
import { borderRadius } from 'react-select/src/theme';
import { setUiModalAiBox, setUiShowAiBox } from '../../modules/ui';
import { useTranslation } from "react-i18next";

const MainGuideBubble = () => {

    // Cookie name change: aichat-guide -> aichat-event-guide

    const [runGuide, setRunGuide] = useState(true);

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const steps = [{
        target: '.header-color',
        title: t("common.guide-title"),
        content: t("common.guide-content"),
        disableBeacon: true,
    }, {
        target: '.chat-guide',
        content: 'chat-guide'
    }];

    function setGuideCookie() {
        const cookies = new Cookies();

        cookies.set('aichat-event-guide', true, {
            path: '/',
            expires: new Date(new Date().getTime()+1000*60*60*24*365)
        });
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        console.log(action, index, status, type);

        if(action == 'update' && type == 'step:after') {
            dispatch(setUiShowAiBox(true));
            dispatch(setUiModalAiBox(true));
            console.log('show ai chat')
        } else if(action == 'skip' && status == 'skipped' && type == 'tour:end') {
            setGuideCookie();
            setRunGuide(false);
        } 
        /*
        else if(action == 'next' && status == 'running' && type == 'step:after') {
            setGuideCookie();
            setRunGuide(false)
        }
        */
    }

    return (
        <>
            <Joyride
                run={ runGuide }
                steps={ steps }
                disableOverlay={ false }
                floaterProps={{
                    disableAnimation: true,
                }}
                disableCloseOnEsc={ false }
                disableOverlayClose={ true }
                disableScrolling={ true }
                showSkipButton={ true }

                hideCloseButton={ true }
                hideBackButton={ true }
                

                continuous={  true }
                spotlightPadding={ 0 }

                callback={(data) => handleJoyrideCallback(data)}

                locale={{
                    skip: t("common.main-guide-skip"),
                    next: t("common.main-guide-next"),
                    back: t("common.main-guide-back"),
                    last: t("common.main-guide-last"),
                    
                }}
                styles={{
                    tooltip: {
                        borderRadius: '12px'
                    },
                    options: {
                        primaryColor: '#FFFFFF',
                        textColor: '#FFFFFF',
                        backgroundColor: '#3236FF',
                        arrowColor: '#3236FF',
                        zIndex: 1070
                    },
                    beaconOuter: {
                        borderRadius: '30px'
                    },
                    buttonNext: {
                        color: '#3236FF',
                        fontWeight: 500,
                        padding: '10px'
                    },
                    buttonSkip: {
                        // textDecoration: 'underline'
                    },
                    tooltipTitle: {
                        textAlign: 'left',
                        paddingLeft: '10px',
                    },
                    tooltipContent: {
                        textAlign: 'left'
                    }
                }}
                
            />
        </>

    )
}

export default MainGuideBubble
import React, { useEffect } from "react"
import { useDispatch } from "react-redux";

import SettingSidebarContainer from "../../containers/setting/SettingSidebarContainer"
import SettingSpaceContainer from "../../containers/setting/SettingSpaceContainer"
import '../../../resources/assets/styles/sass/UiSetting.scss';
import { setSelectedSetting } from "../../modules/setting";

const UiSetting = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            console.log('Setting Page Clean Up')
            // dispatch(setSelectedSetting("TranslateText")) // Init Setting Menu
        }
    }, [])

    return (
        <>
            <SettingSidebarContainer />
            <SettingSpaceContainer />
        </>
    )
    
}

export default UiSetting
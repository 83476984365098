import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FoldingCube } from 'better-react-spinkit'
import { BiTrash } from 'react-icons/bi'
import { immediateToast } from "izitoast-react";

import "izitoast-react/dist/iziToast.css";
import { setSummaryDocList, setSummaryDocSelectedSpace, setSummaryDocStatus } from "../../../modules/summaryDoc";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";

const SummaryDocList = (props) => {

    const {
        getSummaryDocState
    } = props

    type SummaryDocSelectedType = {
        appType: string,
        created_at: string,
        label: string,
        orgUser_id: string,
        space_id: string,
        updated_at: string
    }

    const dispatch = useDispatch();

    const [sidebarIcon, setSidebarIcon] = useState(false)

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);

    const loadingSummaryDocList = useSelector(state => state.loading.loadingSummaryDocList);
    
    const summaryDocList = useSelector(state => state.summaryDoc.summaryDocList);
    const summaryDocSelectedSpace = useSelector(state => state.summaryDoc.summaryDocSelectedSpace);

    const { t, i18n } = useTranslation();

    function delSummaryDocSpace(item: SummaryDocSelectedType) {
        console.log(item);

        immediateToast("info", {
            title: t("summaryDoc.delete_title"),
            message: t("summaryDoc.delete_msg"),
            position: 'topCenter',
            timeout: 2000,
            zindex: 999999999
        });

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "type" : "summaryDoc",
            "spaceId" : item.space_id
        });

        var config = {
            method: 'post',
            url:  API_URL + '/' + API_VERSION + '/space/delSpace',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
        .then( (response) => {
            console.log(response.data)

            if( response.data.code == 100){
                console.log("SUCCESS")

                immediateToast("success", {
                    title: t("summaryDoc.delete_title"),
                    message: t("summaryDoc.delete_success"),
                    position: 'topCenter',
                    timeout: 2000,
                    zindex: 999999999
                });

                dispatch( setSummaryDocStatus("upload") ); // init

                // 문서 요약 사이드바 갱신
                const updateList = summaryDocList.filter((list: SummaryDocSelectedType) => list.space_id != item.space_id)
                dispatch( setSummaryDocList(updateList) );

                if(summaryDocSelectedSpace.space_id == item.space_id) {
                    // 지금 선택한 문서 요약 페이지 삭제
                    dispatch( setSummaryDocSelectedSpace(false) )
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <ul className="mb-0 sidebar-list-wrapper">
            <>
                {
                    loadingSummaryDocList ? (
                        <div className='justify-content-center d-flex align-items-center h-100'>
                            <FoldingCube size={20} color="#C0C0C0" />
                        </div>
                    ) : (
                        <>
                            {
                                Object.keys(summaryDocList).map(v => 
                                    <li 
                                        key={ summaryDocList[v].space_id }
                                        className={ summaryDocSelectedSpace != "false" ? (
                                            summaryDocSelectedSpace.space_id == summaryDocList[v].space_id ? "btn-select-space pl-2 pr-3 pt-3 pb-3 selected-space-room" : "btn-select-space pl-2 pr-3 pt-3 pb-3"
                                            ) : ""
                                        }
                                        // onClick={() => getSpaceState(summaryDocList[v])}
                                        onMouseEnter={() => setSidebarIcon( summaryDocList[v].space_id ) }
                                        onMouseLeave={() => setSidebarIcon(false) }
                                        onClick={()=>getSummaryDocState( summaryDocList[v] )}
                                    >

                                        <div className="space-label fl text-truncate">
                                            { summaryDocList[v].label }
                                        </div>
                                        {
                                            sidebarIcon != false && (
                                                sidebarIcon == summaryDocList[v].space_id && (
                                                    <div
                                                        className="d-inline-block fr pr-2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            delSummaryDocSpace( summaryDocList[v] );
                                                        }}
                                                    >
                                                        <BiTrash size={ 14 } />
                                                    </div>
                                                )
                                            )
                                        }
                                        {/* <div className="fr pr-2">
                                            <BiTrash size={ 14 } />
                                        </div> */}
                                        <div className="cr" />
                                    </li>
                                )
                            }
                        </>
                    )
                    
                }
            </>
        </ul>
    )
}

export default SummaryDocList;
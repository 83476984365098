
//Actions
const SET_SELECTED_SETTING = "setting/SET_SELECTED_SETTING";
const SET_USER_OPTS = "setting/SET_USER_OPTS";
const SET_OPT_TRANSLATE_TEXT_SELECTION = "setting/OPT_TRANSLATE_TEXT_SELECTION"


// 액션 생성 함수
export const setSelectedSetting = selectedSetting => ({ type: SET_SELECTED_SETTING, selectedSetting });
export const setUserOpts = userOpts => ({ type: SET_USER_OPTS, userOpts })
export const setOptTranslateTextSelection = translateTextSelection => ({ type: SET_OPT_TRANSLATE_TEXT_SELECTION, translateTextSelection })


const initialState = {
    selectedSetting : "Etc",
    userOpts: []
};

export default function setting(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_SETTING:
      return {
          ...state,
          selectedSetting : action.selectedSetting
    };
    case SET_USER_OPTS:
      return {
          ...state,
          userOpts: action.userOpts
      }
    case SET_OPT_TRANSLATE_TEXT_SELECTION:
      return {
          ...state,
          userOpts: {
            ...state.userOpts,
            ...action.translateTextSelection,
         }
      }
    default:      
    
      return state;
  }
}

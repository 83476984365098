import React from "react"

import AiChatSetting from "../../components/apps/setting/AiChatSetting"
import SummaryDocSetting from "../../components/apps/setting/SummaryDocSetting"
import TranslateTextSetting from "../../components/apps/setting/TranslateTextSetting"
import TranslateDocSetting from "../../components/apps/setting/TranslateDocSetting"
import { useSelector } from "react-redux"
import EtcSetting from "../../components/apps/setting/EtcSetting"

const SettingSpaceContainer = () => {

    const selectedSetting = useSelector(state => state.setting.selectedSetting);

    const CurrentSetting = () => {
        switch(selectedSetting) {
            case "CHAT":
                return <AiChatSetting />
            case "TranslateText":
                return <TranslateTextSetting />
            case "TranslateDoc":
                return <TranslateDocSetting />
            case "SummaryDoc":
                return <SummaryDocSetting />
            case "Etc":
                return <EtcSetting />
            default:
                return <TranslateTextSetting />
        }
    }

    return (
        <div className="container-uiChat fr ai-setting-container">
            <CurrentSetting />
        </div>
    )
}

export default SettingSpaceContainer